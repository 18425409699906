<div class="overlay-toolbar mt1">
  <!-- [ngStyle]="{'margin-left:' subNavbarPaddingLeft }" -->
  <mat-icon class="dxfx_icon_btn_medium "
  [ngStyle]="{'margin-left':  (subNavbarPaddingLeft ? subNavbarPaddingLeft : 0) +'px'}"
  (click)='backToOverview()'>subdirectory_arrow_right</mat-icon>
  <!-- keyboard_arrow_up -->
  <!-- <span class="toolbar-spacer"></span> -->
  <div class="d-flex p0 pl5" >
    <div class="p-0 toolbarTitle" >Detail</div>
    <mat-icon class="dxfx_icon_btn_medium disabledIcon" >chevron_right</mat-icon>
    <div class="p-0 toolbarTitle" >
      {{title}}
    </div>

  </div>
</div>

<div class="p0 bgDarg cHolder h100 w100" *ngIf='timelineInjectRef || companyInjectTemplate' style=" overflow: hidden; position: relative;">

  <div class="stateBorder" *ngIf="timelineInjectRef" [ngStyle]="{'background-color': getBackgroundColor()}"></div>

  <!-- SIDEBAR CONTAINER COMP -->
  <app-inject-detail-sidenav [injectModel]="injectModel" (onNaviSelected)="onNaviSelectEvent($event)"></app-inject-detail-sidenav>
  <!-- <div class="sideBarContainer">
    <div class="sideBarBox" *ngFor="let navItem of sideNavItems" >
      <app-navigation-list-item
      [active]="activeSideNavId == navItem.id"
      [model]="navItem" (selected)="selectNav($event)" ></app-navigation-list-item>
    </div>
  </div> -->


  <div class="contentCOntainer">
    <div class="titleBar">


      <div class="p-1 d-flex flex-column" style="min-width: 200px;">
        <div class="p-0 uc itemTitle fs12 m0 mb3">
          <span *ngIf="!injectNoError">Inject No.</span>
          <span *ngIf="injectNoError" style="color: red;">{{errorCopy}}</span>
        </div>
        <div class="p-0 titleBarTxt h38" *ngIf="!showIdAndTitle">
          {{inject_no}}
        </div>
        <div class="p-0 h38" *ngIf="showIdAndTitle">
          <input type="text"  [class.formControlError]='injectNoError' [class.formControl] = '!injectNoError'
          class=" textfield100 h38"  required  (keyup)='injectNoKeyDown($event)'
          placeholder="" style="text-transform: uppercase;"
          [(ngModel)]="inject_no" maxlength="50">

        </div>
      </div>
      <div class="p-1 pl0 d-flex flex-column" style="min-width: 250px;">
        <div class="p-0 uc itemTitle fs12 m0 mb3">Inject Title</div>
        <div class="p-0 titleBarTxt h38" *ngIf="!showIdAndTitle">
          {{short_title}}
        </div>
        <div class="p-0 h38" *ngIf="showIdAndTitle">
          <input type="text" class="textfield100 h38 " placeholder=""
          [class.formControlError]='injectShortError' [class.formControl] = '!injectShortError'
                  [(ngModel)]="short_title" maxlength="250">
        </div>
      </div>


      <inject-detail-ol-delivery-method class="p-1 pl0"
      [canChange]="true"
      [deliveryMethod]="timelineInjectRef ? timelineInjectRef.deliveryMethod : companyInjectTemplate ? companyInjectTemplate.deliveryMethod : undefined"
      (onChanged)="onDeliveryMethodChanged($event)"
      ></inject-detail-ol-delivery-method>


        <!-- <inject-detail-ol-delivery-date-time  *ngIf="timelineInjectRef" class="p-1 pl0"
        [inject]="timelineInjectRef"
         [deliveryDateTime]="timelineInjectRef.deliveryDateTime"></inject-detail-ol-delivery-date-time> -->


      <inject-detail-ol-locations class="p-1 pl0"
      (selected)="locationChanged($event)"
      [injectLocation]="injectLocation"
      [canChange]="true"
      [locations]="locations"></inject-detail-ol-locations>

      <!-- <inject-detail-ol-state class="p-1 pl0" title="new status" [selectable]="true" *ngIf="timelineInjectRef"
      [injectState]="getInjectState(timelineInjectRef)" (onChanged)="onStateChanged($event)"></inject-detail-ol-state> -->

    </div>

    <div class="titleBar" *ngIf="timelineInjectRef">
      <inject-detail-ol-delivery-date-time  class="p-1 pl0"
      [inject]="timelineInjectRef"
       [deliveryDateTime]="timelineInjectRef.deliveryDateTime"></inject-detail-ol-delivery-date-time>
      <inject-detail-ol-state class="p-1 pl0" title="new status" [selectable]="true"
      [injectState]="getInjectState(timelineInjectRef)" (onChanged)="onStateChanged($event)"></inject-detail-ol-state>
    </div>

    <div class="dynContainer">
      <ng-template add-host></ng-template>
    </div>
  </div>



</div>

