import { CdkDragDrop } from "@angular/cdk/drag-drop";
import { Component, OnInit, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatSelectChange } from "@angular/material/select";
import { Router } from "@angular/router";
import {
  CompanyInject,
  iCompany,
  iCompanyInject,
  iExerciseInject,
} from "src/app/model/company.model";
import { HTTP_METHOD } from "src/app/model/enums";
import { ExerciseI } from "src/app/model/exercise.model";
import {
  iAdminExerciseFlat,
  iCompanyAdmin,
  iUser,
} from "src/app/model/iuser.model";
import {
  ModOverlayDataType,
  iModifyOverlayData,
} from "src/app/model/texteditOLdata.model";
import {
  CompanyTrainingObjective,
  iCompanyTrainingObjective,
  iExerciseTrainingObjective,
} from "src/app/model/trainingObj.model";
import { InjectImportOverlayComponent } from "src/app/overlays/inject-import-overlay/inject-import-overlay.component";
import { ModifyOverlayComponent } from "src/app/overlays/modify-overlay/modify-overlay.component";
import { ApiService } from "src/app/services/api.service";
import { StorageService } from "src/app/services/storage.service";
import { UiService } from "src/app/services/ui.service";
import getCleanCompanyInject from "src/app/services/utils/companyIn.help";
import { wait } from "src/app/services/utils/functions";
import * as _ from 'lodash';
export enum ADMIN_VIEW_MODE {
  LOADING = "LOADING",
  VIEW_INJECTS = "VIEW_INJECTS",
  VIEW_TR_OBJ = "VIEW_TR_OBJ",
}

@Component({
  selector: "app-admin",
  templateUrl: "./admin.component.html",
  styleUrls: ["./admin.component.scss"],
})
export class AdminComponent implements OnInit {
  @ViewChild("csvReader", { static: false }) csvReader: any;
  currentUser: iUser | undefined;
  adminUser: iCompanyAdmin | undefined;
  comp: iCompany | undefined;
  disabled = false;
  iCompanyInjectDetail: iCompanyInject | undefined;
  errorCopy: string = "";
  searchTxt: string = "";
  selectedExerciseId: string | undefined;
  injectNoHasError: boolean = false;
  exercisesFlat: iAdminExerciseFlat[] = [];
  selectedE: ExerciseI | undefined;
  selectedCompany: iCompany | undefined;
  currentViewMode: ADMIN_VIEW_MODE = ADMIN_VIEW_MODE.LOADING;
  ADMIN_VIEW_MODES = ADMIN_VIEW_MODE;

  selectedInjectSet: string | undefined

  constructor(
    private api: ApiService,
    public ui: UiService,
    public storage: StorageService,
    private router: Router,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.load();
  }

  private async load(setViewMode: boolean = true) {
    if (!this.storage.currentUser) {
      const _currentUser = await this.api.apiGetP(`user/`);
      console.log(_currentUser);
      this.currentUser = _currentUser as iUser;
    } else {
      this.currentUser = this.storage.currentUser;
    }

    if (this.currentUser.companies && this.currentUser.companies.length == 1) {
      this.onCompanyChanged(this.currentUser.companies[0])
    }

    if (setViewMode) {

      setTimeout(() => {
        this.currentViewMode = ADMIN_VIEW_MODE.VIEW_INJECTS;
      }, 100);
    }
  }

  viewModeChanged(e: any) {
    console.log(e);
    this.load(false);

    // const ex = this.selectedE;
    // if (this.selectedE) {
    //   this.selectedE = undefined;
    // }
    /* wait(1500, ()=> {
      console.log(`>>>> VIEW MODE CHANGED ${this.selectedExerciseId}`);

      if (this.selectedE) {
        console.log(this.selectedE);
        // const ex = this.selectedE;
        this.selectedE = this.selectedE;
      }
    }) */
  }

  isInExerInjects(item: iCompanyInject): boolean {
    if (this.storage.exerciseInjectsAdmin.length == 0) {
      return false;
    }
    const index = this.storage.exerciseInjectsAdmin.findIndex(
      (ele) => ele.inject_no == item.inject_no
    );

    return index != -1;
  }

  action() {}

  importInject() {
    if (this.selectedCompany) {
      this.dialog
        .open(InjectImportOverlayComponent, {
          width: "80%",
          maxWidth: "1000px",
          // height: "640px",
          height: "75%",
          maxHeight: "1000px",
          panelClass: this.ui.darkMode ? "fsPanelClass" : "fsPanelClassLight",
            backdropClass: "fs_backdrop",
          // backdropClass: "backdrop-background",
          data: this.selectedCompany!,
        })
        .afterClosed()
        .subscribe((_imported) => {
          if (_imported != null && _imported == "imported") {
            // this.loadInjectsForCompany();
          }
        });
    }
  }

  openCOmpanyInject(item: iCompanyInject) {
    console.log(item);
    this.iCompanyInjectDetail = item;
  }
  createNew() {
    if (this.adminUser) {
      const item = getCleanCompanyInject();
      this.iCompanyInjectDetail = item;
      this.iCompanyInjectDetail.companyId = this.adminUser!.company.id;
    }
  }

  // public records: any[] = [];
  // isRecordComplete = false;
  async onSave(e: iCompanyInject) {
    console.log(e);
    if (e.id == -1) {
      const index = this.adminUser?.company.injectTemplates?.findIndex(
        (item) => item.inject_no.toLowerCase() == e.inject_no.toLowerCase()
      );
      console.log(`index ${index}`);
      if (index != -1) {
        this.errorCopy = "inject_no exists";
        this.injectNoHasError = true;
      } else {
        const exercises = await this.api.apiPost(
          "inject/create-company-inject/",
          {
            short_title: e.short_title,
            inject_no: e.inject_no,
            deliveryMethod: e.deliveryMethod,
            responsibility_for_delivery_by: e.responsibility_for_delivery_by,
            description: e.description,
            related_documents: e.related_documents,
            related_equipment: e.related_equipment,
            additional_guidance_caution_options:
              e.additional_guidance_caution_options,
            expected_outcomes: e.expected_outcomes,
            trainers_action_requirement: e.trainers_action_requirement,
            role_players_instruction: e.role_players_instruction,
            companyId: e.companyId,
          }
        );
        this.load();
      }
    }
  }

  noReturnPredicate() {
    return false;
  }
  dragMoved(e: any) {
    // console.log(e);
  }

  private async transferFromCompanyToExercise(
    compInjectId: number,
    exerciseId: string
  ) {
    console.log("transferFromCompanyToExercise");
    const exercise = await this.api.apiPost("inject/template-to-exercise/", {
      id: compInjectId,
      exerciseId: exerciseId,
    });
    // console.log(exercises);
    // const injectsByEx = await this.api.apiGetP(`inject/by-exercise/${exerciseId}`);
    this.storage.exerciseInjectsAdmin.push(exercise as iExerciseInject);
  }
  private async transferTrainObjFromCompanyToExercise(
    id: number,
    exerciseId: string
  ) {
    console.log("transferTrainObjFromCompanyToExercise");
    // return
    // const exercise = await this.api.apiPost('inject/transfer-from-company-to-exercise/', {
    const exercise = await this.api.apiPost(
      "trainingobjective/company-to-exercise/",
      {
        id: id,
        exerciseId: exerciseId,
      }
    );
    // console.log(exercises);

    // const injectsByEx = await this.api.apiGetP(`inject/by-exercise/${exerciseId}`);
    this.storage.exerciseTrainingObjectsAdmin.push(
      exercise as iExerciseTrainingObjective
    );
  }

  route(p: string) {
    this.router.navigate([p]);
  }


  editObjective(e: iCompanyTrainingObjective | undefined) {
    console.log(e);
    if (e && this.selectedCompany) {
      let model: iCompanyTrainingObjective = {
        id: e.id,
        companyId: e.companyId,
        title: e.title,
      };
      let olData: iModifyOverlayData = {
        dataTye: ModOverlayDataType.MODIFY_OBJ,
        companyId: this.selectedCompany.companyId,
        model: new CompanyTrainingObjective(model),
      };
      // this.dialog.open(ModifyTrainingObjectiveComponent, {
      this.dialog.open(ModifyOverlayComponent, {
        width: "500px",
        height: "300px",
        // "maxWidth": '1800px',
        // "maxHeight": '1000px',
        // backdropClass: "backdrop-background",
        data: olData,
      });

    } else if (!e && this.selectedCompany) {
      let model: iCompanyTrainingObjective = {
        id: -1,
        companyId: this.selectedCompany.companyId,
        title: "",
      };
      let olData: iModifyOverlayData = {
        dataTye: ModOverlayDataType.CREATE_OBJ,
        companyId: this.selectedCompany.companyId,
        model: new CompanyTrainingObjective(model),
      };
      // this.dialog.open(ModifyTrainingObjectiveComponent, {
      this.dialog.open(ModifyOverlayComponent, {
        width: "500px",
        height: "300px",
        // "maxWidth": '1800px',
        // "maxHeight": '1000px',
        backdropClass: "backdrop-background",
        data: olData,
      });
      // .afterClosed().subscribe((companyObjectives:iCompanyTrainingObjective[] | undefined) => {
      //   if (companyObjectives) {

      //     this.companyObjectives = companyObjectives
      //   }
      // })
    }
  }

  async deleteInject(e: iCompanyInject) {
    if (!this.selectedCompany) {
      console.log(`!selectedCompany`);
      return;
    }
    const r = await this.api.apiReq(HTTP_METHOD.DELETE, `inject/company-inject/${e.id}`,{} )
    this.storage.removeCompanyInject(e)

  }
  async editInject(e: iCompanyInject | undefined) {
    if (!this.selectedCompany) {
      return;
    }
    if (!e) {
      const newClean = getCleanCompanyInject();
      newClean.companyId = this.selectedCompany.companyId;

      let olData: iModifyOverlayData = {
        dataTye: ModOverlayDataType.CREATE_COMPANY_INJECT,
        companyId: this.selectedCompany.companyId,
        model: new CompanyInject(newClean),
        // exerciseInjects: this.exerciseInjects,
        // companyInjects: this.companyInjects,
      };

      this.dialog.open(ModifyOverlayComponent, {
        width: "85%",
        maxWidth: "1800px",
        height: "85%",
        maxHeight: "1000px",
        backdropClass: "backdrop-background",
        data: olData,
      });
      //   .afterClosed().subscribe((companyItems:iCompanyInject[] | undefined) => {
      //   if (companyItems) {

      //     this.companyInjects = companyItems
      //   }
      // })
    } else {
      let olData: iModifyOverlayData = {
        dataTye: ModOverlayDataType.MODIFY_COMPANY_INJECT,
        companyId: this.selectedCompany.companyId,
        model: new CompanyInject(e),
        // exerciseInjects: this.exerciseInjects,
        // companyInjects: this.companyInjects,
      };

      this.dialog.open(ModifyOverlayComponent, {
        width: "85%",
        maxWidth: "1800px",
        height: "85%",
        maxHeight: "1000px",
        backdropClass: "backdrop-background",
        data: olData,
      });
    }
  }

  async onInjectSetChanged(e: any) {

    this.selectedInjectSet = e == 'all' ? undefined : e ;

  }

  async onCompanyChanged(e: any) {
    console.log(`Company: -> `);
    console.log(e);
    console.log(`<--`);
    //this.storage.companyInjects
    var injectSets: string[] = []
    this.selectedCompany = e;
    this.selectedInjectSet = undefined;
    const loadedCompany = await this.api.apiGetP(`company/${e.companyId}`);
    const _selectedCompany: iCompany = loadedCompany as iCompany;
    this.storage.companyInjects = _selectedCompany.injectTemplates ?? [];
    this.storage.companyObjectives = _selectedCompany.trainingObjectives ?? [];

    this.storage.companyInjects.forEach((item) => {
      if(item.inject_set && !injectSets.includes(item.inject_set)) {
        injectSets.push(item.inject_set)
      }
    });
    // this.api.initExerciseClass(this.exercise)
    this.storage.injectSets = injectSets
    console.log(`loadedCompany: -> `);
    console.log(loadedCompany);
    console.log(`<--`);
    console.log(`selectedCompany: -> `);
    console.log(this.selectedCompany);
    console.log(`<--`);
    this.loadExercisesByCompany(e.companyId);
    // if (this.exercisesFlat.length == 0) {
    // }
    this.searchTxt = "";
  }

  onSearch(e: any) {
    console.log(e);
  }

  onExerciseChanged(e: MatSelectChange) {
    console.log(`onExerciseChanged >>>`);
    // console.log(e);
    // console.log(`onExerciseChanged <<<`);
    this.loadExDetail(e.value.id);
    this.storage.exerciseInjectsAdmin = [];
    this.selectedExerciseId = e.value.id;
    const exItem: ExerciseI = e.value as ExerciseI;
    this.selectedE = exItem; //new ExerciseClass(exItem)

    this.storage.exerciseInjectsAdmin = exItem.injects ?? [];
    // console.log(this.selectedE);
    this.searchTxt = "";
  }

  async loadExercisesByCompany(id: string) {
    const exDe = await this.api.apiGetP(`exercise/list-by-company/${id}`);
    // console.log(exDe);
    this.exercisesFlat = exDe as iAdminExerciseFlat[];
    this.exercisesFlat = this.exercisesFlat.filter(item => item.isDeBoarded == false)

    if (this.selectedExerciseId) {
      const index = this.exercisesFlat.findIndex(item => item.id == this.selectedExerciseId)
      // console.log(`loadExercisesByCompany > selectedExerciseId ${this.selectedExerciseId} index: ${index}`);
      if  (index != -1) {
        wait(100, () => {
          this.selectedE = this.exercisesFlat[index] as ExerciseI
        })
      }
    }
    // console.log(`loadExercisesByCompany >>>`);
    // console.log(this.selectedE);
    // console.log(`loadExercisesByCompany <<<`);
    // if (this.selectedE) {
    //   this.onExerciseChanged(this.selectedE)
    //   // const index = this.exercisesFlat.findIndex(item => item.id == this.selectedE!.id)
    //   // if (index != -1) {
    //   //   this.selectedE = this.exercisesFlat[index]
    //   // }
    // }
  }

  async loadExDetail(id: string) {
    const injectsByEx = await this.api.apiGetP(
      `inject/templates-by-exercise/${id}`
    );
    this.storage.exerciseInjectsAdmin = injectsByEx as iExerciseInject[];

    const trainingObjsByEx = await this.api.apiGetP(
      `trainingobjective/exercise/${id}`
    );
    this.storage.exerciseTrainingObjectsAdmin =
      trainingObjsByEx as iExerciseTrainingObjective[];
    console.log(this.storage.exerciseTrainingObjectsAdmin);
  }

  showDeleteTrainingBtn(trainngId: number): boolean {
    const items = _.map(this.storage.exerciseInjectsAdmin, 'learning_objectives').toString().split(',')

    return !items.includes(trainngId.toString())
  }
  async deleteTrainingFromExercise(e: number) {
    this.showDeleteTrainingBtn(e)
    if (this.selectedExerciseId) {
      console.log(`deleteTrainingFromExercise: ${e}`);
      console.log(this.storage.exerciseInjectsAdmin);

      // const index = this.storage.exerciseInjectsAdmin.findIndex(
      //   (item) => item.id == e
      // );
      // if (index != -1) {
      //   this.storage.exerciseInjectsAdmin.splice(index, 1);
      // }
    }

    // console.log(e);
    // return
   /*  if (this.selectedExerciseId) {
      console.log(`deleteTrainingFromExercise: ${e}`);
      await this.api.apiPost(`trainingobjective/company-to-exercise/`, {
        id: e,
        exerciseId: this.selectedExerciseId,
      });

      const index = this.storage.exerciseTrainingObjectsAdmin.findIndex(
        (item) => item.id == e
      );
      if (index != -1) {
        this.storage.exerciseTrainingObjectsAdmin.splice(index, 1);
      }
    } */
  }

  async deleteInjectFromExercise(e: number) {
    // console.log(e);
    // return
    if (this.selectedExerciseId) {
      console.log(`deleteInjectFromExercise: ${e}`);
      //await this.api.apiDeleteP(`inject/exercise-inject/${e}`);
      await this.api.apiPost(`inject/template-to-exercise/`, {
        id: e,
        exerciseId: this.selectedExerciseId,
      });

      const index = this.storage.exerciseInjectsAdmin.findIndex(
        (item) => item.exercise_inject_id == e
      );
      if (index != -1) {
        this.storage.exerciseInjectsAdmin.splice(index, 1);
      }
    }
  }

  get companyInjectsFiltered(): iCompanyInject[] {

    let out: iCompanyInject[] = []
    if (!this.selectedCompany) {
      return [];
    }
    if (this.searchTxt.length >= 2) {
      out = this.storage.companyInjects.filter(
        (item: iCompanyInject) =>
          item.short_title
            .trim()
            .toLowerCase()
            .includes(this.searchTxt.trim().toLowerCase()) ||
          item.inject_no
            .trim()
            .toLowerCase()
            .includes(this.searchTxt.trim().toLowerCase())
      );

    } else {
      out = this.storage.companyInjects;
    }
    if (this.selectedInjectSet) {
      out = out.filter(
        (item: iCompanyInject) =>
          item.inject_set == this.selectedInjectSet

      );
    }
    return out;
  }

  drop(event: CdkDragDrop<any[]>) {
    if (event.previousContainer === event.container) {
      console.log("MOVE");
      //  moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      console.log("COPY");
      let transferendInject = event.previousContainer.data[event.previousIndex];
      if (this.selectedExerciseId) {
        const index = this.storage.exerciseInjectsAdmin.findIndex(
          (item) => item.inject_no == transferendInject.inject_no
        );
        if (index == -1) {
          this.transferFromCompanyToExercise(
            transferendInject.id,
            this.selectedExerciseId
          );
        }
      }
    }
  }

  onMoveInjectToExercise(item: iCompanyInject) {
    console.log(item);


    if (this.selectedExerciseId) {
      this.transferFromCompanyToExercise(item.id, this.selectedExerciseId);
      this.checkTrainingFromTransfer(item);

    }
  }

  private checkTrainingFromTransfer(item: iCompanyInject) {
    let learning_objectiveIds: string[] = []
    if(item.learning_objectives) {

      learning_objectiveIds = item.learning_objectives.split(',')
    }
    console.log(learning_objectiveIds);
    console.log(this.storage.exerciseTrainingObjectsAdmin);
    if (this.selectedExerciseId) {

      for (var i = 0; i < learning_objectiveIds.length; i ++) {
        const transferendInjectId = parseInt(learning_objectiveIds[i])
        const index = this.storage.exerciseTrainingObjectsAdmin.findIndex(
          (item) => item.id == transferendInjectId
        );
        // console.log(`index ${index}`);
        if (index == -1) {
          this.transferTrainObjFromCompanyToExercise(
            transferendInjectId,
            this.selectedExerciseId
          );
        }
      }
    }
  }

  dropTraining(event: CdkDragDrop<any[]>) {
    if (event.previousContainer === event.container) {
      console.log("MOVE");
    } else {
      console.log("COPY");
      let transferendInject = event.previousContainer.data[event.previousIndex];
      console.log(transferendInject);
      if (this.selectedExerciseId) {
        const index = this.storage.exerciseTrainingObjectsAdmin.findIndex(
          (item) => item.id == transferendInject.id
        );
        if (index == -1) {
          this.transferTrainObjFromCompanyToExercise(
            transferendInject.id,
            this.selectedExerciseId
          );
        }
      }
    }
  }
}
