import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { iModifyOverlayData, ModOverlayDataType } from 'src/app/model/texteditOLdata.model';
import { CompanyTrainingObjective, iCompanyTrainingObjective } from 'src/app/model/trainingObj.model';
import { ApiService } from 'src/app/services/api.service';
import { StorageService } from 'src/app/services/storage.service';

@Component({
  selector: 'app-modify-training-objective',
  templateUrl: './modify-training-objective.component.html',
  styleUrls: ['./modify-training-objective.component.scss']
})
export class ModifyTrainingObjectiveComponent {
  selectedTrainingObj: iCompanyTrainingObjective | undefined
  olData: iModifyOverlayData | undefined
  viewType: ModOverlayDataType = ModOverlayDataType.LOADING
  VIEWTYPES = ModOverlayDataType
  constructor(
    public storage: StorageService,
    private api: ApiService,
    public dialogRef: MatDialogRef<ModifyTrainingObjectiveComponent>
    ,@Inject(MAT_DIALOG_DATA) public data: iModifyOverlayData,
    ) {
      this.olData = data
      this.viewType = data.dataTye
      // console.log(data);
      // const yy = this.data.model instanceof CompanyTrainingObjective;
      // const yy2 = this.data.model instanceof CompanyInject;
      // console.log(yy) // Will be true
      // console.log(yy2) // Will be true
      switch (data.dataTye) {
        case ModOverlayDataType.CREATE_OBJ:
          if (data.model instanceof CompanyTrainingObjective) {
            this.selectedTrainingObj = data.model.item
          }
          return
        case ModOverlayDataType.MODIFY_OBJ:
          if (data.model instanceof CompanyTrainingObjective) {
            this.selectedTrainingObj = data.model.item
          }
          return



      }

      // if ((data.dataTye == ModOverlayDataType.CREATE_OBJ || data.dataTye == ModOverlayDataType.MODIFY_OBJ) && data.model instanceof CompanyTrainingObjective) {
      //   this.selectedTrainingObj = data.model.item
      // }

    }
  close() {
    this.dialogRef.close()
  }

  async save(){
    console.log(this.selectedTrainingObj);
    console.log(this.olData);
  }

  get saveEnabled():boolean {

    if (!this.olData) { return false }
    switch (this.olData.dataTye) {
      case ModOverlayDataType.CREATE_OBJ:
        if (this.selectedTrainingObj && this.selectedTrainingObj.title.length > 3) {
          return true
        }
        return false
      case ModOverlayDataType.MODIFY_OBJ:
        if (this.selectedTrainingObj && this.selectedTrainingObj.title.length > 3) {
          return true
        }
        return false


    }
    return false
  }
}
