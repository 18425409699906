import { Component, inject, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ExercisePerformanceTeamItem, ExerciseTimelineItemType, HourItemI, IExerciseTimelineInjectRef, IExerciseTimelineItem, InjectsPerStatus } from 'src/app/model/exercise.model';
import { ApiService } from 'src/app/services/api.service';
import { StorageService } from 'src/app/services/storage.service';
import * as _ from 'lodash';
import { TrainerFeedbackVoteType } from 'src/app/model/trainerFeedback.model';
import { addDays, addHours, addMinutes, differenceInDays, differenceInHours, endOfDay, isSameDay, startOfDay } from 'date-fns';
import { TIME } from 'src/app/services/helpers';
import { UTCDate } from '@date-fns/utc';
import { getExerciseDays } from 'src/app/services/utils/functions';

@Component({
  selector: 'app-exercise-performance-overlay',
  templateUrl: './exercise-performance-overlay.component.html',
  styleUrls: ['./exercise-performance-overlay.component.scss']
})
export class ExercisePerformanceOverlayComponent implements OnInit{
  public api = inject(ApiService);
  public storage = inject(StorageService);
  public dialogRef = inject(MatDialogRef<ExercisePerformanceOverlayComponent>)

  timelineItems: IExerciseTimelineItem[] = []
  selectedTimelineItem: IExerciseTimelineItem | undefined
  performanceItem: ExercisePerformanceTeamItem | undefined
  injectStatusList:InjectsPerStatus[] = []
  injectStatusList2:InjectsPerStatus[] = []
  hourItems:HourItemI[] = []
  exerciseDays:HourItemI[] = []
  selectedExerciseDay:HourItemI | undefined
  ngOnInit(): void {

    //   let exercistEndDate = this.storage.currentExer.endDateTime
    //   const days = differenceInDays(TIME.tt_toDate(exercistEndDate), TIME.tt_toDate(exercistStartDate))
    if (this.storage.currentExer) {
      let exercistStartDate = this.storage.currentExer.startDateTime

      this.timelineItems = this.storage.currentExer.timelineItems.filter(item => item.itemType == ExerciseTimelineItemType.TEAM)
      console.log(this.timelineItems);
      // this.hourItems =  this.getExerciseDays()
      this.hourItems = getExerciseDays(Number(this.storage.currentExer.getDateTime().getTime()), this.storage.currentExer.durationInHours)
       console.log(this.hourItems);
        const dayCount = this.hourItems.filter(item => item.startNewDate == true)
        //differenceInDays(TIME.tt_toDate(exercistEndDate), TIME.tt_toDate(exercistStartDate))
        // console.log(`dayCount: ${dayCount}`);
        console.log(dayCount);
        this.exerciseDays = dayCount
    }
  }
  onDayChanged(e:any) {

    console.log(this.selectedExerciseDay);
    var _assignedInjects: IExerciseTimelineInjectRef[] = []
    if(e == 'all') {
      if(!this.storage.currentExer) {return }
      if (!this.performanceItem) { return }
      if (!this.selectedExerciseDay) { return }
      _assignedInjects = this.performanceItem.assignedInjects
      this._calcStatesListRight(_assignedInjects)
      this.number_of_injects_2 = _assignedInjects.length
    } else {
      if(!this.storage.currentExer) {return }
      if (!this.performanceItem) { return }
      if (!this.selectedExerciseDay) { return }
      let startDate = //this.selectedExerciseDay.dayIndex == 0 ?
      addDays(TIME.tt_toDate(this.storage.currentExer.startDateTime), this.selectedExerciseDay.dayIndex)
      console.log(startDate);
      // deliveryDateTime
      this.performanceItem.assignedInjects.forEach((ele) => {
        // console.log(ele.deliveryDateTime);
        let pos2:number = Number(ele.leftPosSmall)
        // console.log(`pos2 : ${pos2}`);

        const newDeliveryDateTime: number = Math.round((pos2 / 61) * 60)

        // console.log(`newDeliveryDateTime : ${newDeliveryDateTime}`);
        const startDateTime = addMinutes(this.storage.currentExer!.getDateTimeRaw(), newDeliveryDateTime)//TIME.date_to_tt()
        console.log(startDateTime);
        if(isSameDay(startDate, startDateTime)) {
          _assignedInjects.push(ele)
        }
      })
      console.log(_assignedInjects);
      // let names = _.map(_assignedInjects, 'short_title')
      // console.log(names);
      this._calcStatesListRight(_assignedInjects)
      this.number_of_injects_2 = _assignedInjects.length
      // let injects = this.performanceItem.assignedInjects.filter(item => item.startNewDate == true)
      // let exercistEndDate = this.storage.currentExer.endDateTime
      // const days = differenceInDays(TIME.tt_toDate(exercistEndDate), TIME.tt_toDate(exercistStartDate))
      // const hoursCount = differenceInHours(TIME.tt_toDate(exercistEndDate), TIME.tt_toDate(exercistStartDate))
      // let startHour = TIME.tt_toDate(exercistStartDate).getUTCHours()
    }



  }
  onChanged(e:any) {
    this.performanceItem = undefined
    this.selectedExerciseDay = undefined
    this.injectStatusList2 = []
    this.number_of_objects_rated_2 = 0
    this.number_of_objectives_2 = 0
    console.log(this.selectedTimelineItem);
    if(e == 'all') {
      this.loadAllTimelinePerformance()
    } else {

      this.loadTimelinePerformance(this.selectedTimelineItem!.id)
    }


  }
  private async loadAllTimelinePerformance() {
    this.clearStats()
    var number_of_injects = 0
    var _assignedInjects: IExerciseTimelineInjectRef[] = []
    for(var i = 0; i < this.timelineItems.length; i ++) {
      const res = await this.api.apiGetP(`exercise/timeline-performance/${this.timelineItems[i].id}`)
      var _performanceItem: ExercisePerformanceTeamItem = res as ExercisePerformanceTeamItem
      number_of_injects += _performanceItem.assignedInjects.length;
      _assignedInjects = _.concat(_assignedInjects, _performanceItem.assignedInjects)
    }
    this.performanceItem = {
      title: "all",
      id: -199,
      itemType: ExerciseTimelineItemType.EVENTS,
      assignedInjects: _assignedInjects,
    }
    // this.performanceItem = res as ExercisePerformanceTeamItem
    // console.log(this.performanceItem);
     this.number_of_injects = this.performanceItem.assignedInjects.length


    this._calcStatesList(this.performanceItem.assignedInjects)
  }
  private async loadTimelinePerformance(id:number) {
    this.clearStats()
    const res = await this.api.apiGetP(`exercise/timeline-performance/${id}`)
    this.performanceItem = res as ExercisePerformanceTeamItem
    console.log(this.performanceItem);
    this.number_of_injects = this.performanceItem.assignedInjects.length


    this._calcStatesList(this.performanceItem.assignedInjects)
  }
  private _calcStatesList(pInjects:IExerciseTimelineInjectRef[]){
    this.injectStatusList = []
    for (const assignedInject of pInjects) {
      console.log(assignedInject.trainerFeedBack);

      if (assignedInject.trainerFeedBack) {

        this.number_of_objects_rated +=
        (assignedInject.trainerFeedBack.vote && assignedInject.trainerFeedBack.vote != TrainerFeedbackVoteType.NOT_RATED) ? 1 : 0
        this.number_of_ect_comment += assignedInject.trainerFeedBack.feedbacks ? assignedInject.trainerFeedBack.feedbacks.length : 0
      }
      if (assignedInject.trainingObjectives) {

        this.number_of_objectives += assignedInject.trainingObjectives.length
      }
      /* if (assignedInject.learning_objectives) {

        this.number_of_objectives += assignedInject.learning_objectives.split(',').length
      } */
    }
    for (const __status of this.storage.injectStates) {
      const __index = this.injectStatusList.findIndex((item) => item.status == __status);
      if(__index == -1){
        console.log(__status.color)
        const __injects = pInjects.filter((__inject) => __inject.state.id == __status.id);
        let __item:InjectsPerStatus = {
          status:__status,
          injects: __injects,//this.ui.exerciseinjects.filter((__inject) => __inject.state.value == __status.value),
          percent: __injects.length / pInjects.length
        }
        this.injectStatusList.push(__item);
      } else {

      }
    }

    console.log(this.injectStatusList);

  }
  private _calcStatesListRight(pInjects:IExerciseTimelineInjectRef[]){
    this.injectStatusList2 = []
    this.number_of_objects_rated_2 = 0
    this.number_of_ect_comment_2 = 0
    this.number_of_objectives_2 = 0
    for (const assignedInject of pInjects) {
      console.log(assignedInject.trainerFeedBack);

      if (assignedInject.trainerFeedBack) {

        this.number_of_objects_rated_2 +=
        (assignedInject.trainerFeedBack.vote && assignedInject.trainerFeedBack.vote != TrainerFeedbackVoteType.NOT_RATED) ? 1 : 0
        this.number_of_ect_comment_2 += assignedInject.trainerFeedBack.feedbacks ? assignedInject.trainerFeedBack.feedbacks.length : 0
      }
      if (assignedInject.trainingObjectives) {
        this.number_of_objectives_2 += assignedInject.trainingObjectives.length
      }
      /* if (assignedInject.learning_objectives) {
        this.number_of_objectives += assignedInject.learning_objectives.split(',').length
      } */
    }
    for (const __status of this.storage.injectStates) {
      const __index = this.injectStatusList2.findIndex((item) => item.status == __status);
      if(__index == -1){
        console.log(__status.color)
        const __injects = pInjects.filter((__inject) => __inject.state.id == __status.id);
        let __item:InjectsPerStatus = {
          status:__status,
          injects: __injects,//this.ui.exerciseinjects.filter((__inject) => __inject.state.value == __status.value),
          percent: __injects.length / pInjects.length
        }
        this.injectStatusList2.push(__item);
      } else {

      }
    }

    console.log(this.injectStatusList2);

  }
  close() {
    this.dialogRef.close();
  }

  number_of_injects_2 = 0
  number_of_objectives_2 = 0

  number_of_objects_rated_2 = 0
  number_of_ect_comment_2 = 0

  number_of_injects = 0
  number_of_objectives = 0
  // number_of_trainers = 0
  // number_of_ect_users = 0
  number_of_objects_rated = 0
  number_of_ect_comment = 0

  private clearStats() {
    this.number_of_injects = 0
    this.number_of_objectives = 0
    // this.number_of_trainers = 0
    // this.number_of_ect_users = 0
    this.number_of_objects_rated = 0
    this.number_of_ect_comment = 0
  }
  // get number_of_injects(): number {
  //   return this.performanceItem ? this.performanceItem.assignedInjects.length : 0
  // }

  // get number_of_objectives(): number {
  //   return 0
  // }

  // get number_of_trainers(): number {
  //   return 0
  // }

  // get number_of_ect_users(): number {
  //   return 0
  // }

  // get number_of_objects_rated(): number {
  //   return 0
  // }

  // get number_of_ect_comment(): number {
  //   return 0
  // }
  // private getExerciseDays() {
  //   if(!this.storage.currentExer) {return []}
  //   let exercistStartDate = this.storage.currentExer.startDateTime
  //   let exercistEndDate = this.storage.currentExer.endDateTime
  //   const days = differenceInDays(TIME.tt_toDate(exercistEndDate), TIME.tt_toDate(exercistStartDate))
  //   const hoursCount = differenceInHours(TIME.tt_toDate(exercistEndDate), TIME.tt_toDate(exercistStartDate))
  //   let startHour = TIME.tt_toDate(exercistStartDate).getUTCHours()
  //   console.log(`start h ${startHour}, count h ${hoursCount} days: ${days}`);

  //   var _currentStartHour = startHour
  //   var _date: Date =  TIME.tt_toDate(exercistStartDate);
  //   console.log(`_date: ${_date} `);
  //   var _lastAddedDate: Date = _date;
  //   var _exerciseDay = []
  //   var _dayIndex = -1
  //   var _hourCountPerDay = 0
  //   for(var i = 0; i < hoursCount; i ++) {

  //     let starMinutes = "00"

  //     let _dateSting = ''
  //     let _startNewDate = false
  //     if(_currentStartHour == 0 || i == 0 ) {
  //       _dateSting = TIME.formatedDateStringWithoutYear(_date, '/')
  //       _lastAddedDate = _date;
  //       _startNewDate = true
  //       _dayIndex ++


  //     } else {

  //     }
  //     _hourCountPerDay =
  //     // ist erster tag
  //     isSameDay(_date, TIME.tt_toDate(exercistStartDate)) ?
  //     differenceInHours(endOfDay(TIME.tt_toDate(exercistStartDate)), TIME.tt_toDate(exercistStartDate))
  //      :
  //      // ist letzter tag
  //     isSameDay(_date, TIME.tt_toDate(exercistEndDate)) ?
  //     differenceInHours(TIME.tt_toDate(exercistEndDate), startOfDay(new UTCDate(_date)))
  //       :
  //       differenceInHours(endOfDay(new UTCDate(_date)), startOfDay(new UTCDate(_date)));
  //     // _hourCountPerDay = (_dayIndex == days  ) ?
  //     // differenceInHours(TIME.tt_toDate(exercistEndDate), new UTCDate(_date)) :
  //     // differenceInHours(endOfDay(new UTCDate(_date)), new UTCDate(_date));
  //     // _hourCountPerDay = differenceInHours(endOfDay(new Date(_date.toUTCString()).setUTCHours(_currentStartHour)), new Date(_date.toUTCString()).setUTCHours(_currentStartHour));
  //     console.log(`_dayIndex == days ? ${_dayIndex == days} _hourCountPerDay: ${_hourCountPerDay} date: ${_date} i: ${i} _currentStartHour: ${_currentStartHour}`);
  //     let _hourItem:HourItemI = {
  //       index: i,
  //       dayIndex: _dayIndex,
  //       hoursCount: _hourCountPerDay,
  //       startNewDate: _startNewDate,
  //       dateString: _dateSting,
  //       hourString: (_currentStartHour < 10 ? ("0" + _currentStartHour.toString()) : ( _currentStartHour.toString() )) + ":"+starMinutes.toString()
  //     };


  //     if (_currentStartHour < 23) {
  //       _currentStartHour ++;
  //       // _hourCountPerDay ++;
  //     } else {
  //       _currentStartHour = 0;
  //       // _hourCountPerDay = 0;
  //     }

  //     _date = new UTCDate(addHours(_date, 1));

  //     _exerciseDay.push(_hourItem);
  //     console.log(_hourItem);

  //   }

  //   // this.hourItems = _exerciseDay
  //   // console.log(this.hourItems);
  //   return _exerciseDay
  // }
}
