

<div style="height: calc(100% - 36px); overflow: hidden;">
  <mat-toolbar style="height: 36px; border-radius: 0px; background-color: rgba(0,0,0,0.5)!important;" class="pl0">
    <span class="toolbar-spacer"></span>
    <mat-icon style="color:#ABBBC7; cursor: pointer;   -webkit-user-select:none;" (click)='close()'>close</mat-icon>
  </mat-toolbar>


  <div class="d-flex align-center" style="height: 45px;">
    <div class="p-2" style="display: flex;" >

      <input type="file" #csvReader name="Upload CSV" id="txtFileUpload"  accept=".json" (change)="uploadListener($event)"/>

      <ect-color-btn  [iconOnly]="false"  class="mr5" title="Select File" icon="note_add" color="primary" (clicked)="csvReader.click()" ></ect-color-btn>

      {{fileName}}

    </div>
    <div class="p-2 ml-auto" *ngIf="!isImporting">
      <!-- <button *ngIf="isRecordComplete" mat-flat-button color="accent" (click)="importIntoDataBase()">Übernehmen</button> -->
      <ect-color-btn  *ngIf="isRecordComplete" [iconOnly]="false"  class="mr5" title="Upload" icon="upload" color="primary" [disabled]="!canImport" (clicked)="importIntoDataBase()" ></ect-color-btn>
    </div>
    <div class="p-2 ml-auto" *ngIf="isImporting">
      <mat-spinner [diameter]='25' color='accent' ></mat-spinner>
    </div>
  </div>

  <div class="d-flex w100" style="font-weight: 400; color: #a2a686; font-size: 12px;  background-color: rgb(31, 33, 37); height: 40px;">
    <div class="p-2 w30"></div>
    <div class="p-2 w160 ">INJECT NO.</div>
    <div class="p-2 flex-fill">NAME</div>
    <div class="p-2 w160 ml-auto">DELIVERY METHOD</div>
    <div class="p-2 w30 "> _ </div>
    <div class="p-2 w30 "> _ </div>
    <div class="p-2 w30 "> _ </div>
  </div>

  <div class="d-flex w100 flex-column" style="overflow-y: scroll; height: 100%;">

    <div class="p-0 importTableRow d-flex" *ngFor="let importItem of injectImportList" [class.warn-color]="isIncluded(importItem)">
      <div class="p-2 w30"></div>
      <div class="p-2 w160 ">{{importItem.inject_no}}</div>
      <div class="p-2 flex-fill">{{importItem.short_title}}</div>
      <div class="p-2 w160 ml-auto">{{importItem.deliveryMethod}}</div>
      <div class="p-2 w30"><mat-icon *ngIf="isIncluded(importItem)" style="font-size: 18px;" matTooltip="INNJECT ID exists" matTooltipClass="errorTT">info</mat-icon></div>
      <div class="p-2 w30"><mat-icon *ngIf="isIncluded(importItem)" (click)="editItemFromImport(importItem)"
        class="dxfx_icon_btn_mini" matTooltip="Edit INJECT ID" matTooltipClass="baseTT">edit</mat-icon></div>
      <div class="p-2 w30"><mat-icon *ngIf="isIncluded(importItem)" (click)="removeItemFromImport(importItem)"
        class="dxfx_icon_btn_mini_WARN" matTooltip="Remove from import" matTooltipClass="baseTT">close</mat-icon></div>
    </div>

  </div>

  <!-- <table mat-table [dataSource]="injectsDataSource" matSort style="width: 100%; border-radius: 0px; " >
    <ng-container matColumnDef="inject_no">
      <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 150px;"><span class='pl10'>INNJECT NO</span></th>
      <td mat-cell *matCellDef="let element" style="width: 150px;"><span class='pl10'>{{element.inject_no}}</span></td>
    </ng-container>

    <ng-container matColumnDef="name" >
      <th mat-header-cell *matHeaderCellDef >NAME</th>
      <td mat-cell *matCellDef="let element">{{element.short_title}}</td>
    </ng-container>



    <ng-container matColumnDef="DELIVERY METHOD" justify='end'>
      <th mat-header-cell *matHeaderCellDef style="width: 150px;">DELIVERY METHOD</th>
      <td mat-cell *matCellDef="let element">{{element.deliveryMethod}}</td>
    </ng-container>

    <tr mat-header-row  *matHeaderRowDef="injectDisplayedColumns" ></tr>
    <tr mat-row class="element-row" [class.warn]="isIncluded(row)"
    *matRowDef="let row; columns: injectDisplayedColumns;" ></tr>

  </table> -->
</div>
