
<div class="inject-item-circle-holder_single"
  #_drag
  cdkDrag
  cdkDragLockAxis="x"
  autoScrollStep="10"

  autoScrollDisabled="true"
  (animationstart)="aniStart()"
  (cdkDragEnded)="dragend($event, inject)"
  (cdkDragStarted)="dragStartet($event, inject)" (cdkDragMoved)="onDragMoved($event, inject)"
  [cdkDragDisabled]="dragDisabled || storage.getAccess([ExerciseUserRoles.OBSERVER, ExerciseUserRoles.TRAINER])"
  [ngStyle]="styles"
  [class.dragCursor]="!dragDisabled"
  [class.circleFadeIn]="fadedIn"
  [matMenuTriggerFor]="menu"
  (dblclick)="openDetail()"
  matTooltipClass="injectTimelineTT"
  matTooltipPosition="before"
  [matTooltipDisabled]="isDragging"
  matTooltip='{{tooltip}}'
>

<!--(click)="openDetailTest(inject)"   -->

  <div class="inject-item-background-single" [ngStyle]="{'background-color':inject.state.color}"
  [class.inject-item-background-single-ani]='isAnimated || !dragDisabled'></div>

  <div class="inject-item-circle-title-single b" [class.inject-item-circle-title-top-single]='ui.timeLineLabelOnTop'style="margin-left: 0px!important;">
    <div class="d-flex align-items-center" style="height: 100%; width: 100%; user-select: none;">
      <div class="d-flex align-items-start " *ngIf="dragDisabled" >
        <!-- <div class="p-0">{{startDateTime}}</div> -->
        <mat-icon class="dxfx_icon_btn_medium dark" [matMenuTriggerFor]="menu">more_vert</mat-icon>
        <div class="p-0">{{inject.short_title | slice: 0:10}}</div>
      </div>

      <div class="p-0 d-flex h100 align-items-center" *ngIf="!dragDisabled" style="padding-top: 4px!important;">
        <div class="p-0">
          <mat-icon class="fs18" style="height: 16px!important;">arrow_back</mat-icon>
        </div>
        <div class="p-0 fs16 b" style="margin-top: -3px!important; margin-right: 4px;">{{startDateTime}}</div>
        <div class="p-0 ">
          <mat-icon class="fs18" style="height: 16px!important;">arrow_forward</mat-icon>
        </div>
      </div>
    </div>
  </div>

  <mat-menu #menu="matMenu" >
    <button mat-menu-item (click)="openDetail()" >
      <mat-icon class="fs18" style="margin-right: 10px!important;">open_in_new</mat-icon>
      <span class="fs16">DETAIL</span>
    </button>
    <button mat-menu-item (click)="onChangeTimeTapped()" *exerciseAccess='[ExerciseUserRoles.ADMIN, ExerciseUserRoles.EXCON]'>
      <mat-icon  class="fs18" style="margin-right: 10px!important;">timer</mat-icon>
      <span class="fs16">TIME</span>
    </button>
    <button mat-menu-item (click)="changeTimeAndDuration(inject, $event)" *exerciseAccess='[ExerciseUserRoles.ADMIN, ExerciseUserRoles.EXCON]'>
      <mat-icon class="fs18" style="margin-right: 10px!important;">schedule</mat-icon>
      <span class="fs16">DURATION</span>
    </button>
  </mat-menu>

  <div [class.inject-item-circle-animation-single]='isAnimated' [ngStyle]="{'border-color':inject.state.color}"></div>
  <div [class.inject-item-circle-dragging]='!dragDisabled' [ngStyle]="{'border-color':inject.state.color}"></div>
</div>

