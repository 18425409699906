
import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from "@angular/core";

import { StorageService } from '../../services/storage.service';
import { UiService } from "src/app/services/ui.service";
import { ExerciseTimelineItemType, IExerciseTimelineInjectRef, IExerciseTimelineItem } from "src/app/model/exercise.model";
import { Subscription } from "rxjs";
import { ViewportScroller } from "@angular/common";


@Component({
  selector: 'side-nav-role-item',
  template: `
        <div class="p-0 sideNavRoleItem" [ngStyle]="{'height': tlItemHeight}"
          [class.sideNavRoleItemlight]="!ui.darkMode" id='{{itemId}}'>

          <div class="d-flex align-items-center h100">
            <div class="p-0 ml-auto fs16 tlr  d-flex flex-column pr5">

              <div class="p-0 itemTitle ml-auto" *ngIf="tlItem.itemType == ExerciseTimelineItemTypes.TEAM">{{tlItem.itemType}}</div>
              <div class="p-0 ">{{tlItem.title}}</div>

            </div>
          </div>
        </div>
  `,
  styleUrls: ['./timeline.component.scss']
})
export class SideNavRoleItemComponent implements OnInit, OnDestroy {
  @Input() tlItem!: IExerciseTimelineItem
  @Input() tlItemHeight!: string;
  @Output() onScrollTo = new EventEmitter<string>()
   ExerciseTimelineItemTypes = ExerciseTimelineItemType;
     animationSub: Subscription | undefined;
     itemId: string = 'id'
  constructor(public ui: UiService, private scroller: ViewportScroller) {}

  ngOnInit(): void {
    this.itemId = `tlItem-${this.tlItem.id}`
  this.animationSub = this.ui.injectAnimation.subscribe(
      (inject: IExerciseTimelineInjectRef) => {
        if(inject.timelineItem) {
          if (inject.timelineItem.id == this.tlItem.id) {
            console.log(`treffer ${this.tlItemHeight}`);

            this.onScrollTo.emit(this.itemId)
            // document.getElementById(this.itemId)!.scrollIntoView();
            // {
            //   behavior: "smooth",
            //   block: "center",
            //   inline: "center"
            // }
          }
        }
      }
    );
  }
  ngOnDestroy(): void {
    if (this.animationSub) {
      this.animationSub.unsubscribe();
      this.animationSub = undefined;
    }
  }
    // getHeight(timelineItem: IExerciseTimelineItem) {
    //   if (timelineItem.height ) {
    //     const h = timelineItem.height;
    //     const h3 = 60 * h;
    //     //return (60 + (60 * h)).toString() + 'px'
    //     return `${h3 + 60}px`;
    //   } else {
    //     return "60px";
    //   }
    // }
}
