<div class="container-fluid p0" style="height: 100%; overflow: hidden;">
  <mat-toolbar class="overlay-toolbar">
    <span class="toolbar-spacer"></span>
    <mat-icon class="dxfx_icon_btn_medium ml5" (click)='close()'>close</mat-icon>
  </mat-toolbar>

  <div class="container-fluid" style=" height: calc(100% - 88px); overflow-y:scroll; background-color: black;">
      <div class="d-flex h100 align-items-center jusify-content-center" *ngIf="selectedTrainingObj && viewType == VIEWTYPES.CREATE_OBJ">
        <div class="p-2 flex-fill" >
          <div class="formGroup textfield100 " style="margin-top: -15px;">
            <mat-label class='mat-caption'>TITLE*</mat-label>
            <input type="text" class="formControl textfield100 textFieldH35" placeholder="title" style="text-transform: uppercase;"
            [(ngModel)]="selectedTrainingObj.title" >
          </div>
        </div>
      </div>

      <div class="d-flex h100 align-items-center jusify-content-center" *ngIf="selectedTrainingObj && viewType == VIEWTYPES.MODIFY_OBJ">
        <div class="p-2 flex-fill">
          <div class="formGroup textfield100 " style="margin-top: -15px;">
            <mat-label class='mat-caption'>TITLE*</mat-label>
            <input type="text" class="formControl textfield100 textFieldH35" placeholder="title" style="text-transform: uppercase;"
            [(ngModel)]="selectedTrainingObj.title" >
          </div>
        </div>
      </div>


  </div>

  <mat-toolbar class="overlay-toolbar">
    <span class="toolbar-spacer"></span>
    <ect-color-btn class="mr5" title="save" icon="save" color="primary" [disabled]="!saveEnabled" (clicked)="save()" ></ect-color-btn>
  </mat-toolbar>
</div>
