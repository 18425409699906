import { Component, inject, Input, OnInit } from "@angular/core";
import { iCompanyInject } from "src/app/model/company.model";
import { INJECT_DELIVERY_METHOD_LIST_ITEM } from "src/app/model/exercise.model";
import { StorageService } from "src/app/services/storage.service";
import { InjectDeliveryMethods, selectedInjectDeliveryMethodItem } from "src/app/services/utils/companyIn.help";
import * as _ from 'lodash'
import { MatSelectChange } from "@angular/material/select";
import { iCompanyTrainingObjective } from "src/app/model/trainingObj.model";
@Component({
  selector: 'modify-company-inject',
  templateUrl: './modify-company-inject.component.html',

    styleUrls: ['../modify-overlay.component.scss']
})
export class ModifyCompanyInjectComponent implements OnInit {
  @Input() inject!: iCompanyInject
  // learningObjectives: string[] = []
  learningObjectivIds: string[] = []
  // learningObjectives: iCompanyTrainingObjective[] = []
  selectedLearningObjectives: iCompanyTrainingObjective[] = []
  deliveryMethods: INJECT_DELIVERY_METHOD_LIST_ITEM[] = []
  selectedDelivertMethod: INJECT_DELIVERY_METHOD_LIST_ITEM | undefined
  errorCopy: string = ""
  // injectNoHasError: boolean = false
  isSaving = false
  disabled = false
  isMainInfos = true

  public storage = inject(StorageService)
  injectNoKeyDown(e:any) {
    // this.injectNoHasError = false
    this.errorCopy = ''
  }
  ngOnInit(): void {
    //  this.learningObjectives = this.storage.companyObjectives
    // this.learningObjectives = _.map(this.storage.companyObjectives, 'title')
    // this.storage.companyObjectives.forEach(ele => {
    //   console.log(ele);
    // })
    console.log(this.inject);
    if (this.inject.learning_objectives) {
      this.learningObjectivIds = this.inject.learning_objectives.split(',')
      console.log(this.learningObjectivIds);
      this.selectedLearningObjectives = this.storage.companyObjectives.filter(item => this.learningObjectivIds.includes(item.id.toString()))
    }
    console.log(this.selectedLearningObjectives);
    this.deliveryMethods = InjectDeliveryMethods
    this.selectedDelivertMethod = selectedInjectDeliveryMethodItem(this.inject.deliveryMethod)

  }
  onDeliMethodChanged(e: INJECT_DELIVERY_METHOD_LIST_ITEM) {
    this.selectedDelivertMethod = e
    console.log(this.selectedDelivertMethod);
    this.inject.deliveryMethod = e.itemEnum

  }
  get injectNoError():boolean {
    return this.inject.inject_no.length >= 3 ? false : true
  }
  get injectShortError():boolean {
    return this.inject.short_title.length > 3 ? false : true
  }
  get injectNoHasError():boolean {
    return true
  }

  learningObjectivesChanged(e: MatSelectChange) {
    console.log(e.value);
    const ids = _.map(e.value, 'id').toString()
    // console.log(ids);
    // console.log(e.value.length);
    this.inject.learning_objectives = ids; //e.value.toString();
    // console.log(this.selectedLearningObjectives);
  }

}

/*
template: `
  <!-- <div class="container-fluid p0" *ngIf='inject != null' style="height: 100%; overflow: hidden;"> -->
  <!-- <mat-toolbar class="overlay-toolbar">
    <span class="toolbar-spacer"></span>
    <mat-icon class="dxfx_icon_btn_medium ml5" (click)='close()'>close</mat-icon>
  </mat-toolbar> -->
  <!-- <div class="container-fluid" style=" height: calc(100% - 88px); overflow-y:scroll;"> -->
    <!-- <mat-card style="min-height: 200px;" class="pt0"> -->


      <!-- <div class="d-flex p0 ">
        <div class="p-2">
          <div class="formGroup textfield100">
            <mat-label class='mat-caption uc'>Inject No.*</mat-label>
            <input type="text"  [class.formControlError]='injectNoError' [class.formControl] = '!injectNoError'
            class=" textfield100 textFieldH40"  required [disabled]='isSaving' (keydown)='injectNoKeyDown($event)'
            placeholder="inject_no" style="text-transform: uppercase;"
            [(ngModel)]="inject.inject_no" >
          </div>
        </div>

        <div class="p-2 flex-fill" style="min-width: 250px;">
          <mat-label class='mat-caption uc'>Short title</mat-label>
          <input type="text" class="textfield100 textFieldH40" placeholder="Short title"
          [class.formControlError]='injectShortError' [class.formControl] = '!injectShortError'
                  [(ngModel)]="inject.short_title" >
        </div>

        <div class="p-2 w33p">
          <mat-label class='mat-caption'>De:</mat-label>
           <mat-form-field appearance="outline" class="textfield100 select-form-field pb0">

             <mat-select [(ngModel)]='selectedDelivertMethod' (ngModelChange)='onDeliMethodChanged($event)' >
               <mat-option *ngFor="let exer of deliveryMethods" [value]="exer">
                 {{exer.viewVal}}
               </mat-option>
             </mat-select>
           </mat-form-field>
         </div>
      </div>


        <div class="d-flex flex-wrap pt0">
          <div class="p-2 flex-fill" style="width: 50%;">
            <mat-label class='mat-caption uc'>Related documents</mat-label>
            <div class="formGroup textfield100">
              <textarea class="formControl" [(ngModel)]="inject.related_documents" rows="5" cols="4" [disabled]='isSaving'></textarea>
            </div>
          </div>
          <div class="p-2 flex-fill" style="width: 50%;">
            <mat-label class='mat-caption uc'>additional_guidance_caution_options</mat-label>
            <div class="formGroup textfield100">
              <textarea class="formControl" [(ngModel)]="inject.additional_guidance_caution_options" rows="5" cols="4" [disabled]='isSaving'></textarea>
            </div>
          </div>
        </div>

        <div class="d-flex flex-wrap pt0" >
          <div class="p-2 flex-fill" style="width: 50%;">
            <mat-label class='mat-caption uc'>expected_outcomes</mat-label>
            <div class="formGroup textfield100">
              <textarea class="formControl" [(ngModel)]="inject.expected_outcomes" rows="5" cols="4" [disabled]='isSaving'></textarea>
            </div>
          </div>
          <div class="p-2 flex-fill" style="width: 50%;">
            <mat-label class='mat-caption uc'>on_scene_preparation</mat-label>
            <div class="formGroup textfield100">
              <textarea class="formControl" [(ngModel)]="inject.on_scene_preparation" rows="5" cols="4" [disabled]='isSaving'></textarea>
            </div>
          </div>
        </div>

           <div class="d-flex flex-wrap pt0">
            <div class="p-2 flex-fill" style="width: 50%;">
              <mat-label class='mat-caption uc'>Description</mat-label>
              <div class="formGroup textfield100">
                <textarea class="formControl" [(ngModel)]="inject.description" rows="5" cols="4" [disabled]='isSaving'></textarea>
              </div>
            </div>
            <div class="p-2 flex-fill" style="width: 50%;">
              <mat-label class='mat-caption uc'>Related equipment:</mat-label>
                <div class="formGroup textfield100">
                <textarea class="formControl" [(ngModel)]="inject.related_equipment" rows="5" cols="4" [disabled]='isSaving'></textarea>
              </div>
            </div>
          </div>

          <div class="d-flex flex-wrap pt0" >
            <div class="p-2 flex-fill" style="width: 50%;">
              <mat-label class='mat-caption uc'>role_players_instruction</mat-label>
              <div class="formGroup textfield100">
                <textarea class="formControl" [(ngModel)]="inject.role_players_instruction" rows="5" cols="4" [disabled]='isSaving'></textarea>
              </div>
            </div>
            <div class="p-2 flex-fill" style="width: 50%;">
              <mat-label class='mat-caption uc'>Trainers action requirement:</mat-label>
                <div class="formGroup textfield100">
                <textarea class="formControl" [(ngModel)]="inject.trainers_action_requirement" rows="5" cols="4" [disabled]='isSaving'></textarea>
              </div>
            </div>
          </div> -->

    <!-- </mat-card>
  </div> -->
  <!-- <mat-toolbar class="overlay-toolbar">
    <span class="toolbar-spacer"></span>
    <ect-color-btn class="mr5" title="save" icon="save" color="primary" [disabled]="disabled" (clicked)="saveItem()" ></ect-color-btn>
  </mat-toolbar> -->
<!-- </div> -->
  `,

 */
