import { Component, inject, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { CompanyInject, iCompany, iCompanyInject } from 'src/app/model/company.model';
import { StorageService } from '../../services/storage.service';
import { ApiService } from 'src/app/services/api.service';
import { HTTP_METHOD } from 'src/app/model/enums';
import { INJECT_DELIVERY_METHOD } from 'src/app/model/exercise.model';
import * as _ from 'lodash'
import { iCompanyTrainingObjective } from 'src/app/model/trainingObj.model';
import { finalize, Subscription } from 'rxjs';
import { HttpClient, HttpEventType } from '@angular/common/http';
import { RenameInjectImportItemComponent } from './rename-inject-import-item/rename-inject-import-item.component';
import { UiService } from 'src/app/services/ui.service';
import { RenameOverlayData } from './renameOverlayData.model';
@Component({
  selector: 'app-inject-import-overlay',
  templateUrl: './inject-import-overlay.component.html',
  styleUrls: ['./inject-import-overlay.component.scss']
})
export class InjectImportOverlayComponent implements OnInit {
  @ViewChild('csvReader',{static: false}) csvReader: any;
  injectDisplayedColumns: string[] = ['inject_no', 'name', 'DELIVERY METHOD'];
  injectsDataSource:any;
  fileName = '';
  companyInjects:iCompanyInject[] = []
  injectImportList:iCompanyInject[] = []
  // includedCompanyInjects: iCompanyInject[] = []
  public records: any[] = [];
  isRecordComplete = false;
  companyDocId:string;
  company: iCompany
  private injectImportSet = ''
  private storage = inject(StorageService);
  private api = inject(ApiService);

  // uploadProgress:number | undefined;
  // uploadSub: Subscription | undefined;
  constructor(
    private ui: UiService,
    private dialog: MatDialog,
    public dialogRef: MatDialogRef<InjectImportOverlayComponent>
    ,@Inject(MAT_DIALOG_DATA) public data:iCompany) {
      this.company = data
      this.companyDocId = data.companyId

    }

  ngOnInit(): void {
    // this.includedCompanyInjects = this.storage.companyInjects
    this.injectImportSet = `Import-${new Date().getFullYear()}-${new Date().getMonth()}-${new Date().getDate()}-${new Date().getHours()}:${new Date().getMinutes()}`
  }
//   onFileSelected(event:any) {
//     const file:File = event.target.files[0];

//     if (file) {
//         this.fileName = file.name;
//         const formData = new FormData();
//         formData.append("thumbnail", file);

//         const upload$ = this.http.post("/api/thumbnail-upload", formData, {
//             reportProgress: true,
//             observe: 'events'
//         })
//         .pipe(
//             finalize(() => this.reset())
//         );

//         this.uploadSub = upload$.subscribe(event => {
//           if (event.type == HttpEventType.UploadProgress && event.total) {

//             this.uploadProgress = Math.round(100 * (event.loaded / event.total));
//           }
//         })
//     }
// }
// cancelUpload() {
//   this.uploadSub?.unsubscribe();
//   this.reset();
// }

// reset() {
//   this.uploadProgress = undefined;
//   this.uploadSub = undefined;
// }
  get includedCompanyInjects() : iCompanyInject[] {
    return this.storage.companyInjects
  }
  uploadListener(event: any): void {

    let text = [];
    // let files = $event.srcElement.files;
    const file:File = event.target.files[0];
    if (file) {
      this.fileName = file.name;
      if (this.isValidCSVFile(file)) {

        // let input = $event.target;
        let reader = new FileReader();
        reader.readAsText(file);

        reader.onload = () => {
          let csvData = reader.result;

          ///console.log(reader.result);

          /* let csvRecordsArray = (<string>csvData).split(/\r\n|\n/);

          let headersRow = this.getHeaderArray(csvRecordsArray);

          this.records = this.getDataRecordsArrayFromCSVFile(csvRecordsArray, headersRow.length);
          */
         this.records = this.getInjectListFromImport(JSON.parse(reader.result!.toString()));
         console.log(this.records);
        };
        reader.onloadend = () => {
          // this.records.shift()
          this.injectsDataSource = new MatTableDataSource<CompanyInject>(this.records);
          this.injectImportList = this.records
        }
        reader.onerror = function () {
          console.log('error is occured while reading file!');
        };

      } else {
        alert("Please import valid .csv file.");
        this.fileReset();
      }
    }

  }
  getInjectListFromImport(csvRecordsArray: any) {
    let csvArr:iCompanyInject[] = [];

    for (let i = 0; i < csvRecordsArray.length; i++) {
      let curruntRecord = csvRecordsArray[i]


        var injectRecord:iCompanyInject = {

          id: -1,
          companyId: this.companyDocId,
          inject_no: this.getInjectItem(curruntRecord, 'inject_no'),
          short_title: this.getInjectItem(curruntRecord, 'short_title'),

          deliveryMethod: this.getInjectItem(curruntRecord, 'delivery_method') == 'NA' ? 'OTHER' : this.getInjectItem(curruntRecord, 'delivery_method'),
          responsibility_for_delivery_by: '',

          description: this.getInjectItem(curruntRecord, 'description'),
          related_documents: this.getInjectItem(curruntRecord, 'related_documents'),
          related_equipment: this.getInjectItem(curruntRecord, 'related_equipment'),
          //related_site_location: this.getInjectItem(curruntRecord, 'related_site_location'),

          role_players_instruction: this.getInjectItem(curruntRecord, 'role_players_instruction'),
          on_scene_preparation: this.getInjectItem(curruntRecord, 'on_scene_preparation'),
          additional_guidance_caution_options: this.getInjectItem(curruntRecord, 'additional_guidance_caution_options'),
          expected_outcomes: this.getInjectItem(curruntRecord, 'expected_outcomes'),
          trainers_action_requirement: this.getInjectItem(curruntRecord, 'trainers_action_requirement'),
          inject_set: this.injectImportSet,
          learning_objectives: this.getInjectItem(curruntRecord, 'learning'),
          // learning_1: this.getInjectItem(curruntRecord, 'learning_1'),
          // learning_2: this.getInjectItem(curruntRecord, 'learning_2'),
          // learning_3: this.getInjectItem(curruntRecord, 'learning_3'),
        }

        csvArr.push(injectRecord);

    }

    this.isRecordComplete = true;
    return csvArr;
  }

  removeItemFromImport(value:iCompanyInject) {
    const index = this.injectImportList.findIndex(item => item == value)
    if (index != -1) {
      this.injectImportList.splice(index, 1);
    }
  }
  editItemFromImport(value:iCompanyInject) {
    const olData: RenameOverlayData = {
      injectItem: value,
      injectImportList: this.injectImportList,
      companyInjects: this.companyInjects,
    }
    this.dialog.open(RenameInjectImportItemComponent, {
      width: "460px",
      height: "320px",
      panelClass: this.ui.darkMode ? "fsPanelClass" : "fsPanelClassLight",
      backdropClass: "fs_backdrop",
      data: olData
    }).afterClosed().subscribe(newName => {
      if(newName) {
        value.inject_no = newName
      }
    })
  }

  private allDlMethods: INJECT_DELIVERY_METHOD[] = [
    INJECT_DELIVERY_METHOD.EVENT,
    INJECT_DELIVERY_METHOD.MAIL,
    INJECT_DELIVERY_METHOD.OTHER,
    INJECT_DELIVERY_METHOD.PHONECALL,
    INJECT_DELIVERY_METHOD.PHYSICALMEETING,
    INJECT_DELIVERY_METHOD.RADIOCALL,
    INJECT_DELIVERY_METHOD.ROLEPLAY,
    INJECT_DELIVERY_METHOD.SOCIALMEDIA,
    INJECT_DELIVERY_METHOD.VIRTUALMEETING,
    INJECT_DELIVERY_METHOD.VIRTUAL_OSOCC,
  ]
  getInjectItem(item: any, key: string): any {
    if (key == 'delivery_method') {
      return this.allDlMethods.includes(item[key]) ? item[key] : "OTHER"
    } else if (key == 'learning_1' || key == 'learning_2' || key == 'learning_3')  {
      return item[key] != null ? item[key] : '';
    } else if (key == 'learning')  {
      var out = ''
      if (item['learning_1'] != null) {
        out += `${item['learning_1']},`
      }
      if (item['learning_2'] != null) {
        out += `${item['learning_2']},`
      }
      if (item['learning_3'] != null) {
        out += item['learning_3']
      }
      return out;
    } else {
      return item[key] != null ? item[key] : 'NA';
    }

  }
  isValidCSVFile(file: any) {
    return file.name.endsWith(".json");
  }
  isImporting = false
  async importIntoDataBase(){

    this.isImporting = true
    // return
    var learning_objectives: string[] = []
    var learning_objectives_OE: string[] = []
    if (this.company.trainingObjectives) {
      learning_objectives = _.map(this.company.trainingObjectives, 'title')
      learning_objectives_OE = _.map(this.company.trainingObjectives, 'title')
    }
    console.log(this.company.trainingObjectives)
    console.log(learning_objectives)


    for (const __inject of this.records) {
      // console.log(__inject);
      if (__inject.learning_objectives) {
        var injLearningObjs:string[] = []
        injLearningObjs = __inject.learning_objectives.split(',')
        if (injLearningObjs.length > 0) {
          injLearningObjs.forEach(element => {
            if (!learning_objectives.includes(element)) {
              learning_objectives.push(element)
            }
          })
        }
      }
      const dto = {
        ...__inject,
        id: undefined,
        companyId: this.companyDocId,
      }
      delete dto.id;
      // const r = await this.api.apiReq(HTTP_METHOD.POST, `inject/company-inject`,dto )
      // console.log(r);
      // this.storage.addCompanyInject(r as iCompanyInject)
    }
    //console.log(learning_objectives);



    learning_objectives_OE.forEach(lobjOE => {
      const index = learning_objectives.findIndex(item => item == lobjOE)
      if (index != -1) {
        learning_objectives.splice(index, 1)
      }
    })

    for (var i = 0; i < learning_objectives.length; i ++) {
      const lobj = learning_objectives[i]
      const r = await this.api.apiReq(HTTP_METHOD.POST, `trainingobjective/company`, {
        companyId: this.company.companyId,
        title: lobj,
      })
      this.storage.companyObjectives = r as iCompanyTrainingObjective[]
      console.log(this.storage.companyObjectives);
    }
    // learning_objectives.forEach(lobj => {
    //   const r = await this.api.apiReq(HTTP_METHOD.POST, `trainingobjective/company`, {
    //     companyId: this.company.companyId,
    //     title: lobj,
    //   })
    // })

    //  console.log(learning_objectives);
    // const r = await this.api.apiReq(HTTP_METHOD.POST, `trainingobjective/company`, {
    //   companyId: this.olData.companyId!,
    //   title: this.selectedTrainingObj.title,
    // })
    // this.storage.companyObjectives = r as iCompanyTrainingObjective[]

    for (var j = 0; j < this.records.length; j ++) {
      const __inject = this.records[j];

      if (__inject.learning_objectives) {

        var injLearningObjs:string[] = []
        var injLearningObjIds:string[] = []
        injLearningObjs = __inject.learning_objectives.split(',');

        if (injLearningObjs.length > 0) {
          injLearningObjs.forEach(element => {
            const foundIndex = this.storage.companyObjectives.findIndex(item => item.title == element)
            // console.log(`foundIndex: ${foundIndex}`);
            if (foundIndex != -1) {
              // console.log(this.storage.companyObjectives[foundIndex].id);
              injLearningObjIds.push(this.storage.companyObjectives[foundIndex].id.toString())
            } else {
              // console.log(`NOT FOUND`);
            }
          })
        }

        this.records[j].learning_objectives = injLearningObjIds.toString()
      }

      const dto = {
        ...this.records[j],
        id: undefined,
        companyId: this.companyDocId,
      }
      delete dto.id;
      // console.log(dto);
      const r = await this.api.apiReq(HTTP_METHOD.POST, `inject/company-inject`,dto )
      console.log(r);
      this.storage.addCompanyInject(r as iCompanyInject)
    }


    this.isImporting = false
    this.dialogRef.close()
    // for (const __inject of this.records) {
    //   // console.log(__inject);
    //   if (__inject.learning_objectives) {
    //     var injLearningObjs:string[] = []
    //     injLearningObjs = __inject.learning_objectives.split(',')
    //     if (injLearningObjs.length > 0) {
    //       injLearningObjs.forEach(element => {
    //         const foundIndex = this.storage.companyObjectives.findIndex(item => item.title == element)
    //         // console.log(`foundIndex: ${foundIndex}`);
    //         if (foundIndex != -1) {
    //           console.log(this.storage.companyObjectives[foundIndex].id);
    //         } else {
    //           console.log(`NOT FOUND`);
    //         }

    //       })
    //     }
    //   }
    //   const dto = {
    //     ...__inject,
    //     id: undefined,
    //     companyId: this.companyDocId,
    //   }
    //   delete dto.id;
    //   // const r = await this.api.apiReq(HTTP_METHOD.POST, `inject/company-inject`,dto )
    //   // console.log(r);
    //   // this.storage.addCompanyInject(r as iCompanyInject)
    // }
  }

  isIncluded(inj: iCompanyInject) : boolean {
    const index = this.includedCompanyInjects.findIndex(item => item.inject_no == inj.inject_no)
    return index != -1
  }

  fileReset() {
    this.csvReader.nativeElement.value = "";
    this.records = [];
  }
  close() {
    this.dialogRef.close()
  }

  get canImport(): boolean {
    let out = true
    const inject_nos = _.map(this.injectImportList, 'inject_no')

    this.storage.companyInjects.forEach(ele => {
      if(inject_nos.includes(ele.inject_no)) {
        out = false
      }
    })
    return out;
  }
}
