import { NgModule } from '@angular/core';

import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MaterialModule } from './material.module';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LoginComponent } from './pages/auth/login/login.component';
import { authInterceptorProviders } from './services/auth.interceptor';
import { ApiService } from './services/api.service';
import { ExerciseOverviewComponent } from './pages/exercise-overview/exercise-overview.component';
import { ExerciseDashboardComponent } from './pages/exercise-dashboard/exercise-dashboard.component';
import { AddComponentDirective } from './directives/add-component.directive';
import { AccessDirective } from './directives/access.directive';
import { TimelineComponent } from './components/timeline/timeline.component';
import { InjectSelectOverlayComponent } from './overlays/inject-select-overlay/inject-select-overlay.component';
import { InjectDetailOverlayComponent } from './overlays/inject-detail-overlay/inject-detail-overlay.component';
import { InjectsToolbarComponent } from './components/injects-toolbar/injects-toolbar.component';
import { InjectsToolbarActionItemComponent } from './components/injects-toolbar-action-item/injects-toolbar-action-item.component';
import { TextEditOverlayComponent } from './overlays/text-edit-overlay/text-edit-overlay.component';

import { TimlineTimeChangeOverlayComponent } from './overlays/timline-time-change-overlay/timline-time-change-overlay.component';
import { FullscreenOverlayComponent } from './overlays/fullscreen-overlay/fullscreen-overlay.component';
import { ExerciseUsersViewComponent } from './components/exercise-users-view/exercise-users-view.component';
import { ExerciseTimelinesModifyViewComponent } from './components/exercise-timelines-modify-view/exercise-timelines-modify-view.component';
import { ColorBtnComponent } from './components/color-btn/color-btn.component';
import { DurationPipe } from './services/duration.pipe';
import { ExerciseDurationOverlayComponent } from './overlays/exercise-duration-overlay/exercise-duration-overlay.component';
import { MinimapItemComponent } from './components/minimap-item/minimap-item.component';
import { AdminComponent } from './pages/admin/admin.component';
import { AdminInjectDetailComponent } from './components/admin-inject-detail/admin-inject-detail.component';
import { TimelinetypeModifyComponent } from './components/exercise-timelines-modify-view/timelinetype-modify/timelinetype-modify.component';
import { TimelineInjectItemComponent } from './components/timeline-inject-item/timeline-inject-item.component';

import { AlertOverlayComponent } from './overlays/alert-overlay/alert-overlay.component';
import { ModifyOverlayComponent } from './overlays/modify-overlay/modify-overlay.component';

import { ModifyCompanyInjectComponent } from './overlays/modify-overlay/modify-company-inject/modify-company-inject.component';
import { InjectsTableViewComponent } from './overlays/inject-select-overlay/injects-table-view.component';
import { ExerciseDashboardNavBar } from './pages/exercise-dashboard/exercose-dashboard-nav-bar.component';
import { ExerxiseAccessDirective } from './directives/exercise-nac-access.directive';
import { InjectTemplateDetailComponent } from './overlays/inject-select-overlay/inject-template-detail.component';
import { InjectDetailOL_DeliveryMethodComponent } from './overlays/inject-detail-overlay/inject-detail-ol-delivery-method.component';
import { InjectDetailOL_StateComponent } from './overlays/inject-detail-overlay/inject-detail-ol-status.component';
import { InjectDetailOL_DeliveryDateTimeComponent } from './overlays/inject-detail-overlay/inject-detail-ol-delivery-date-time.component';
import { GoogleMapsModule } from '@angular/google-maps';
import { LocationDetailOverlayComponent } from './overlays/location-detail-overlay/location-detail-overlay.component';
import { TimelineMiniGoogleMapComponent } from './components/timeline/timeline-mini-gmap.component';

import { InjectDetailOL_DescriptionComponent } from './overlays/inject-detail-overlay/components/inject-detail-ol-description.component';
import { RichTextEditorModule } from '@syncfusion/ej2-angular-richtexteditor';
import { InjectDetailOL_TextEditorComponent } from './overlays/inject-detail-overlay/components/inject-detail-ol-texteditor.component';
import { NavigationListItemComponent } from './components/navigation-list-item/navigation-list-item.component';
import { ToggleButtonComponent } from './components/toggle-button/toggle-button.component';
import { InjectDetailOL_LocationsComponent } from './overlays/inject-detail-overlay/components/inject-detail-ol-locations.component';
import { InjectDetailOL_TrainingObjectivesComponent } from './overlays/inject-detail-overlay/components/inject-detail-ol-trainingObjective.component';
import { InjectDetailOL_TrainerFeedbackComponent } from './overlays/inject-detail-overlay/components/inject-detail-ol-trainerFeedback.component';

import { DashboardNavbarIconButonComponent } from './components/dashboard-navbar-icon-buton/dashboard-navbar-icon-buton.component';
import { ExerciseMapViewComponent } from './components/exercise-map-view/exercise-map-view.component';
import { CreateExerciseOverlayComponent } from './overlays/create-exercise-overlay/create-exercise-overlay.component';
import { AssignedInjectsTableViewComponent } from './overlays/inject-select-overlay/assigned-injects-table-view.component';
import { ExerciseLogbookOverlayComponent } from './overlays/exercise-logbook-overlay/exercise-logbook-overlay.component';
import { ExercisePerformanceOverlayComponent } from './overlays/exercise-performance-overlay/exercise-performance-overlay.component';
import { PerformanceProgressInjectComponent } from './components/performance-progress-inject/performance-progress-inject.component';
import { DividerComponent } from './components/divider/divider.component';
import { InjectDetailComponent } from './components/inject-detail/inject-detail.component';
import { NavigationToggleButtonComponent } from './components/navigation-toggle-button/navigation-toggle-button.component';
import { ExerciseTeamPerformanceOverlayComponent } from './overlays/exercise-team-performance-overlay/exercise-team-performance-overlay.component';
import { TeamperformanceProgressBarComponent } from './components/teamperformance-progress-bar/teamperformance-progress-bar.component';
import { DatePipe } from '@angular/common';
import { ResizableTextAreaDirective } from './directives/resizable.textarea.directive';
import { ToolbarChatItemComponent } from './components/injects-toolbar/toolbarChatItem.component';
import { FileSaverModule } from 'ngx-filesaver';
import { ExcelExampleComponent } from './excel-example/excel-example.component';
import { LocaleService } from './services/locale.service';
import { InjectImportOverlayComponent } from './overlays/inject-import-overlay/inject-import-overlay.component';
import { CommentsOverviewOverlayComponent } from './overlays/comments-overview-overlay/comments-overview-overlay.component';
import { DashboardRoleComponent } from './pages/exercise-dashboard/components/dashboard-role/dashboard-role.component';
import { DashboardTimeComponent } from './pages/exercise-dashboard/components/dashboard-time/dashboard-time.component';
import { DashboardUserComponent } from './pages/exercise-dashboard/components/dashboard-user/dashboard-user.component';
import { UserTableRowComponent } from './components/exercise-users-view/userTableRow.component';
import { ExerciseUserOverlayComponent } from './overlays/exercise-user-overlay/exercise-user-overlay.component';
import { CompanyUserTableRowComponent } from './components/exercise-users-view/companyUserTableRow.component';
import { ExcelExportComponent } from './overlays/excel-export/excel-export.component';
import { ExportAsModule } from 'ngx-export-as';
import { PasswortRestoreComponent } from './pages/auth/passwort-restore/passwort-restore.component';
import { ModifyTrainingObjectiveComponent } from './overlays/modify-overlay/modify-training-objective/modify-training-objective.component';
import { RenameInjectImportItemComponent } from './overlays/inject-import-overlay/rename-inject-import-item/rename-inject-import-item.component';
import { SideNavRoleItemComponent } from './components/timeline/side-nav-role-item.component';
import { ExerciseDurationOverlayTimeLineItem } from './overlays/exercise-duration-overlay/exercise-duration-overlay-timeline-item.component';
import { InjectDetailSidenavComponent } from './components/inject-detail-sidenav/inject-detail-sidenav.component';
import { ExerciseDurationOverlayNewComponent } from './overlays/exercise-duration-overlay-new/exercise-duration-overlay-new.component';
// import { NgxEditorModule } from 'ngx-editor';
// import { schema } from 'ngx-editor/schema';

// import { EditorModule } from 'primeng/editor';




@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    ExerciseOverviewComponent,
    ExerciseDashboardComponent,
    AccessDirective,
    ExerxiseAccessDirective,
    AddComponentDirective,
    TimelineComponent,
    InjectSelectOverlayComponent,
    InjectDetailOverlayComponent,
    InjectsToolbarComponent,
    InjectsToolbarActionItemComponent,
    TextEditOverlayComponent,

    TimlineTimeChangeOverlayComponent,
    FullscreenOverlayComponent,
    ExerciseUsersViewComponent,
    ExerciseTimelinesModifyViewComponent,
    ColorBtnComponent,
    DurationPipe,
    ExerciseDurationOverlayComponent,
    MinimapItemComponent,
    AdminComponent,
    AdminInjectDetailComponent,
    TimelinetypeModifyComponent,
    TimelineInjectItemComponent,
    // AdminInjectDetailOverlayComponent,
    AlertOverlayComponent,
    ModifyOverlayComponent,

    ModifyCompanyInjectComponent,
    InjectsTableViewComponent,
    ExerciseDashboardNavBar,
    InjectTemplateDetailComponent,
    InjectDetailOL_DeliveryMethodComponent,
    InjectDetailOL_StateComponent,
    InjectDetailOL_DeliveryDateTimeComponent,
    LocationDetailOverlayComponent,
    TimelineMiniGoogleMapComponent,
    InjectDetailOL_DescriptionComponent,
    InjectDetailOL_TextEditorComponent,
    NavigationListItemComponent,
    ToggleButtonComponent,
    InjectDetailOL_LocationsComponent,
    InjectDetailOL_TrainingObjectivesComponent,
    InjectDetailOL_TrainerFeedbackComponent,
    DashboardNavbarIconButonComponent,
    ExerciseMapViewComponent,
    CreateExerciseOverlayComponent,
    AssignedInjectsTableViewComponent,
    ExerciseLogbookOverlayComponent,
    ExercisePerformanceOverlayComponent,
    PerformanceProgressInjectComponent,
    DividerComponent,
    InjectDetailComponent,
    NavigationToggleButtonComponent,
    ExerciseTeamPerformanceOverlayComponent,
    TeamperformanceProgressBarComponent,
    ResizableTextAreaDirective,
    ToolbarChatItemComponent,
    ExcelExampleComponent,
    InjectImportOverlayComponent,
    CommentsOverviewOverlayComponent,
    DashboardRoleComponent,
    DashboardTimeComponent,
    DashboardUserComponent,
    UserTableRowComponent,
    ExerciseUserOverlayComponent,
    CompanyUserTableRowComponent,
    ExcelExportComponent,
    PasswortRestoreComponent,
    ModifyTrainingObjectiveComponent,
    RenameInjectImportItemComponent,
    SideNavRoleItemComponent,
    ExerciseDurationOverlayTimeLineItem,
    InjectDetailSidenavComponent,
    ExerciseDurationOverlayNewComponent

  ],
  imports: [
    GoogleMapsModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    FormsModule,
    MaterialModule,
    RichTextEditorModule,
    FileSaverModule,
    ExportAsModule

    // NgxEditorModule,
    // NgxEditorModule.forRoot({
    //   locals: {
    //     // menu
    //     bold: 'Bold',
    //     italic: 'Italic',
    //     code: 'Code',
    //     underline: 'Underline',
    //     strike: 'Strike',
    //     blockquote: 'Blockquote',
    //     bullet_list: 'Bullet List',
    //     ordered_list: 'Ordered List',
    //     heading: 'Heading',
    //     h1: 'Header 1',
    //     h2: 'Header 2',
    //     h3: 'Header 3',
    //     h4: 'Header 4',
    //     h5: 'Header 5',
    //     h6: 'Header 6',
    //     align_left: 'Left Align',
    //     align_center: 'Center Align',
    //     align_right: 'Right Align',
    //     align_justify: 'Justify',
    //     text_color: 'Text Color',
    //     background_color: 'Background Color',
    //     horizontal_rule: 'Horizontal rule',
    //     format_clear: 'Clear Formatting',
    //     insertLink: 'Insert Link',
    //     removeLink: 'Remove Link',
    //     insertImage: 'Insert Image',
    //     indent: 'Increase Indent',
    //     outdent: 'Decrease Indent',
    //     superscript: 'Superscript',
    //     subscript: 'Subscript',
    //     undo: 'Undo',
    //     redo: 'Redo',

    //     // pupups, forms, others...
    //     url: 'URL',
    //     text: 'Text',
    //     openInNewTab: 'Open in new tab',
    //     insert: 'Insert',
    //     altText: 'Alt Text',
    //     title: 'Title',
    //     remove: 'Remove',
    //     enterValidUrl: 'Please enter a valid URL',
    //   },
    // })
    // EditorModule

    // InjectDetailOverlayCompModule
  ],

  exports:[AccessDirective, ExerxiseAccessDirective],
  providers: [ApiService, authInterceptorProviders, DatePipe, LocaleService, ExerciseDurationOverlayComponent],
  bootstrap: [AppComponent],

})
export class AppModule { }



