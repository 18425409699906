// import { TacticalMonth, TacticalTime } from '../core/models/timeModels';
import * as _moment from 'moment';
import { addDays, addHours, addMinutes, endOfDay, startOfDay, subMinutes } from 'date-fns';
import { TacticalMonth, TacticalTime } from '../model/exercise.model';
import { UTCDate } from '@date-fns/utc';
// const moment = _moment;
export class Helper{

  static tacticaltime() :string {
    return ''
  }

}

export class TIME {

  static tt_currentUTC(add_h:number = 0):TacticalTime {
    let current:TacticalTime = TIME.tt_clean();
    let currentUTC:Date = TIME.currentUTCDate();
    if(add_h > 0){
      currentUTC = addHours(currentUTC, add_h);
    }

    const _day = currentUTC.getUTCDate()
    const _hours = currentUTC.getUTCHours()
    const _min = currentUTC.getMinutes()
    const _sec = currentUTC.getSeconds()

    current.day =  _day < 10 ? `0${_day}` : `${_day}`;

    current.hours = _hours < 10 ? `0${_hours}` : `${_hours}`;

    current.minutes = _min < 10 ? `0${_min}` : `${_min}`;

    current.seconds = _sec < 10 ? `0${_sec}` : `${_sec}`;

    /* current.day = currentUTC.getUTCDate() < 10 ?
     "0" + currentUTC.getUTCDate().toString() : currentUTC.getUTCDate().toString();

    current.hours = currentUTC.getUTCHours() < 10 ?
     "0" + currentUTC.getUTCHours().toString() : currentUTC.getUTCHours().toString();

    current.minutes = currentUTC.getMinutes() < 10 ?
    "0" + currentUTC.getMinutes().toString() : currentUTC.getMinutes().toString();

    current.seconds = currentUTC.getSeconds() < 10 ?
    "0" + currentUTC.getSeconds().toString() : currentUTC.getSeconds().toString(); */

    current.month = TIME.tacticalMonthFromIndex(currentUTC.getMonth());

    current.year = currentUTC.getFullYear().toString().substr(2)

    return current;
  }

  static tt_clean():TacticalTime {
    let t:TacticalTime = {
      day:"",
      hours:"",
      minutes:"",
      seconds:'',
      month:{viewVal:'jan',index:0},
      year:"",
    }
    return t;
  }

  static tt_toDate(tt:TacticalTime):Date {
    let _y:number = 2000;
    _y += parseFloat(tt.year);
    let _month:number = tt.month.index;
    let _d:number = parseFloat(tt.day);
    let _h:number = parseFloat(tt.hours);
    let _min:number = parseFloat(tt.minutes);
    let _sec:number = parseFloat(tt.seconds);

    // let d:Date = new Date(Date.UTC(_y,_month, _d,_h, _min, _sec,0));
    let d:Date = new UTCDate(_y,_month, _d,_h, _min, _sec,0);
    // let d:Date = new Date(_y,_month, _d,_h, _min, _sec);

    //
    // d.setUTCFullYear(_y);
    // d.setUTCMonth(_month)
    // d.setUTCDate(_d)
    // d.setUTCHours(_h)
    // d.setUTCMinutes(_min)
    // d.setUTCSeconds(0,0)

    return d;
  }

  static date_to_tt(dateInput:Date, zeroHour = false):TacticalTime {

    let t:TacticalTime = {
      /* day: dateInput.getUTCDate() < 10 ? "0" + dateInput.getUTCDate().toString() :dateInput.getUTCDate().toString(),
      hours:dateInput.getUTCHours() < 10 ? "0" + dateInput.getUTCHours().toString() :dateInput.getUTCHours().toString(),
      minutes:dateInput.getMinutes() < 10 ? "0" + dateInput.getMinutes().toString() :dateInput.getMinutes().toString(),
      seconds:dateInput.getSeconds() < 10 ? "0" + dateInput.getSeconds().toString() :dateInput.getSeconds().toString(), */

      // day: dateInput.getUTCDate() < 10 ? `0${dateInput.getUTCDate()}` : `${dateInput.getUTCDate()}`,
      // hours: zeroHour ? "00" : dateInput.getUTCHours() < 10 ? `0${dateInput.getUTCHours()}` : `${dateInput.getUTCHours()}`,
      // minutes: dateInput.getMinutes() < 10 ? `0${dateInput.getMinutes()}` : `${dateInput.getMinutes()}`,
      // seconds: dateInput.getSeconds().toString(),

      day: dateInput.getUTCDate() < 10 ? `0${dateInput.getUTCDate()}` : `${dateInput.getUTCDate()}`,
      hours: zeroHour ? "00" : dateInput.getUTCHours() < 10 ? `0${dateInput.getUTCHours()}` : `${dateInput.getUTCHours()}`,
      minutes: dateInput.getMinutes() < 10 ? `0${dateInput.getMinutes()}` : `${dateInput.getMinutes()}`,
      seconds: dateInput.getSeconds().toString(),

      month:TIME.tacticalMonthFromIndex(dateInput.getMonth()),
      year:dateInput.getFullYear().toString().substr(2)
    }
    return t;
  }

  static date_lcoal_to_tt(dateInput:Date):TacticalTime {
    let t:TacticalTime = {
      day: dateInput.getDate() < 10 ? '0'+ dateInput.getDate().toString() : dateInput.getDate().toString(),
      hours: dateInput.getHours() < 10 ? `0${dateInput.getHours().toString()}` : dateInput.getHours().toString(),
      minutes: dateInput.getMinutes() < 10 ? '0'+ dateInput.getMinutes().toString() : dateInput.getMinutes().toString(),
      seconds: dateInput.getSeconds().toString(),
      month:TIME.tacticalMonthFromIndex(dateInput.getMonth()),
      year:dateInput.getFullYear().toString().substr(2)
    }
    return t;
  }




  static endOf(d: Date): Date {
    // return new UTCDate(endOfDay(d))
    return new UTCDate(subMinutes(endOfDay(d), endOfDay(d).getTimezoneOffset()) )
    // return new UTCDate(addMinutes(endOfDay(d),d.getTimezoneOffset() ))
    // return new UTCDate(subMinutes(addMinutes(endOfDay(d),d.getTimezoneOffset() ), 1))

    //return new UTCDate(subMinutes(addDays(d, 1).setHours(23, 59, 59), 1))
    // return new UTCDate(d.setHours(23, 59, 59))
  }
  static startOf(d: Date): Date {
    // return new UTCDate(endOfDay(d))
    return new UTCDate(subMinutes(startOfDay(d), startOfDay(d).getTimezoneOffset()) )
    // return new UTCDate(addMinutes(endOfDay(d),d.getTimezoneOffset() ))
    // return new UTCDate(subMinutes(addMinutes(endOfDay(d),d.getTimezoneOffset() ), 1))

    //return new UTCDate(subMinutes(addDays(d, 1).setHours(23, 59, 59), 1))
    // return new UTCDate(d.setHours(23, 59, 59))
  }
  static currentUTCDate():Date {
    let d:Date = new Date();
    const _d = _moment(d).utc(false).toString();

    return new Date(d);
    //return new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate(), d.getHours(), d.getMinutes(), d.getSeconds(), d.getMilliseconds()));
   //return utc;

  }


  static getTacticalMonths(): TacticalMonth[] {
   let  months:TacticalMonth[] = [
      {viewVal:"jan", index:0},
      {viewVal:"feb", index:1},
      {viewVal:"mar", index:2},
      {viewVal:"apr", index:3},
      {viewVal:"may", index:4},
      {viewVal:"jun", index:5},
      {viewVal:"jul", index:6},
      {viewVal:"aug", index:7},
      {viewVal:"sep", index:8},
      {viewVal:"oct", index:9},
      {viewVal:"nov", index:10},
      {viewVal:"dec", index:11},
    ]

    return months;
  }

  static tacticalMonthFromIndex(index:number):TacticalMonth {
    return TIME.getTacticalMonths().find((item)=>item.index == index)!;
  }

  static formatedDateString(date:Date):string {
    let _y = date.getFullYear()
    let _m = date.getMonth()
    let _d = date.getDate()


    return _y + '-' + ((_m + 1 ).toString().length == 1 ? "0"+(_m +1).toString() : (_m +1).toString())
    + '-' + (_d.toString().length == 1 ? "0"+_d.toString() : _d.toString())
  }
  static formatedDateStringWithoutYear(date:Date, separator: string = '.'):string {

    let _m = date.getMonth()
    let _d = date.getUTCDate()


    return ((_m + 1 ).toString().length == 1 ? "0"+(_m +1).toString() : (_m +1).toString())
    + separator + (_d.toString().length == 1 ? "0"+_d.toString() : _d.toString())
  }

  static formatedTimeString(date:Date):string {
    // let _y = date.getFullYear()
    // let _m = date.getMonth()
    // let _d = date.getDate()
    let _h = date.getUTCHours()
    let _min = date.getUTCMinutes()


    return (_h.toString().length == 1 ? "0"+_h.toString() : _h.toString()) + ":" + (_min.toString().length == 1 ? "0"+_min.toString() : _min.toString())
  }

  static formatedCleanDate(date:Date):UTCDate {
    let _y:number = date.getFullYear();

    let _month:number = date.getUTCMonth();
    let _d:number = date.getUTCDate();
    let _h:number = date.getUTCHours()


    // let d:Date = new Date(Date.UTC(_y,_month, _d,_h, _min, _sec,0));
    let d:UTCDate = new UTCDate(_y,_month, _d,_h, 0, 0,0);
    // let d:Date = new Date(_y,_month, _d,_h, _min, _sec);

    //
    // d.setUTCFullYear(_y);
    // d.setUTCMonth(_month)
    // d.setUTCDate(_d)
    // d.setUTCHours(_h)
    // d.setUTCMinutes(_min)
    // d.setUTCSeconds(0,0)

    return d;
  }
}
