import { EventEmitter, Injectable, signal } from '@angular/core';
import { iCompanyInject, iExerciseInject } from "../model/company.model";
import {
  iCompanyTrainingObjective,
  iExerciseTrainingObjective,
} from "../model/trainingObj.model";
import { ApiService } from "./api.service";
import {
  ExerciseClass,
  ExerciseI,
  ExerciseLog,
  ExerciseUserRole,
  IExerciseInjectState,
  IExerciseTimelineInjectRef,
  IExerciseTimelineItem,
} from "../model/exercise.model";
import { Socket, io } from "socket.io-client";
import { environment } from "src/environments/environment";
import { IAuthToken } from "../model/auth.token";
import { iUser } from "../model/iuser.model";
import { InjectItemUpdateDto } from "../model/dtos";
import * as _ from "lodash";
import { Location } from "../model/location.model";
import { TokenstorageService } from "./tokenstorage.service";
import { ChatMessage, ChatMessageLocal } from "../model/chatMessage.model";
import { DatePipe } from "@angular/common";
import { addMinutes, isSameDay } from "date-fns";
import { ExcelService, InjectExportAssignedInject, RatingExportItem } from "./excel.service";
import { ActivatedRoute, Router } from "@angular/router";
import { convertInject, wait } from "./utils/functions";
import { tlSchema } from "./utils/schema/timeLineExport.schema";

@Injectable({
  providedIn: "root",
})
export class StorageService {

  initialUrl: string | undefined
  private _user: iUser | undefined;
  private _isLoggedIn = false;
  // private _currentUser: iUser | undefined;
  private _companyInjects: iCompanyInject[] = [];
  private _companyObjectives: iCompanyTrainingObjective[] = [];
  private _exerciseInjectsAdmin: iExerciseInject[] = [];
  private _exerciseTrainingObjectsAdmin: iExerciseTrainingObjective[] = [];
  private _chatMessages: ChatMessage[] = [];
  private _chatMessagesLocal: ChatMessageLocal[] = [];
  private _exerciseInjects: iExerciseInject[] = [];
  private _assigendExerciseInjects: IExerciseTimelineInjectRef[] = [];
  private socket: Socket | undefined;
  private socket_endpoint: string = environment.endpoints.SOCKET_ENDPOINT;

  injectsLocationChangedEmitter: EventEmitter<void> = new EventEmitter<void>();
  incomingMessageEmitter: EventEmitter<ChatMessageLocal> = new EventEmitter<ChatMessageLocal>();

  testLocal = signal<ChatMessageLocal[]>([]);
  // quantity = signal<ConnectedUser[]>([]);
  injectSets: string[] = []// ['base', 'import']

  constructor(
    private api: ApiService,
    private ts: TokenstorageService,
    private df: DatePipe,
    private excelService: ExcelService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    // console.log(router);
    /* wait(10, () => {
      // route.url.subscribe(d => {
      //   console.log(d);
      // })
      // console.log(this.route);
      console.log(router.routerState.snapshot.url);
    }) */
    this.exercises = []
    // console.log(this.router.url)
    // console.log(`%c construct > StorageService < `,'color:#32A555');
    this.loadUiData();
    const token = this.getToken();
    // console.log(`%c StorageService > ${JSON.stringify(token)}`,'color:#32A555');
    if (token) {
      this.loadCurrentUser(token.token);
      // this._isLoggedIn = true;
      // this.initSocket(token.token);
    }

  }

  private _currentExercise: ExerciseClass | undefined;

  // _injects: IExerciseTimelineInjectRef[] = []
  initExerciseClass(e: ExerciseClass) {
    this._currentExercise = e;
  }

  get currentExer(): ExerciseClass | undefined {
    return this._currentExercise;
  }
  get iscurrentExerAdmin(): boolean {
    if (!this._currentExercise) {
      return false;
    }
    return this._currentExercise.isAdmin;
  }
  // setCurrentExerciseTimelineInhects(e: IExerciseTimelineInjectRef[]) {
  //   console.log(e);
  //   this._injects = e

  // }
  // get injects(): IExerciseTimelineInjectRef[] {
  //   return this._injects
  // }

  // ------------------------------------------------
  get companyInjects(): iCompanyInject[] {
    return _.sortBy(this._companyInjects, ['inject_no'])
  }
  // ------------------------------------------------
  set companyInjects(val: iCompanyInject[]) {
    this._companyInjects = val;
  }
  // ------------------------------------------------
  removeCompanyInject(val: iCompanyInject) {
    const index = this._companyInjects.findIndex(item => item.id == val.id)
    if (index != -1) {
      this._companyInjects.splice(index, 1)
    }
  }
  // get chatMessages():ChatMessage[] {
  //   return this._chatMessages.sort((a,b) => b.id - a.id)
  // }
  // ------------------------------------------------
  get chatMessages(): ChatMessageLocal[] {
    return this._chatMessagesLocal;
    // return this.fixMessages(this.storage.chatMessages)
  }
  // ------------------------------------------------
  addCompanyInject(val: iCompanyInject) {
    console.log("addCompanyInject");
    console.log(val);

    const itemIndex = this._companyInjects.findIndex(
      (item) => item.id == val.id
    );
    if (itemIndex != -1) {
      this._companyInjects.splice(itemIndex, 1);
    }
    this._companyInjects.push(val);
  }
  // ------------------------------------------------
  get companyObjectives(): iCompanyTrainingObjective[] {
    return this._companyObjectives;
  }
  // ------------------------------------------------
  set companyObjectives(val: iCompanyTrainingObjective[]) {
    this._companyObjectives = val;
  }

  // ------------------------------------------------
  get exerciseInjectsAdmin(): iExerciseInject[] {
    return this._exerciseInjectsAdmin;
  }
  // ------------------------------------------------
  set exerciseInjectsAdmin(val: iExerciseInject[]) {
    this._exerciseInjectsAdmin = val;
  }
  // ------------------------------------------------
  get exerciseTrainingObjectsAdmin(): iExerciseTrainingObjective[] {
    return this._exerciseTrainingObjectsAdmin;
  }
  // ------------------------------------------------
  set exerciseTrainingObjectsAdmin(val: iExerciseTrainingObjective[]) {
    this._exerciseTrainingObjectsAdmin = val;
  }

  // async exerciseInjects(id: string) :Promise<IExerciseTimelineInjectRef[]> {
  //   const items = await this.api.apiGetP('inject/by-exercise/'+id)
  //   return items as IExerciseTimelineInjectRef[]
  // }

  get exerciseInjects(): iExerciseInject[] {
    return this._exerciseInjects;
  }
  // set exerciseInjects(val: IExerciseTimelineInjectRef[]) {
  //   this._exerciseInjects = val;
  // }
  get assigendExerciseInjects(): IExerciseTimelineInjectRef[] {
    return this._assigendExerciseInjects;
  }
  set assigendExerciseInjects(val: IExerciseTimelineInjectRef[]) {
    this._assigendExerciseInjects = val;
  }



  private async loadUiData() {
    const _states = await this.api.apiGetP("inject/states");
    this.injectStates = _states as IExerciseInjectState[];
    // console.log(this.injectStates);
  }

  filteredInjectStates: string[] = [
    "REQUESTED",
    "DRAFT",
    "CANCELED",
    "PLANNED",
    "PREPARED",
    "RUN",
    "DELIVERED",
  ];

  filteredStates: string[] = [
    "REQUESTED",
    "DRAFT",
    "CANCELED",
    "PLANNED",
    "PREPARED",
    "RUN",
    "DELIVERED",
  ];

  injectStates: IExerciseInjectState[] = [];

  includesState(val: string): boolean {
    return this.filteredStates.includes(val);
  }

  toogleStateFor(item: string) {
    // console.log(item)
    if (this.filteredStates.includes(item)) {
      let index = this.filteredStates.indexOf(item);
      if (index != -1) {
        this.filteredStates.splice(index, 1);
      }
    } else {
      this.filteredStates.push(item);
    }
    // this.ui.setFilteredStates(this.filteredStates);
  }

  async loadExDetail(id: string) {
    this._currentExercise = undefined;
    this._chatMessages = [];
    this._chatMessagesLocal = [];
    const currentUser = await this.api.apiGetP(`user/me-by-exercise/${id}`);
    console.log(currentUser);
    // this.postSocket('exercise_connect', {id: id})
    // this.ui.initCurrentExerciseUser(currentUser as iUser)

    const r = await this.api.apiGetP(`exercise/detail/${id}`);
    if (r) {
      this._currentExercise = new ExerciseClass(r as ExerciseI);
      this._currentExercise.timelineItems.sort((a, b) => a.topIndex - b.topIndex);
      this.initExerciseClass(this._currentExercise);

      await this.loadExInjects(id);

      wait(50, ()=> {
        this.loadExTrObj(id);
      })
      // this._exerciseInjects  = items as IExerciseTimelineInjectRef[]
      // this.setCurrentExerciseTimelineInhects(_injects)
      // console.log(this._assigendExerciseInjects);
    } else {
            wait(150, () => {
              this.router.navigate(['exercise-overview'])
      });
    }

  }
  /**
   *
   * TIMELINES
   */

  /* private async loadExTimeLines(id: string) {
    console.log(`>>> LOAD exerciseTimelinesRes`);
    const exerciseTimelinesRes = await this.api.apiGetP(
      `exercise/timelines/${id}`
    );

    this._currentExercise?.timelineItems.
    console.log(exerciseTimelinesRes);
    console.log(`>>> LOAD exerciseTimelinesRes COMPLETE`);
  } */

  async reloadTimelines() {
    console.log("reloadTimelines");
    if (this._currentExercise) {
      const exerciseTimelinesRes = await this.api.apiGetP(
        `exercise/timelines/${this._currentExercise.id}`
      );
      this._currentExercise.timelineItems =
        exerciseTimelinesRes as IExerciseTimelineItem[];
      this._currentExercise.timelineItems.sort((a, b) => a.topIndex - b.topIndex);
    }
  }
  private async loadExInjects(id: string) {
    // console.log(`>>> LOAD assigned-by-exercise ${new Date()}`);
    const items = await this.api.apiGetP("inject/assigned-by-exercise/" + id);
    // console.log(`>>> LOAD assigned-by-exercise COMPLETE ${new Date()}`);
    this._assigendExerciseInjects = items as IExerciseTimelineInjectRef[];
  }
  private async loadExTrObj(id: string) {
    const trainingObjsByEx = await this.api.apiGetP(
      `trainingobjective/exercise/${id}`
    );
    this._exerciseTrainingObjectsAdmin =
      trainingObjsByEx as iExerciseTrainingObjective[];

    const chatMsgs = await this.api.apiGetP(`exercise/chat-messages/${id}`);
    this._chatMessages = chatMsgs as ChatMessage[];
    this.fixMessages(this._chatMessages.sort((a, b) => a.id - b.id));
    this.initInjectLocations();
  }
  async loadMOreMessages() {
    if (this._currentExercise) {
      const chatMsgs = await this.api.apiGetP(
        `exercise/chat-messages/${this._currentExercise.id}/?count=${this._chatMessages.length}`
      );
      let _chatMessages: ChatMessage[] = chatMsgs as ChatMessage[];

      _chatMessages.forEach((msg) => {
        const index = this._chatMessages.findIndex((item) => item.id == msg.id);
        // console.log(index);

        if (index == -1) {
          this._chatMessages.push(msg);
        }
      });
      // let inComingMessage: ChatMessage = msg as ChatMessage
      // console.log(inComingMessage);
      // const index = this._chatMessages.findIndex(item => item.id == inComingMessage.id)
      // console.log(index);

      // if(index != -1) {
      //   this._chatMessages.splice(index, 1)
      // }

      this.fixMessages(this._chatMessages.sort((a, b) => a.id - b.id));
    }
  }
  private _assigendExerciseInjectsLocations: Location[] = [];

  get assigendExerciseInjectsLocations(): Location[] {
    return this._assigendExerciseInjectsLocations;
  }

  private initInjectLocations() {
    let f1 = this._assigendExerciseInjects.filter(
      (item) => item.location != null
    );
    if (f1.length > 0) {
      const lll = _.map(f1, "location") as Location[];
      // console.log(lll);
      this._assigendExerciseInjectsLocations = lll;
    }
  }

  async loadInjectTemplatesByExercise(id: string) {
    const injectsByEx = await this.api.apiGetP(
      `inject/templates-by-exercise/${id}`
    );
    this._exerciseInjects = injectsByEx as iExerciseInject[];
  }

  private fixMessages(msgs: ChatMessage[]) {
    const messages: ChatMessageLocal[] = _.cloneDeep(this._chatMessagesLocal);
    let userId: string | undefined;
    if (this._user) {
      userId = this._user.uid;
    }
    let msgsClone: ChatMessage[] = [];
    if (this._currentExercise) {
      msgsClone = msgs.filter(
        (item) => item.exerciseId == this._currentExercise!.id
      );
    }
    let msgsLocal: ChatMessageLocal[] = [];
    // messages = msgs
    var dateTime: Date = new Date();
    // console.log(dateTime);

    for (var i = 0; i < msgsClone.length; i++) {
      const msg = msgsClone[i];
      var input: ChatMessageLocal = {
        id: msg.id,
        dayTime: this.df
          .transform(new Date(msg.createdAt), "hh:mm")!
          .toString(), // msg.createdAt | date: "dd/MM/YYYY, hh:mm",
        message: msg.message,
        userName: msg.userId != userId ? msg.userName : undefined,
        userId: msg.userId,
        dayString: undefined,
        isNew:
          messages.length == 0
            ? false
            : messages.findIndex((item) => item.id == msg.id) == -1,
      };
      if (i == 0) {
        dateTime = new Date(msg.createdAt);
        input.dayString = this.df.transform(dateTime, "dd/MM/YYYY")!.toString();
      }
      const date1 = new Date(msg.createdAt);
      const date2 = dateTime;
      // console.log(date1);
      // console.log(date2);
      // console.log('====');

      const sameDAy = isSameDay(date1, date2);
      // console.log(`${i} sameDAy: ${sameDAy}`);

      if (sameDAy && i > 0) {
        input.dayString = undefined;
        //input.dayString = this.df.transform(new Date(msg.createdAt), 'dd/MM/YYYY')!.toString()
      } else {
        input.dayString = this.df
          .transform(new Date(msg.createdAt), "dd/MM/YYYY")!
          .toString();
        // input.dayString = undefined
      }
      dateTime = new Date(msg.createdAt);
      // if(!date) {
      //   date = new Date(msg.createdAt)
      //   input.dayString = this.df.transform(msg.createdAt, 'dd/MM/YYYY')!.toString()
      // } else {
      //   if (isSameDay(msg.createdAt, date)) {
      //     input.dayString = undefined
      //   } else {
      //      input.dayString = this.df.transform(msg.createdAt, 'dd/MM/YYYY')!.toString()
      //      date = new Date(msg.createdAt)
      //   }
      // }
      // console.log(input);

      msgsLocal.push(input);
    }
    // return msgsLocal
    this._chatMessagesLocal = msgsLocal;
    this.testLocal.set(this._chatMessagesLocal);
    // console.log(this._chatMessagesLocal);
  }
  getTooltipText(item: ChatMessageLocal): string {
    const u = this._user
      ? item.userId == this._user.uid
        ? ""
        : `\n${item.userName}`
      : "";
    return `${item.dayTime} ${u}`;
  }

  get isExerciseDeboarded(): boolean {
    return this._currentExercise ? this._currentExercise.isDeBoarded : true;
  }
  getAccess(userRoles: ExerciseUserRole[]): boolean {
    let hasAccess = false;
    if (
      this._currentExercise &&
      userRoles &&
      this._currentExercise.exerciseUser
    ) {
      hasAccess = userRoles.includes(
        this._currentExercise.exerciseUser.userRole
      );
    }
    return hasAccess;
  }

  async exportToExcel() {
    if (!this.currentExer) {
      return;
    }
    const ratings = await this.api.apiGetP(
      `inject/export-rating/${this.currentExer.id}`
    );
    // console.log(ratings);
    let ratngs:RatingExportItem[] = []
    ratngs = ratings as any[]
    const injectsByEx = await this.api.apiGetP(`inject/templates-by-exercise/${this.currentExer.id}`)
    const out = injectsByEx as iExerciseInject[];
    //
    const exDe = await this.api.apiGetP(`exercise/logbook/${this.currentExer.id}`)
    let logbookEntries = exDe as ExerciseLog[]
    // // console.log(exDe);

    //console.log(this.assigendExerciseInjects);

    // const hourItems = getExerciseDays(Number(this.currentExer.dateTimeStarted), this.currentExer.durationInHours)
    // console.log(hourItems)

    // const tlines = this.currentExer.timelineItems
    // let timelines:any[] = []
    // let timelineExport:TimeLineExportC[] = []
    // tlines.forEach((tl) => {
    //   timelines.push({
    //     name: tl.title,
    //     injects: this.assigendExerciseInjects.filter(item => item.timelineItem!.id == tl.id )
    //   })
    // })
    // // console.log(timelines);
    // timelines.forEach((timeline) => {
    //   var itt:TLExporHourItem[] = []
    //   var timelineExportItem:TimeLineExportC = new TimeLineExportC(timeline.name)
    //   // = {
    //   //   teamName: timeline.name,
    //   //   // injectTitle: []
    //   // }

    //   for(var i = 0; i < hourItems.length; i ++) {
    //     const hh = hourItems[i]
    //     let str:TLExporHourItem = {
    //       hourString: hh.hourString,
    //       injectTitle: ''
    //     }
    //     timeline.injects.forEach((element:any) => {

    //       if(element.deliveryDateTime >= hh.hour! && element.deliveryDateTime < hh.hour! + 7) {
    //         console.log(`hur: ${hh.hour} dd ${element.deliveryDateTime} ${element.short_title}`);
    //         // itt.push(element.short_title)
    //         str.injectTitle = element.short_title

    //       } else {
    //         // itt.push(`${i}`)
    //       }
    //     })
    //     itt.push(str)

    //     // Object.assign(timelineExportItem, {injectTitle: str})
    //   }
    //   //  hourItems.forEach((hh) => {



    //   // })
    //   timelineExportItem.setItems(itt)
    //   // Object.assign(timelineExportItem, {injectTitle: itt})
    //   // timelineExportItem.injectTitle = itt
    //   timelineExport.push(timelineExportItem)
    // })
    // console.log(timelineExport);
    // hourItems.forEach((hh) => {
    //   this.assigendExerciseInjects.forEach((element) => {
    //     if(element.deliveryDateTime >= hh.hour! && element.deliveryDateTime < hh.hour! + 7)
    //       console.log(`hur: ${hh.hour} dd ${element.deliveryDateTime} ${element.short_title}`);
    //       // console.log(`hur: ${hh.hour} dd ${element.deliveryDateTime}`);
    //   })
    // })
     var assInjs: InjectExportAssignedInject[] = []
     this.assigendExerciseInjects.forEach((element) => {
      // console.log(`deliveryDateTime ${element.deliveryDateTime}`);
      const newI = convertInject(element)
      newI.deliveryDateTime = this.getDelDt(element)
      assInjs.push(newI)


     })
    //  console.log(assInjs)

   this.excelService.generateList(assInjs, out, ratngs, logbookEntries);//, hourItems, timelineExport
  }

  getDelDt(inj: IExerciseTimelineInjectRef):string {
    let out = ''
    if(this.currentExer) {
      let pos2:number = Number(inj.leftPosSmall)
      const newDeliveryDateTime: number = Math.round((pos2 / 61) * 60)
      const startDateTime = addMinutes(this.currentExer.getDateTimeRaw(), newDeliveryDateTime)

      out = this.df.transform(startDateTime, "dd.MM.YYYY HH:mm")!.toString()
    }
    return out;
  }

  async sendPush(message: string) {
    if (this.currentExer && !this.currentExer.isDeBoarded) {

      this.postSocket("post_chat_message", {
        exerciseId: this.currentExer.id,
        message: message,
      });
      await this.api.apiPushMessage({
        payload: {
          channelTopic: this.currentExer.id,
          messageBody: message,
          userId: this._user?.uid,
          channelTopicName: this.currentExer.name,
          //tokens: ['c2-9Gc3f0U5kvGUiMbrDxe:APA91bHeymY8XMqK1mOqNQG7mQ-vOIVyKkC9ouGkaP5YN-oK-06yMx_zBU9FYLefo3g4qTfQ44tdVT7Z8WSKpgYgtyVK3byCFojjzq5ayD08h8Eb_kHiOiWBTKusfRCz37DE2TEQiVxM']
        },
      });
    }
    // console.log(r);
  }


  /// USER DATA
  // -- SOCKET
  private initSocket(token: any) {
    this.socket = io(this.socket_endpoint, {
      auth: {
        token: token,
      },
      autoConnect: true,
      // ackTimeout: 5,
    });

    this.socket.connect()
    this.socket.on("connect", () => {
      console.log("soccet connected", this.socket?.id);
      console.log(this.socket?.connected);
    });

    this.socket.on("disconnect", () => {
      console.log("soccet disconnect");
    });

    this.socket.on("inject_update", (msg) => {
      console.log(">>>>>> inject_update <<<<< ");
      // console.log(msg);
      let updateInj: IExerciseTimelineInjectRef =
        msg as IExerciseTimelineInjectRef;
      // console.log(updateInj);
      const index = this._assigendExerciseInjects.findIndex(
        (item) => item.id == updateInj.id
      );
      // console.log(index);
      let oldPos = 0;
      if (index != -1) {
        oldPos = this._assigendExerciseInjects[index].leftPosSmall;
        this._assigendExerciseInjects.splice(index, 1);
      }
      updateInj.animated = true;
      setTimeout(() => {
        updateInj.fadedIn = true;
      }, 250);
      updateInj.prevLeftPos = oldPos;
      this._assigendExerciseInjects.push(updateInj);
      this.initInjectLocations();
    });

    this.socket.on("inject_delete", (msg) => {
      console.log(">>>>>> inject_delete <<<<< ");

      const index = this._assigendExerciseInjects.findIndex(
        (item) => item.id == msg.id
      );

      if (index != -1) {
        this._assigendExerciseInjects.splice(index, 1);
      }
    });
    this.socket.on("user_connect_team", (msg) => {
      console.log(`%c >>>>>> user_connect_team <<<<<`, "color:#1976D2");
      console.log(`%c >>>>>> ${msg} <<<<<`, "color:#1976D2");
    });
    this.socket.on("exercise_updated", (msg) => {
      console.log(`%c >>>>>> exercise_updated <<<<<`, "color:#1976D2");
      // console.log(msg);
      const exs = this.exercises.find(item => item.id == msg.id)
      // console.log(exs);
      if(exs && exs.users && this._user) {
        const usr = exs.users.find(user => user.userId == this._user!.uid)
        if (usr && usr.userRole != ExerciseUserRole.ADMIN){
          this.unsubscribeExercise(msg.id)
        }
      }

      this.loadExercises()
      if(this._currentExercise && this._currentExercise.id == msg.id) {
        this.router.navigate(['exercise-overview'])
        // console.log(`%c >>>>>> navigate exercise-overview <<<<<`, "color:#1976D2");
      } else if(this.router.url.includes('overview')) {

        // wait(1000, () => {
        //   // this.router.navigate(['sign-in'])
        //   console.log(`%c >>>>>> navigate exercise-sign-in <<<<<`, "color:#1976D2");
        // })
      }

    });
    this.socket.on("chat_message", (msg) => {
      console.log(">>>>>> chat_message <<<<< ");
      console.log(msg);
      let inComingMessage: ChatMessage = msg as ChatMessage;
      console.log(inComingMessage);
      const index = this._chatMessages.findIndex(
        (item) => item.id == inComingMessage.id
      );
      // console.log(index);

      if (index != -1) {
        this._chatMessages.splice(index, 1);
      }
      if(this._user) {
        const isOwnMessage = inComingMessage.userId == this._user.uid
        console.log(`isOwnMessage: ${isOwnMessage}`)
        var input: ChatMessageLocal = {
          id: inComingMessage.id,
          dayTime: this.df
            .transform(new Date(inComingMessage.createdAt), "hh:mm")!
            .toString(), // msg.createdAt | date: "dd/MM/YYYY, hh:mm",
          message: inComingMessage.message,
          userName: !isOwnMessage ? inComingMessage.userName : undefined,
          userId: inComingMessage.userId,
          dayString: undefined,
          isNew: false

        };
        if (!isOwnMessage) {
          this.incomingMessageEmitter.emit(input)
        }
      }

      this._chatMessages.push(inComingMessage);
      this.fixMessages(this._chatMessages.sort((a, b) => a.id - b.id));
    });


  }

  private unsubscribeExercise(id: string) {
    wait(1000, () => {
      // this.router.navigate(['sign-in'])
      console.log(`%c >>>>>> unsubscribeExercise <<<<<`, "color:#1976D2");
      this.postSocket('exercise_unsubscribe', {id: id})
    })

  }
  socketPush(dto: Object) {
    if (this.socket) {
      console.log("\n\n SOCKET PUSH exercise_inject_to_timeline");
      this.socket.emit("exercise_inject_to_timeline", dto);
    }
  }
  postSocket(message: string, dto: Object) {
    if (this.socket) {
      console.log(`post socket ${message}`);
      this.socket.emit(message, dto);
    }
  }

  socketInjectUodate(dto: InjectItemUpdateDto) {
    if (this.socket) {
      console.log("\n\n SOCKET PUSH ASSIGED INJECT");
      this.socket.emit("inject_item_update", dto);
    }
  }

  get currentUser(): iUser | undefined {
    return this._user;
  }
  get exerciseUserRole() : ExerciseUserRole | undefined {
    if(!this._currentExercise || !this._currentExercise.exerciseUser) {
      return undefined;
    } else {
      return this._currentExercise.exerciseUser.userRole;
    }
  }


  get isLoggedIn(): boolean {
    return this._isLoggedIn;//this._user ? this._user.loggedIn : false; //;
  }

  signOut(): void {
    // window.sessionStorage.removeItem();
    // window.sessionStorage.removeItem(UID);
    this.ts.clear()
    window.sessionStorage.clear();
    this._isLoggedIn = false;
    this._user = undefined;
    this.initialUrl = undefined
  }

  public initUser(user: iUser, tItem: IAuthToken): void {
    this.ts.saveToken(tItem);
    this._user = user;
    this._isLoggedIn = true;
    this.initSocket(tItem.token);
  }

  public getToken(): IAuthToken | null {
    return this.ts.getToken();
  }

  private async loadCurrentUser(token: string) {
    console.log(`%c StorageService > loadCurrentUser()`,'color:#FF006FFF');
    const userResponse:any = await this.api.apiGetP(`user/`)
    this._user = userResponse as iUser;
    console.log(this._user);
    if(this._user.needPWChange === true) {
      this._isLoggedIn = false;
    } else {
      this._isLoggedIn = true;
      this.initSocket(token);
      if(this.router.routerState.snapshot.url.includes('sign-in')){
        wait(50,() => {
          this.router.navigateByUrl(this.initialUrl ? this.initialUrl : 'exercise-overview')
        })
      }
      // console.log(this.router.routerState.snapshot.url);
      // console.log(this.initialUrl);

    }

  }

  exercises: ExerciseI[] = []
  async loadExercises() {
    const r = await this.api.apiGetP('exercise')
    console.log(r);
    this.exercises = r as ExerciseI[]
  }
}
