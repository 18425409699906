<mat-toolbar class="overlay-toolbar">
  <span class="toolbar-spacer"></span>
  <mat-icon style="color:#ABBBC7; cursor: pointer;" (click)='close()'>close</mat-icon>
</mat-toolbar>

<div class="container">

  <div class="d-flex flex-column">

    <div class="p-2 textfield100">
      <mat-label class='mat-caption uc'>Inject No.*</mat-label>
      <input type="text"  [class.formControlError]='injectHasError' [class.formControl] = '!injectHasError'
      class=" textfield100 textFieldH40" required (keyup)='injectNoKeyDown($event)'
      placeholder="inject_no" style="text-transform: uppercase;"
      [(ngModel)]="inject_no" >
    </div>
    <div class="p-0">{{errorCopy}}</div>
  </div>
</div>

<mat-toolbar class="overlay-toolbar">
  <span class="toolbar-spacer"></span>
  <ect-color-btn class="mr5" title="save" icon="save" color="primary" [disabled]="!saveEnabled" (clicked)="save()" ></ect-color-btn>
</mat-toolbar>
