
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { MatSelectChange } from "@angular/material/select";
import { IExerciseInjectState } from "src/app/model/exercise.model";
import { StorageService } from "src/app/services/storage.service";

@Component({
  selector: 'inject-detail-ol-state',
  template: `
   <div class="d-flex flex-column p0">
          <div class="p-0  uc itemTitle fs12 m0 mb3">{{title}}</div>
            <!-- <div class="p-2 flex-fill" *ngIf='!canEdit'>
              <div class="textfield100 select-form-field font-main">{{inject.deliveryMethod}}</div>
            </div> -->
          <div class="p-0" >
          <!-- *ngIf='canEdit' -->
            <mat-form-field appearance="outline" class="textfield100 select-form-field pb0">
            <mat-select [(value)]='selectedState'  (selectionChange)='onStateChanged($event)' [disabled]='!canChange' class="font-main">
              <mat-option *ngFor="let injectState of storage.injectStates" [value]="injectState">
              {{injectState.title}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          </div>
        </div>
         <!-- <div class="d-flex flex-column p0" style="height: 66px;">
          <div class="p-2 flex-fill p0 uc itemTitle fs12 m0">{{title}}</div>
          <div class="p-2 flex-fill" *ngIf='!canEdit'>
            <div class="textfield100 select-form-field font-main">{{inject.deliveryMethod}}</div>
          </div>
          <div class="p-0 flex-fill " *ngIf='selectable'>
            <mat-form-field appearance="outline" class="textfield100 select-form-field pb0">
            <mat-select [value]='selectedState'  (selectionChange)='onStateChanged($event)' [disabled]='disabled' class="font-main">
              <mat-option *ngFor="let injectState of injectStates" [value]="injectState">
                {{injectState.title}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          </div>
          <div class="p-0 d-flex h100 align-items-center borderR"  *ngIf='!selectable'>
            <div class="p-2 pl10" [ngStyle]="{'color':inject.state.color}" style="font-size: 18px; font-weight: 500;">{{inject.state.title}}</div>
          </div>
        </div> -->
  `,
    styleUrls: ['./inject-detail-overlay.component.scss']
})
export class InjectDetailOL_StateComponent implements OnInit {
  // @Input() inject!: IExerciseTimelineInjectRef
  @Input() injectState?: IExerciseInjectState
  @Input() title!: string
  @Input() selectable: boolean = true
  @Input() canChange:boolean = false
  @Output() onChanged = new EventEmitter<IExerciseInjectState>();
  // injectStates: IExerciseInjectState[] = []
  selectedState: IExerciseInjectState | undefined
  currentState: IExerciseInjectState | undefined
  // canEdit = true
  // isSaving = false
  // disabled = false

  constructor(public storage: StorageService) {}

  ngOnInit(): void {
    // console.log(this.inject);
    // this.injectStates = this.storage.injectStates
    this.selectedState = this.injectState//this.injectStates.find(item => item.id == this.inject.state.id) ?? undefined
    // console.log(this.selectedState);
    this.currentState = this.selectedState

  }
  onStateChanged(e: MatSelectChange) {
    // this.selectedDelivertMethod = e
    // console.log(e.value);
    this.onChanged.emit(e.value)
    // console.log(this.selectedState);
    // if(this.selectedState?.id != e.value.id) {
    //   console.warn('NEED TO BE SAVED');
    //   this.onChanged.emit(e.value)
    // }

    // this.inject.deliveryMethod = e.itemEnum

  }
  // get textColorForInject():string{
  //   return "#1E2A33";
  //   // if(inject.state.keyName === 'REQUESTED'){
  //   //   return "#1E2A33";
  //   // }
  //   // else {
  //   //   return  "#ffffff"
  //   // }

  // }
}
