import { Component, EventEmitter, inject, Input, OnInit, Output } from "@angular/core";
import { ExerciseClass, ExerciseUserRole, IExerciseTimelineInjectRef, IExerciseTimelineItem } from "../../model/exercise.model";
import { ExerciseDashboardNavIcon, NAV_EVENT } from "../../model/models";
import { UiService } from "src/app/services/ui.service";
import { wait } from "src/app/services/utils/functions";


@Component({
  selector: "exercise-duration-ol-tl-item",
  template: `
   <div class="inject-item-circle-holder_single"

  autoScrollDisabled="true"



  [ngStyle]="styles"
  [class.dragCursor]="!dragDisabled"
  [class.circleFadeIn]="fadedIn"


  matTooltipClass="injectTimelineTT"
  matTooltipPosition="before"

  matTooltip='{{tooltip}}'
>

<!--(click)="openDetailTest(inject)"   -->

  <div class="inject-item-background-single" [ngStyle]="{'background-color':inject.state.color}"></div>

  <div class="inject-item-circle-title-single " [class.inject-item-circle-title-top-single]='ui.timeLineLabelOnTop' style="margin-left: 0px!important;">
    <div class="d-flex align-items-center" style="height: 100%; width: 100%; user-select: none;">

    <div class="d-flex align-items-start " *ngIf="dragDisabled" >
        <!-- <div class="p-0 pl10">{{inject.timeString}}|{{inject.deliveryDateTime}}</div> -->
        <!-- {{inject.short_title}} <br> -->
        <!-- <div class="p-0">{{inject.leftPosSmall}}</div> -->
        <div class="p-0 pl10 " *ngIf="scaleFact < 1">{{inject.short_title | slice: 0:4 }}</div>
        <div class="p-0 pl10 " *ngIf="scaleFact >= 1">{{inject.short_title | slice: 0:10 }}</div>
      </div>


    </div>
  </div>



  <!-- <div [class.inject-item-circle-animation-single]='isAnimated' [ngStyle]="{'border-color':inject.state.color}"></div> -->
  <div [class.inject-item-circle-dragging]='!dragDisabled' [ngStyle]="{'border-color':inject.state.color}"></div>
</div>
  `,
    styleUrls: ["./exercise-duration-overlay.component.scss"],
})
export class ExerciseDurationOverlayTimeLineItem implements OnInit {
  @Input() inject!: IExerciseTimelineInjectRef;
  @Input() exercise!: ExerciseClass;
  @Input() roleItem!: IExerciseTimelineItem;
  @Input() scaleFact!: number


  dragDisabled = true
  fadedIn = false
  public ui = inject(UiService)

  tooltip = ''

  ngOnInit(): void {
      wait(15, () => {
        this.fadedIn = true
      })
  }
  get topPosByIndex(): string {
    // return(28 + (teamIndex * 60)).toString() +'px';
    // return(14 + (teamIndex * 60)).toString() +'px';
    let start = 2; //+ (teamIndex * 60);
    let height: number = 0;
    for (var i = 0; i < this.exercise.timelineItems.length; i++) {
      if (
        this.roleItem.topIndex >= this.exercise.timelineItems[i].topIndex &&
        this.roleItem.id != this.exercise.timelineItems[i].id
      ) {
        height += 50 + (this.exercise.timelineItems[i].height  * 50);
      }
    }
    let top = this.inject.topPos ? this.inject.topPos : 0;
    start += height;
    start += top * 49 + 2;
    return start.toString() + "px";

    // let start = 3//+ (teamIndex * 60);
    // let height: number = 0
    // for(var i = 0; i < this.exercise.timelineItems.length; i ++) {
    //   if (this.roleItem.topIndex >= this.exercise.timelineItems[i].topIndex && this.roleItem.id != this.exercise.timelineItems[i].id) {
    //     height += 60 +(this.exercise.timelineItems[i].height * 60)
    //   }
    // }
    // let top = inject.topPos ? inject.topPos : 0
    // start += height
    // start += (top  * 59) + 3
    // return(start).toString() +'px';
  }

  private _topPos: string = ''

  get textColor(): string {
    return "#1E2A33";
  }

  get left() {


    if (this.ui.smallTimeLineItems) {
      return this.inject.leftPosSmall * this.scaleFact
    } else {
      return this.inject.leftPos * this.scaleFact;
    }

  }

  get styles(): any {
    return {
      transform:
        "translate(" +
        this.left +
        "px, " +
        this.topPosByIndex +
        // this._topPos +
        ")",
      color: this.textColor,
      width:
        (this.ui.smallTimeLineItems
          ? this.inject.deliveryDurationInMinutes * this.scaleFact
          : (this.inject.deliveryDurationInMinutes * this.scaleFact) * 2) + "px",
      transition: "all 0.35s ease-in-out",
      // transition: "all 0.35s cubic-bezier(0, 0, 0.2, 1)",
      // "transition-delay": "6s",
    };
    // return {'transform': 'translate(' + (this.ui.smallTimeLineItems ? this.inject.leftPosSmall : this.inject.leftPos)  + 'px, ' + this.getTopPosByIndex(this.inject) + ')','background-color': this.inject.state.color, 'color':this.getTextColorForInject(this.inject), 'width': (  this.ui.smallTimeLineItems ? (this.inject.deliveryDurationInMinutes ) : (this.inject.deliveryDurationInMinutes * 4)) +'px', 'opacity': (this.isAnimated ? 0.5 : 1)}
  }
}
