import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { ExerciseState } from 'src/app/model/enums';
import { ExerciseClass, HourItemI, HourItemIState, IExerciseTimelineInjectRef, TacticalMonth, TacticalTime } from 'src/app/model/exercise.model';
import { TIME } from 'src/app/services/helpers';
import {map, startWith} from 'rxjs/operators';
import {  addMinutes, differenceInDays, differenceInMinutes, isAfter, isBefore, isEqual } from 'date-fns';
import * as _ from 'lodash';
import { ApiService } from 'src/app/services/api.service';
import { getExerciseDays, wait } from 'src/app/services/utils/functions';
import { StorageService } from '../../services/storage.service';
import { InjectUpdateDto } from 'src/app/model/dtos';
import { BUTTON_ERROR_TYPE, miniOvlItem } from 'src/app/model/models';
import { UiService } from 'src/app/services/ui.service';
import {  gsap  } from 'gsap';
import { LEVEL, Logger } from 'src/app/services/logger.service';

import { HourAdjustEvent, HourAdjustEventType, TimelineController } from 'src/app/services/utils/timelineController';
@Component({
  selector: 'app-exercise-duration-overlay-new',
  templateUrl: './exercise-duration-overlay-new.component.html',
  styleUrls: ['./exercise-duration-overlay-new.component.scss']
})
export class ExerciseDurationOverlayNewComponent  implements OnInit {
  assigendExerciseInjects: IExerciseTimelineInjectRef[] = []
  @ViewChild('_time_line_mini_map_box') _time_line_mini_map_box:ElementRef | undefined
  @ViewChild('timelineInjectsHolder') timelineInjectsHolder:ElementRef | undefined
  @ViewChild('_dayInput') _dayInput:ElementRef | undefined
  @ViewChild('_monthStart') _monthStart:ElementRef | undefined
  @ViewChild('_monthEnd') _monthEnd:ElementRef | undefined
  @ViewChild('_timeBar') _timeBar:ElementRef | undefined

  // durationDays:string =''
  // durationHours:string =''
  // durationMinutes:string =''
  // durationDiffInSec:number =0;
  // startDateTime:TacticalTime  | undefined
  // startDateTime:TacticalTime  | undefined
  // lastStartDateTime:TacticalTime  | undefined
  // exerciseDurationInMinutes: number = 0
  // lastExerciseDurationInMinutes: number = 0
  // endDateTime:TacticalTime | undefined
  //timeStart:TacticalTime  | undefined
// durationInDays = '00'
  // durationInHours = '00'
  // durationInMinutes = '00'
  // hourItems: HourItemI[] = [];

  miniOvlItems: miniOvlItem[] = [];
  startMonthFormC = new FormControl();

  selectedStartMonth:TacticalMonth | undefined
  adjustInjectStartTime = true
  isLoading = false
  preloaderVisible = true
  allowdDays = ['1','2','3','4','5','6','7','8','9','0'];
  allowdDaysHours = ['1','2','3','4','5','6','7','8','9','0'];
  errorText =  ''
  currentFact = 0

  buttonErrors: BUTTON_ERROR_TYPE[] = []
  BUTTON_ERROR_TYPES = BUTTON_ERROR_TYPE

  HourItemIStates = HourItemIState
  timelineController:TimelineController = new TimelineController()

  get isReadOnly():boolean {
    if (!this.excercise) {
      return true
    }
    return !this.excercise.isAdmin
  }

  showSaveButton:boolean = true;
  months:TacticalMonth[] = [
    {viewVal:"jan", index:0},
    {viewVal:"feb", index:1},
    {viewVal:"mar", index:2},
    {viewVal:"apr", index:3},
    {viewVal:"may", index:4},
    {viewVal:"jun", index:5},
    {viewVal:"jul", index:6},
    {viewVal:"aug", index:7},
    {viewVal:"sep", index:8},
    {viewVal:"oct", index:9},
    {viewVal:"nov", index:10},
    {viewVal:"dec", index:11},
  ]

  filteredMonths: Observable<TacticalMonth[]>  | undefined

  exerciseStates = ExerciseState

  excercise:ExerciseClass

  dayInput = 0;
  hourInput = 0;
  minInput = 0;
  yearInput = 0;

  miniMapWidth: number = 0;




  get hasAssignedInjects(): boolean {
    return this.storage.assigendExerciseInjects.length > 0
  }

  constructor(
    public dialogRef: MatDialogRef<ExerciseDurationOverlayNewComponent>,
    @Inject(MAT_DIALOG_DATA) public data:ExerciseClass,
    public storage: StorageService,
    public ui: UiService,
    private api: ApiService) {
      this.excercise = data
      const _c:TacticalTime = TIME.tt_currentUTC()
      this.isLoading = true
      this.preloaderVisible = true
    }

    ngOnInit(): void {
      // const txOfs = new Date().getTimezoneOffset();

      // Logger.log(`offset ${txOfs}`, LEVEL.DEBUG);
      var _startDateTime:TacticalTime  | undefined
      var _exerciseDurationInMinutes: number = 0

      setTimeout(()=>{
        if(!this.excercise) { return }

        _startDateTime = TIME.date_lcoal_to_tt(this.excercise.getDateTime())

        this.selectedStartMonth = _startDateTime.month

        _exerciseDurationInMinutes = this.excercise.durationInHours * 60

        /** HIER 1 */

        this.assigendExerciseInjects = _.cloneDeep(this.storage.assigendExerciseInjects)

        for(var i = 0; i < this.assigendExerciseInjects.length; i ++) {
          this.assigendExerciseInjects[i].oedeliveryDateTime = this.assigendExerciseInjects[i].deliveryDateTime
          this.assigendExerciseInjects[i].leftPosOe = this.assigendExerciseInjects[i].leftPos
          this.assigendExerciseInjects[i].leftPosSmallOe = this.assigendExerciseInjects[i].leftPosSmall
        }

        var _earliestMillis: number | undefined
        var _maxtMillis: number | undefined

        if(this.assigendExerciseInjects.length > 0) {

          const earliestInject = _.minBy(this.assigendExerciseInjects, 'deliveryDateTime')
          _earliestMillis = earliestInject?.deliveryDateTime
          // if (earliestInject) {
          //   Logger.log(`earliestInject deliveryDateTime: ${earliestInject.deliveryDateTime} title: ${earliestInject.short_title}`, LEVEL.DEBUG);
          // }
          const latestInject = _.maxBy(this.assigendExerciseInjects, function(i) {
            return Number(i.deliveryDateTime) + Number(i.deliveryDurationInMinutes)
          })

          _maxtMillis = Number(latestInject?.deliveryDateTime) + Number(latestInject?.deliveryDurationInMinutes)
          // if(latestInject) {
          //   const latestInjectTime = Number(latestInject.deliveryDateTime) + Number(latestInject.deliveryDurationInMinutes);
          //   Logger.log(`${latestInject.deliveryDateTime} ${latestInject.short_title} `, LEVEL.DEBUG)
          //   _maxtMillis = latestInjectTime;
          // }
        }

        if (_startDateTime && _exerciseDurationInMinutes) {
          this.timelineController.initByTT(_startDateTime, _exerciseDurationInMinutes, _earliestMillis, _maxtMillis)
        }

        this.timelineController.scrollToEmitter.subscribe((itemId: string) => {
          Logger.log(`scrollToEmitter id found ${itemId}`, LEVEL.DEBUG);
          wait(1000, () => {
            const el = document.getElementById(itemId);
            if(el) {
              el.scrollIntoView({
                behavior: "smooth",
                block: "start",
                inline: "nearest"
              });
            }
          })
        })

        this.timelineController.taskActionEmitter.subscribe((event: HourAdjustEvent) => {
          // if (this.adjustInjectStartTime) {
          //   this.fixAssignedInjectTimeLocal(-this.currentFact)
          // } else {
          //   this.fixAssignedInjectTimeLocal(this.currentFact)
          // }
          Logger.log(`taskActionEmitter >> ${event.event}`, LEVEL.DEBUG);
          if (event.event == HourAdjustEventType.HOUR_REMOVED) {
            if (this.adjustInjectStartTime) {
              this.fixAssignedInjectTimeLocal(-(event.itemCount * 60), true)
              // this.fixAssignedInjectTimeLocal(this.timelineController.countAddedHoursFromStart * 60, true)
            }
            // if (this.adjustInjectStartTime) {
            // }
            // this.fixAssignedInjectTimeLocal(0, true)
            // this.fixAssignedInjectTimeLocal(-(this.timelineController.countAddedHoursFromStart * 60), true)
          } else if (event.event == HourAdjustEventType.HOUR_ADDED) {
            if (this.adjustInjectStartTime) {
              this.fixAssignedInjectTimeLocal((event.itemCount * 60), true)
              // this.fixAssignedInjectTimeLocal(this.timelineController.countAddedHoursFromStart * 60, true)
            }
          }
          // if (this.adjustInjectStartTime) {
          //   this.fixAssignedInjectTimeLocal(this.timelineController.countAddedHoursFromStart * 60, true)
          // } else {
          //   // this.fixAssignedInjectTimeLocal(-this.currentFact, true)
          // }
          // this.currentFact = this.timelineController.countAddedHoursFromStart
          // this.currentFact = this.timelineController.getLeftSpacing()
          // Logger.log(`taskActionEmitter space:  ${this.currentFact}`, LEVEL.DEBUG);
          // if (this.adjustInjectStartTime) {
          //   this.fixAssignedInjectTimeLocal(this.currentFact, true)
          // } else {
          //   // this.fixAssignedInjectTimeLocal(-this.currentFact, true)
          // }

        })

        this.calcDuration(()=> {
         wait(160, () => {
          this.isLoading = false;
          this.init()
          wait(160, () => {
            this.preloaderVisible = false;
      //       const tlScroller = document.getElementById('timeBarView')
      // if (tlScroller) {
      //   tlScroller.addEventListener("scroll", this.tlViewScroll, false);
      // } else {
      //   Logger.log(`SCROLLER NOT FOUND`, LEVEL.DEBUG);
      // }
           })
         })
        })
          this.filteredMonths = this.startMonthFormC.valueChanges
          .pipe(
            startWith(''),
            map(value => typeof value === 'string' ? value : value.name),
            map(name => name ? this.applyFilter(name) : this.months.slice())
          );

          this.miniMapWidth = this._time_line_mini_map_box ? this._time_line_mini_map_box.nativeElement.clientWidth : 1200;

        },500)


    }

/*     displayFn(moth: TacticalMonth): string {
      return moth && moth.viewVal ? moth.viewVal : '';
    } */

    // _lastDateStartet: number | undefined
    // _lastDurationInHours: number | undefined


    // private tlViewScroll = (event: any) => {
    //   console.log(`tlViewScroll ${event.target.scrollLeft}`);
    // }



    private init() {
      wait(50, () => {
        // Logger.log(`windot: ${window.innerHeight}`);
        if (this._time_line_mini_map_box) {
          const height = this._time_line_mini_map_box.nativeElement.clientHeight
          Logger.log(`height: ${height}`, LEVEL.DEBUG);

          if (this.timelineInjectsHolder) {
            const injectHolderHeight = this.timelineInjectsHolder.nativeElement.clientHeight
            Logger.log(`injectHolderHeight: ${injectHolderHeight}`, LEVEL.DEBUG);

            // if (injectHolderHeight > 350) {

            //   const newHeight = (window.innerHeight - 120) - 400
            //   Logger.log(`newHeight ${newHeight}`, LEVEL.DEBUG);
            //   gsap.to(this._time_line_mini_map_box.nativeElement, { duration: 0.25, delay: 0.4, height: newHeight, ease: 'power1.inOut' })
            // }
          }
        }

      })
      wait(1300, () => {
        if(this._dayInput) {
          this._dayInput.nativeElement.focus()
          this._dayInput.nativeElement.select()
        }
      })
    }

    private setupTimeline(dateTimeStarted: number, durationInHours: number) {
      this.timelineController.setupTimeline(dateTimeStarted, durationInHours, () => {
        this.calcDiff()
      })
    }

    applyFilter(name: string):TacticalMonth[] {
      const filterValue = name.toLowerCase();

      return this.months.filter(option => option.viewVal.toLowerCase().indexOf(filterValue) === 0);
    }

    // private setInitialInput(tt: TacticalTime) {
    //   Logger.log(' - setInitialInput - ', LEVEL.DEBUG);
    // }

    formatedString(tt?: TacticalTime) {
      if(!tt) { return '' }

      const dt = TIME.tt_toDate(tt);
      return TIME.formatedDateString(dt) + ' ' + TIME.formatedTimeString(dt)
    }

    calcDuration(callback: () => void) {
      if(this.timelineController.startDateTime) {
        // Logger.log(`this.exerciseDurationInMinutes ${this.exerciseDurationInMinutes}`);
        // if (this.timelineController.exerciseDurationInMinutes < 1440) {
        //   this.exerciseDurationInMinutes = 1440
        // }
        // const endDate = new Date(addMinutes(TIME.tt_toDate(this.startDateTime), this.exerciseDurationInMinutes))
        // this.endDateTime = TIME.date_to_tt(endDate)
        // this.exerciseDurationInMinutes = differenceInMinutes(endDate,TIME.tt_toDate(this.startDateTime))


        // if(this.durationDiffInSec != (this.timelineController.exerciseDurationInMinutes * 60) ){
        //   this.durationDiffInSec != (this.timelineController.exerciseDurationInMinutes * 60)
        //   this.showSaveButton = true;
        // }
         this.setupTimeline(Number(TIME.tt_toDate(this.timelineController.startDateTimeTactical).getTime()), this.timelineController.exerciseDurationInMinutes / 60)

      }

      callback()
    }

    onTFChanged(event: any, type: string) {
      Logger.log(`onTFChanged`, LEVEL.DEBUG);

      var _startDateTime = _.cloneDeep(this.timelineController.startDateTimeTactical)

      let canCalc = true;
      if(type == 'DAY'){
        let charsAt = _startDateTime!.day;
        let cleanUp = false;
        for(let c of charsAt.split('')) {
          Logger.log(`c: ${c}`, LEVEL.DEBUG);
          if(!this.allowdDays.includes(c)) {
            cleanUp = true;
            canCalc = false;
          }
        }
        const _day = _.parseInt(_startDateTime!.day)
        if(cleanUp || (_day && _day > 31)) {
          _startDateTime!.day = TIME.date_lcoal_to_tt(new Date()).day
          // setTimeout(()=>{
          // },10)
        } else if (_day && _day < 10) {
          _startDateTime!.day = `0${_startDateTime!.day}`
          // setTimeout(()=>{
          // },10)
        }
        setTimeout(()=>{
          this.timelineController.changeStartByTT(_startDateTime)
        },10)

      } else if (type == 'HOUR'){
        let charsAt = _startDateTime!.hours;
        let cleanUp = false;
        for(let c of charsAt.split('')) {
          if(!this.allowdDays.includes(c)) {
            cleanUp = true;
            canCalc = false;
          }
        }
        const _hours = _.parseInt(_startDateTime.hours)
        // Logger.log(`cleanUp: ${cleanUp}`);
        // Logger.log(`_hours: ${_hours}`);
        if(cleanUp || (_hours && _hours > 23)) {
          _startDateTime.hours = TIME.date_lcoal_to_tt(new Date()).hours
          // setTimeout(()=>{
          // },10)
        } else if (_hours && _hours < 10) {
          _startDateTime.hours = `0${_startDateTime.hours}`
          // setTimeout(()=>{
          // },10)
        } else {
          if (event.target.value && event.target.value == '0') {
            _startDateTime.hours = `00`
            // setTimeout(()=>{
            // },10)
          }
        }
        setTimeout(()=>{
          this.timelineController.changeStartByTT(_startDateTime)
        },10)
        // Logger.log(this.startDateTime);
      } else if (type == 'MIN'){
        let charsAt = _startDateTime.minutes;
        let cleanUp = false;
        for(let c of charsAt.split('')) {
          if(!this.allowdDays.includes(c)) {
            cleanUp = true;
            canCalc = false;
          }
        }
        const _minutes = _.parseInt(_startDateTime.minutes, 2)
        Logger.log(`_minutes ${_minutes} cleanUp: ${cleanUp}`, LEVEL.DEBUG);
        if(cleanUp || (_minutes && _minutes > 59)) {
          setTimeout(()=>{
            _startDateTime.minutes = TIME.date_lcoal_to_tt(new Date()).minutes
          },10)
        } else if (_minutes && _minutes < 10) {
          setTimeout(()=>{
            _startDateTime.minutes = `0${_startDateTime.minutes}`
          },100)
        } else {
          if (event.target.value && event.target.value == '0') {
            setTimeout(()=>{
              _startDateTime.minutes = `00`
            },10)
          }
        }
      } else if (type == 'YEAR') {

      }

      wait(500, ()  => {

        if(canCalc) {
          this.calcDuration(()=> {})
          this.showSaveButton = true;
        }
      })

      // wait(1500, ()  => {
      //   this.lastStartDateTime = _.cloneDeep(this.startDateTime)
      // })
    }

    startMonthSelected(event: any){
      Logger.log(`${event}`, LEVEL.DEBUG);
      var _startDateTime = _.cloneDeep(this.timelineController.startDateTimeTactical)
      _startDateTime.month = event;//.option.value
      this.timelineController.changeStartByTT(_startDateTime)
      this.calcDuration(()=> {})
      /* this._save() */
    }

    get saveBtnDisabled(): boolean {
      if (this.timelineController.exerciseDurationInMinutes >= 1440) {
        return false
      } else {
        return true
      }
    }

    // private checkDaysBetween() {
    //  /*  if(this.endDateTime && this.startDateTime) {
    //     const diffInDays = differenceInDays(TIME.tt_toDate(this.endDateTime), TIME.tt_toDate(this.startDateTime))
    //     // Logger.log(`diffInDays: ${diffInDays}`);
    //     this.durationInDays = `${diffInDays}`
    //     const diffInMins = differenceInMinutes(TIME.tt_toDate(this.endDateTime), TIME.tt_toDate(this.startDateTime))
    //     // Logger.log(`diffInMins: ${diffInMins}`);
    //   } */
    // }

    addTimeFor(itemType: string){
      var _startDateTime = _.cloneDeep(this.timelineController.startDateTimeTactical)
      this.errorText = ''
      if(itemType == 'DAY'){

        // this.timelineController.appendDay()
        // const days = _.parseInt(this.durationInDays)

        // let added = days + 1;

        // this.durationInDays = `${added}`;//added < 10 ? `0${added}` : `${added}`
        // const start = new Date(TIME.tt_toDate(_startDateTime));

        // this.exerciseDurationInMinutes = this.exerciseDurationInMinutes + 1440;

        // this.endDateTime = TIME.date_to_tt(addMinutes(start, this.exerciseDurationInMinutes))

      } else if(itemType == 'HOUR'){
        // this.timelineController.appendHour()
        // const start = new Date(TIME.tt_toDate(this.startDateTime!));
        // const hours = _.parseInt(this.durationInHours)

        // let added = hours + 1;
        // if (added == 24) {
        //   this.durationInHours = `0`;

        // } else {
        //   this.durationInHours = `${added}`;//added < 10 ? `0${added}` : `${added}`
        // }
        // this.exerciseDurationInMinutes = this.exerciseDurationInMinutes + 60;
        // this.endDateTime = TIME.date_to_tt(addMinutes(start, this.exerciseDurationInMinutes))

        // this.checkDaysBetween()
      }
      wait(150, () => {
        //this.setupTimeline(TIME.tt_toDate(this.startDateTime!).getTime(), this.exerciseDurationInMinutes / 60)
      })
      // else if(itemType == 'MIN'){

      //   const start = new Date(TIME.tt_toDate(this.startDateTime!));
      //   // Logger.log(start);

      //   let end = new Date(addMinutes(start, this.exerciseDurationInMinutes))
      //   // Logger.log(end);

      //   this.exerciseDurationInMinutes = differenceInMinutes(addMinutes(end,1), start)
      //   this.endDateTime = TIME.date_to_tt(addMinutes(end, this.exerciseDurationInMinutes))
      //   this.durationDiffInSec = this.exerciseDurationInMinutes * 60

      // }
      this.showSaveButton = true;
    }

    subTimeFor(itemType: string){
      /* if(itemType == 'DAY'){

        const days = _.parseInt(this.durationInDays)
        // const days = parseInt(this.durationInDays)
        let newDays = days - 1;
        if (days >= 1 && newDays >= 1) {
          // Logger.log(`days ${days}` );
          // Logger.log(`newDays ${newDays}` );

          this.durationInDays = `${newDays}`//newDays < 10 ? `0${newDays}` : `${newDays}`
          const start = new Date(TIME.tt_toDate(this.startDateTime!));

          this.exerciseDurationInMinutes = this.exerciseDurationInMinutes - 1440;

          this.endDateTime = TIME.date_to_tt(addMinutes(start, this.exerciseDurationInMinutes))
        }
      } else if(itemType == 'HOUR'){

        const start = new Date(TIME.tt_toDate(this.startDateTime!));

        const hours = _.parseInt(this.durationInHours)

        let newHours = hours - 1;
        if (hours >= 0 && newHours >= 0) {
          Logger.log(`hier 1: ${newHours}`, LEVEL.DEBUG);
          // Logger.log(`hours ${hours}` );
          // Logger.log(`newHours ${newHours}` );

          this.durationInHours = `${newHours}`//newHours < 10 ? `0${newHours}` : `${newHours}`

        } else {
          Logger.log(`hier 2: ${newHours}`, LEVEL.DEBUG);
          this.durationInHours = `23`
        }

          this.exerciseDurationInMinutes = this.exerciseDurationInMinutes - 60;

          this.endDateTime = TIME.date_to_tt(addMinutes(start, this.exerciseDurationInMinutes))

      } else if(itemType == 'MIN'){

      }
      this.checkDaysBetween()
      wait(150, () => {
        this.setupTimeline(TIME.tt_toDate(this.startDateTime!).getTime(), this.exerciseDurationInMinutes / 60)
      })
      this.showSaveButton = true; */
    }


    adjustMessage = ''
    direction = ''

    adjustInjectStartTimeChanged() {

      // this.currentFact = this.timelineController.countAddedHoursFromStart

      // Logger.log(`adjustInjectStartTime space:  ${this.currentFact}`, LEVEL.DEBUG);

      // this.currentFact = (this.timelineController.countAddedHoursFromStart * 60)
      // Logger.log(`adjustInjectStartTime ${this.adjustInjectStartTime} space: ${this.timelineController.countAddedHoursFromStart}`, LEVEL.DEBUG);
      Logger.log(`adjustInjectStartTime mode:  ${this.adjustInjectStartTime} earliestSavedDDT: ${this.timelineController.earliestStartMillis}`, LEVEL.DEBUG);
      if (this.adjustInjectStartTime) {
        //  this.fixAssignedInjectTimeLocal(Number(diffInMin))
        // this.fixAssignedInjectTimeLocal(-this.currentFact)
      } else {
        const current = this.getCurrentEarliestDeliveryDateTime()
        const diff = -(current - (this.timelineController.earliestStartMillis ?? 0))
        this.fixAssignedInjectTimeLocal(diff, false)
        Logger.log(`adjustInjectStartTime turn off! currentDDT: ${current} earliestSavedDDT: ${this.timelineController.earliestStartMillis} diff: ${diff}`, LEVEL.DEBUG);
      }
    }

    private calcDiff() {
      Logger.log(`calcDiff ::::: > `, LEVEL.DEBUG);
      /* const oeStart = new Date(TIME.tt_toDate(this.lastStartDateTime!).getTime());
      const newStart = new Date(TIME.tt_toDate(this.startDateTime!).getTime());

      const _isAfter = isAfter(newStart, oeStart)
      // Logger.log(`calcDiff ::::: > oe: ${oeStart} new: ${newStart}`);
      const newDurationInMins = Number(this.exerciseDurationInMinutes)

      // const oeDurationInHours = Number(this.lastExerciseDurationInMinutes) / 60
      // const newDurationInHours = Number(this.exerciseDurationInMinutes) / 60

      // Logger.log(`oeDurationInHours: ${oeDurationInHours} newDurationInHours: ${newDurationInHours}` );

      const latestInject = _.maxBy(this.assigendExerciseInjects, function(i) {
        return Number(i.deliveryDateTime) + Number(i.deliveryDurationInMinutes)
      })

      if(latestInject) {
        const latestInjectTime = Number(latestInject.deliveryDateTime) + Number(latestInject.deliveryDurationInMinutes);
        // Logger.log(`latestInjectTime: ${latestInjectTime}`);
        // Logger.log(`newDurationInMins: ${newDurationInMins}` );
        Logger.log(`${latestInject.deliveryDateTime} ${latestInject.short_title} `, LEVEL.DEBUG)

        if (latestInjectTime > newDurationInMins) {
          this.buttonErrors = [BUTTON_ERROR_TYPE.MINUS_DAY, BUTTON_ERROR_TYPE.MINUS_HOUR]

          Logger.log(`ERROR  latestTime >>> new Duration`, LEVEL.ERROR)
          this.errorText = 'inject endtime date conflict'
          // canSave = false
        } else {
          this.buttonErrors = []
        }
      }  else {
        this.buttonErrors = []
      }

      const diffInMin = _isAfter ? differenceInMinutes(newStart, oeStart) : differenceInMinutes(oeStart, newStart);

      if (_isAfter) {
        Logger.log(`new start is after - diffInMin: ${diffInMin}`, LEVEL.DEBUG)


        if(this.assigendExerciseInjects.length > 0) {
          const earliestInject = _.minBy(this.assigendExerciseInjects, 'deliveryDateTime')
          if (earliestInject) {

            Logger.log(`earliestInject deliveryDateTime: ${earliestInject.deliveryDateTime} title: ${earliestInject.short_title}`, LEVEL.DEBUG);

            const timeFact = earliestInject.deliveryDateTime > diffInMin ? diffInMin : earliestInject.deliveryDateTime

            Logger.log(`diffInMin ${diffInMin} timeFact: ${timeFact} earliestInject: ${earliestInject.deliveryDateTime}`,LEVEL.DEBUG);
            this.currentFact = timeFact
          }
        }

      } else {
        Logger.log(`new start is before - diffInMin: ${diffInMin}`, LEVEL.DEBUG);
        if (this.assigendExerciseInjects.length > 0 && diffInMin >= 0) {
          Logger.log(`this.storage.assigendExerciseInjects.length > 0 ${this.assigendExerciseInjects.length}`, LEVEL.DEBUG);
          this.currentFact = diffInMin
        }
      } */

      // wait(2500, () => {
      //   this.lastStartDateTime = this.startDateTime
      //   this.lastExerciseDurationInMinutes = this.exerciseDurationInMinutes
      // })
      // this.setupTimeline(newStart.getTime(), newDurationInHours)
    }

    async save(){
      this.preloaderVisible = true;
      wait(16, () => {
        this.isLoading = true
       })

      Logger.log(`adjustInjectStartTime ${this.adjustInjectStartTime}`, LEVEL.DEBUG);
      var canSave = true
      // Logger.log(`this.excercise.dateTimeStarted ${new Date(TIME.tt_toDate(this.startDateTime!).getTime())}`);
      if (this.storage.currentExer) {
        // const oeStart = this.storage.currentExer.getDateTime();
        // const newStart = new Date(TIME.tt_toDate(this.startDateTime!).getTime());
        // Logger.log(`oe: ${oeStart} new: ${newStart}`);
        // const _isAfter = isAfter(newStart, oeStart)
        // const newDurationInMins = Number(this.exerciseDurationInMinutes)



        // Logger.log(`oeEnd: ${oeEnd} newEnd: ${newEnd} ` );
        // Logger.log(`oeDurationInHours: ${oeDurationInHours} newDurationInHours: ${newDurationInHours}` );


        // const latestInject = _.maxBy(this.storage.assigendExerciseInjects, function(i) {
        //   return Number(i.deliveryDateTime) + Number(i.deliveryDurationInMinutes)
        // })

        // if(latestInject) {
        //   const latestInjectTime = Number(latestInject.deliveryDateTime) + Number(latestInject.deliveryDurationInMinutes);
        //   // Logger.log(`latestInjectTime: ${latestInjectTime}`);
        //   // Logger.log(`newDurationInMins: ${newDurationInMins}` );
        //   Logger.log(`${latestInject.deliveryDateTime} ${latestInject.short_title} `, LEVEL.DEBUG);

        //   if (latestInjectTime > newDurationInMins) {
        //     Logger.log(`ERROR  latestTime >>> new Duration`, LEVEL.DEBUG);
        //     this.errorText = 'inject endtime date conflict'
        //     canSave = false
        //   }
        // }
        // canSave = false

       /*  const diffInMin = _isAfter ? differenceInMinutes(newStart, oeStart) : differenceInMinutes(oeStart, newStart);

        if (_isAfter) {
          // if(this.storage.currentExer.dateTimeStarted < this.excercise.dateTimeStarted) {
            Logger.log(`new start is after - diffInMin: ${diffInMin}`, LEVEL.DEBUG);

            if(this.storage.assigendExerciseInjects.length > 0) {
              if (this.adjustInjectStartTime && canSave) {
                await this.fixAssignedInjectTime(Number(-this.currentFact))
                // await this.fixAssignedInjectTime(Number(-timeFact))
              }
              const earliestInject = _.minBy(this.storage.assigendExerciseInjects, 'deliveryDateTime')
              if (earliestInject) {
                Logger.log(`earliestInject deliveryDateTime: ${earliestInject.deliveryDateTime} title: ${earliestInject.short_title}`, LEVEL.DEBUG);
                const timeFact = earliestInject.deliveryDateTime > diffInMin ? diffInMin : earliestInject.deliveryDateTime
                Logger.log(`diffInMin ${diffInMin} timeFact: ${-timeFact} earliestInject: ${earliestInject.deliveryDateTime}`, LEVEL.DEBUG);
                if (this.adjustInjectStartTime && canSave) {
                  await this.fixAssignedInjectTime(Number(-this.currentFact))
                  // await this.fixAssignedInjectTime(Number(-timeFact))
                }
              }
            }
          } else {

            Logger.log(`new start is before - diffInMin: ${diffInMin}`, LEVEL.DEBUG);

            if (this.storage.assigendExerciseInjects.length > 0 && diffInMin >= 0) {
              Logger.log(`this.storage.assigendExerciseInjects.length > 0 ${this.storage.assigendExerciseInjects.length}`, LEVEL.DEBUG);

              if (this.adjustInjectStartTime && canSave) {
                await this.fixAssignedInjectTime(Number(-this.currentFact))
                // await this.fixAssignedInjectTime(Number(diffInMin))
              }

            }
          } */
        }

        if (!this.excercise) { return }

        if (this.adjustInjectStartTime && canSave) {
          // this.currentFact = this.timelineController.getLeftSpacing()
          Logger.log(`save-- space:  ${this.currentFact}`, LEVEL.DEBUG);
          // await this.fixAssignedInjectTime(Number(this.currentFact))

        }

      if (canSave) {
        this.excercise.dateTimeStarted = this.timelineController.startDateTimeMillis// TIME.tt_toDate(this.startDateTime!).getTime()
        console.log(`Date start ${new Date(this.excercise.dateTimeStarted)}`);
        this.excercise.durationInHours = this.timelineController.exerciseDurationInHours //Number(this.exerciseDurationInMinutes) / 60
        const dto = {
          name: this.excercise.name,
          description: this.excercise.description,
          durationInHours: Number(this.excercise.durationInHours),
          dateTimeStarted: Number(this.excercise.dateTimeStarted),
          status: this.excercise.status,
        }

        const result = await this.api.apiPatch('exercise', this.excercise.id, dto);
        wait(550, () => {
          this.isLoading = false
         })
        // this.ui.onReloadTimeLineEvent.emit()
        setTimeout(() => {
          this.dialogRef.close('RELOAD');
        },250)
      } else {
        wait(160, () => {
          this.isLoading = false
         })
      }

    }

    private async fixAssignedInjectTime(fact: number) {
      Logger.log(`fixAssignedInjectTime fact ${fact}`, LEVEL.DEBUG);
      // return
      for(var i = 0; i < this.storage.assigendExerciseInjects.length; i ++) { //.storage
        const inj = this.storage.assigendExerciseInjects[i] //storage.
        // Logger.log(`inj.deliveryDateTime ${inj.deliveryDateTime}`);
        var ids = ''
        if (inj.trainingObjectives) {

          ids=  _.map(inj.trainingObjectives, 'id').toString()
          // Logger.log(`ids`, ids);
        }
          const deliveryDateTime = Number(Number(inj.deliveryDateTime) + fact)
         const dto:InjectUpdateDto = {


          description: inj.description,
          short_title: inj.short_title,
          stateId: inj.state.id,
          deliveryDateTime: deliveryDateTime,//Number(Number(inj.deliveryDateTime) + fact),
          deliveryDurationInMinutes: Number(inj.deliveryDurationInMinutes),
          locationId: inj.location ? inj.location.id : -1,

          related_documents: inj.related_documents,
          related_equipment: inj.related_equipment,
          role_players_instruction: inj.role_players_instruction,
          on_scene_preparation: inj.on_scene_preparation,
          additional_guidance_caution_options: inj.additional_guidance_caution_options,
          expected_outcomes: inj.expected_outcomes,
          trainers_action_requirement: inj.trainers_action_requirement,
          learning_objectives: ids,

        }
        if (dto.locationId == -1) {
          delete dto.locationId
        }
        //  Logger.log(`dto.deliveryDateTime: ${dto.deliveryDateTime} deliveryDateTime: ${inj.deliveryDateTime}`);
        await this.api.apiPatch('inject/update-assigned', inj.id.toString(), dto);

      }
    }

    private fixAssignedInjectTimeLocal(fact: number, reset: boolean = false) {



      for(var i = 0; i < this.assigendExerciseInjects.length; i ++) {
        const deliveryDateTime = Number(Number(this.assigendExerciseInjects[i].deliveryDateTime) + fact)




        const TimeString = TIME.formatedTimeString(
          addMinutes(this.timelineController.startDateTime, deliveryDateTime)
        );


        const oeDel = this.assigendExerciseInjects[i].oedeliveryDateTime
        const leftPosOe = this.assigendExerciseInjects[i].leftPosOe
        const leftPosSmallOe = this.assigendExerciseInjects[i].leftPosSmallOe

        if(reset && oeDel && leftPosOe && leftPosSmallOe) {
          this.assigendExerciseInjects[i].deliveryDateTime = oeDel
          this.assigendExerciseInjects[i].leftPos = leftPosOe//(deliveryDateTime * 121) / 60
          this.assigendExerciseInjects[i].leftPosSmall = leftPosSmallOe//(deliveryDateTime * 61) / 60
        }

      const newLeftPos = (deliveryDateTime * 121) / 60
      const newLeftPosSmall = (deliveryDateTime * 61) / 60
      // if (i==0) {

      //   Logger.log(`newLeftPos: ${newLeftPos}`, LEVEL.WARN);
      //   Logger.log(`leftPosOe:  ${leftPosOe} `, LEVEL.WARN);
      //   Logger.log(`dDT      :  ${deliveryDateTime}`, LEVEL.WARN);
      //   Logger.log(`dDTOe:      ${oeDel}`, LEVEL.WARN);
      // }
      this.assigendExerciseInjects[i].deliveryDateTime = deliveryDateTime
      this.assigendExerciseInjects[i].leftPos = newLeftPos//(deliveryDateTime * 121) / 60
      this.assigendExerciseInjects[i].leftPosSmall = newLeftPosSmall//(deliveryDateTime * 61) / 60
      this.assigendExerciseInjects[i].timeString = TimeString

        wait(150, () => {
        })

        // Logger.log(`this.assigendExerciseInjects[i]: ${this.assigendExerciseInjects[i].deliveryDateTime} leftSmall: ${this.assigendExerciseInjects[i].leftPosSmall}`);
      }
    }

    close(){
      this.dialogRef.close();
    }

    dayPlusTapped()  {}
    dayMinusTapped()  {}
    hourPlusTapped()  {}
    hourMinusTapped()  {}
    // ngInputChanged(p: string) {}

    getInjects(timelineItemId: number): IExerciseTimelineInjectRef[] {
      if (!this.excercise) {
        return []
      }
      var items = this.assigendExerciseInjects
        .filter((item) => item.exerciseTimelineId == timelineItemId)
        .sort((a, b) => a.deliveryDateTime - b.deliveryDateTime);

      for (var i = 0; i < items.length; i++) {
        let current = items[i];

        var items2 = items.filter(
          (item) =>
            Number(item.deliveryDateTime) >= Number(current.deliveryDateTime) &&
            Number(item.deliveryDateTime) <=
              (Number(current.deliveryDateTime) + Number(current.deliveryDurationInMinutes))

        ); //.sort((a,b) => a.deliveryDateTime - b.deliveryDateTime)
        //items[i].topPos = 0//items2.length
        let topPos = 0;
        for (var j = 0; j < items2.length; j++) {
          if (current.id != items2[j].id && !current.topPos) {
            topPos++;
            items2[j].topPos = topPos;
          }
        }

        let roleItemIndex = this.excercise.timelineItems.findIndex(
          (item) => item.id == timelineItemId
        );
        if (roleItemIndex != -1) {
          let currentHeight = this.excercise.timelineItems[roleItemIndex].height;
          this.excercise.timelineItems[roleItemIndex].height =
            topPos > currentHeight ? topPos : currentHeight;
        }
      }

      return items; //this.api.injects.filter(item => item.timelineItemId ==  timelineItemId)
    }

    isSmallSize = false
    toggleSize() {
      this.isSmallSize = !this.isSmallSize
    }
    get checkboxLabel(): string {
      return 'adjust inject start time'
      //return this.fixedInjectStartTime ? "static inject start time" : "static inject start time"
    }
    winIsExpanded = false
    toggleWindowSize() {
      this.dialogRef.updateSize(this.winIsExpanded ? '1200px' : '90%', this.winIsExpanded ? '70%' : '90%')
      this.winIsExpanded = !this.winIsExpanded
    }
    get sizeIcon(): string {
      return this.winIsExpanded ? 'close_fullscreen' : 'open_in_full'
    }
    get zoomIcon(): string {
      return this.isSmallSize ? 'zoom_out' : 'zoom_in'
    }
    private getCurrentEarliestDeliveryDateTime(): number {
      const earliestInject = _.minBy(this.assigendExerciseInjects, 'deliveryDateTime')
      if (earliestInject) {
        return earliestInject.deliveryDateTime
      } else {
        return 0
      }
    }
}

/* ngInputChanged(p: string) {
  // this.calcDuration()
  // this.showSaveButton = true;


  // if(!this.startDateTime) { return }

  // if(p == 'DAY') {

  //   if(this.startDateTime?.day) {
  //     let cleanUp = false;
  //     for(let c of this.startDateTime!.day.split('')) {
  //       if(!this.allowdDays.includes(c)) {
  //         cleanUp = true;
  //       }
  //     }
  //     if(cleanUp) {
  //       setTimeout(()=>{
  //         this.startDateTime!.day = TIME.date_lcoal_to_tt(new Date()).day
  //       },10)
  //     }
  //   }
  // }
}
// omit_special_char(event: any) {
//   var k;
//     k = event.key//charCode;  //         k = event.keyCode;  (Both can be used)
//     //return((k >= 48 && k <= 57)); //(k >= 0 && k < 32) || (k > 96 && k < 123) || k == 8 || k == 32 ||
//     // const allowd = ['1','2','3','4','5','6','7','8','9','0']
//     // return _.parseInt(k)
//     return(this.allowd.includes(k) && k != '`')

//   } */

 // endMonthSelected(event: any){
    //   // this.excercise.endDateTime.month = event
    //   // this.calcDuration()
    //   /* this._save() */
    // }
    // onHoursKeyDown(e:any) {
    //   Logger.log(e.charCode);
    //   // if(_.parseInt(e)) {
    //   //   Logger.log('is num');

    //   // } else {
    //   //   Logger.log('is ncjan');
    //   // }
    // }

// this.setupTimeline(Number(this.excercise.dateTimeStarted), this.excercise.durationInHours)
          // this.calcDuration()
          // this.mainWidthScaleFact = this.miniMapWidth / 5000;
          // // this.hourItems = this.getExerciseDays();
          //   //  Logger.log(this.hourItems);
          // const dayCount = this.hourItems.filter(
          //   (item) => item.startNewDate == true
          // );
          // const hhh = _.map(dayCount, "hoursCount");
          // var sumHours = 0;
          // hhh.forEach((e) => {
          //   sumHours += e;
          // });
          // const _f1 = Math.round(this.miniMapWidth / sumHours);

          // this.miniOvlItems = [];
          // var full = 0;
          // var _lastWidth = 0;
          // // Logger.log(`miniMapWidth: ${this.miniMapWidth} _f1: ${_f1}`);
          // this.miniMapHourWidth = _f1;
          // for (var i = 0; i < dayCount.length; i++) {
          //   // Logger.log(dayCount[i]);
          //   const _f2 = Math.round(_f1 * dayCount[i].hoursCount);
          //   // Logger.log(`_F2 ${_f2}`);
          //   full += _f2;
          //   this.miniOvlItems.push({
          //     width: _f2,
          //     day: dayCount[i].dateString,
          //     left: _lastWidth,
          //   });
          //   _lastWidth += _f2;
          // }

          // subTimeFor(itemType: string){
          //   if(itemType == 'DAY'){

          //     const days = _.parseInt(this.durationInDays)
          //     // const days = parseInt(this.durationInDays)
          //     let newDays = days - 1;
          //     if (days >= 1 && newDays >= 1) {
          //       // Logger.log(`days ${days}` );
          //       // Logger.log(`newDays ${newDays}` );

          //       this.durationInDays = `${newDays}`//newDays < 10 ? `0${newDays}` : `${newDays}`
          //       const start = new Date(TIME.tt_toDate(this.startDateTime!));

          //       this.exerciseDurationInMinutes = this.exerciseDurationInMinutes - 1440;

          //       this.endDateTime = TIME.date_to_tt(addMinutes(start, this.exerciseDurationInMinutes))

          //     }

          //    /*  const start = new Date(TIME.tt_toDate(this.startDateTime!));

          //     let end = new Date(addMinutes(start, this.exerciseDurationInMinutes))

          //     this.exerciseDurationInMinutes = differenceInMinutes(subDays(end,1), start)
          //     this.endDateTime = TIME.date_to_tt(addMinutes(start, this.exerciseDurationInMinutes))
          //     this.durationDiffInSec = this.exerciseDurationInMinutes * 60 */
          //     // this.calcDiff()
          //     this.setupTimeline(TIME.tt_toDate(this.startDateTime!).getTime(), this.exerciseDurationInMinutes / 60)

          //   } else if(itemType == 'HOUR'){
          //     const start = new Date(TIME.tt_toDate(this.startDateTime!));

          //     const hours = _.parseInt(this.durationInHours)
          //     Logger.log(hours);
          //     // const days = parseInt(this.durationInDays)
          //     let newHours = hours - 1;
          //     if (hours >= 0 && newHours >= 0) {
          //       Logger.log(`hier 1: ${newHours}`);
          //       // Logger.log(`hours ${hours}` );
          //       // Logger.log(`newHours ${newHours}` );

          //       this.durationInHours = `${newHours}`//newHours < 10 ? `0${newHours}` : `${newHours}`



          //     } else {
          //       Logger.log(`hier 2: ${newHours}`);
          //       this.durationInHours = `23`
          //     }

          //     this.exerciseDurationInMinutes = this.exerciseDurationInMinutes - 60;

          //       this.endDateTime = TIME.date_to_tt(addMinutes(start, this.exerciseDurationInMinutes))

          //     this.checkDaysBetween()
          //     this.calcDiff()
          //    /*  const start = new Date(TIME.tt_toDate(this.startDateTime!));

          //     let end = new Date(addMinutes(start, this.exerciseDurationInMinutes))

          //     this.exerciseDurationInMinutes = differenceInMinutes(subHours(end,1), start)
          //     this.endDateTime = TIME.date_to_tt(addMinutes(start, this.exerciseDurationInMinutes))
          //     this.durationDiffInSec = this.exerciseDurationInMinutes * 60 */


          //   } else if(itemType == 'MIN'){

          //     /* const start = new Date(TIME.tt_toDate(this.startDateTime!));


          //     let end = new Date(addMinutes(start, this.exerciseDurationInMinutes))

          //     this.exerciseDurationInMinutes = differenceInMinutes(subMinutes(end,1), start)
          //     this.endDateTime = TIME.date_to_tt(addMinutes(start, this.exerciseDurationInMinutes))
          //     this.durationDiffInSec = this.exerciseDurationInMinutes * 60 */

          //   }
          //   this.showSaveButton = true;
          // }

          /** HIER 1 */
          // this.assigendExerciseInjects = _.clone(this.storage.assigendExerciseInjects)
        // for(let i = 0; i < this.assigendExerciseInjects.length; i ++) {
        //   this.assigendExerciseInjects[i].short_title = `${this.assigendExerciseInjects[i].short_title} = ${i}`
        // }

        // Logger.log(this. assigendExerciseInjects);


        // if (this.excercise.currentExerciseState == ExerciseState.SCHEDULED || this.excercise.currentExerciseState == ExerciseState.DRAFT || this.excercise.currentExerciseState == ExerciseState.RUNNING || this.excercise.currentExerciseState == ExerciseState.DONE ) {

        //   // const isBore2 = isBefore(this.excercise.getDateTime(), new Date())

        //   // Logger.log(`isBore2: ${isBore2}`);

        //   _startDateTime = TIME.date_lcoal_to_tt(this.excercise.getDateTime())

        //   // this.lastStartDateTime = TIME.date_lcoal_to_tt(this.excercise.getDateTime())

        //   // this._lastDateStartet = Number(TIME.tt_toDate(this.lastStartDateTime).getTime())
        //   // this._lastDurationInHours = this.exerciseDurationInMinutes / 60

        //   // this.setInitialInput(this.startDateTime);

        //   // Logger.log(this.startDateTime);

        //   this.selectedStartMonth = _startDateTime.month

        //   _exerciseDurationInMinutes = this.excercise.durationInHours * 60
        //   // this.lastExerciseDurationInMinutes = this.exerciseDurationInMinutes

        //   this.durationDiffInSec = _exerciseDurationInMinutes * 60

        //   const days = Math.round(this.excercise.durationInHours / 24)
        //   const hours = this.excercise.durationInHours % 24
        //   // const mins = this.excercise.durationInHours % 24
        //   // Logger.log(`DAYS: ${days}`);
        //   // Logger.log(`HOURS: ${hours}`);
        //   // Logger.log(`MINS: ${mins}`);

        //   this.durationInDays =  `${days}`
        //   this.durationInHours = `${hours}`
        //   // this.durationInDays = days < 10 ? `0${days}` : `${days}`
        //   // this.durationInHours = hours < 10 ? `0${hours}` : `${hours}`

        // }
          /** HIER 1 */
