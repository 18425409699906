<div class="viewContainer">
  <!-- [options]="marker.options" -->
  <!-- [label]="marker.option.label" -->
  <!-- [label]="marker.label" -->
  <google-map [options]="options" width='100%' height="100%" #_map [zoom]="zoom" id="map">
    <!-- <map-marker *ngFor="let marker of markers" [position]="marker.position" [options]="marker.options"
      [label]="marker.label" (mapClick)="openInfoWindow(marker)"></map-marker>
      <map-info-window>Info Window content</map-info-window> -->
    <!-- <map-marker
    *ngFor="let marker of markers"
    [position]="marker.position"

    [label]="marker.label"
    >
    </map-marker> -->
  </google-map>
  <!-- <div id="map"></div> -->
  <!-- <div class="closeBtn">
    <mat-icon class="dxfx_icon_btn_medium ml5" (click)='close()'>close</mat-icon>
  </div> -->

  <!-- <div class="testMarker" #_testMarker></div> -->
</div>
