import { EventEmitter, Injectable } from "@angular/core";
import { ScreenSize } from "../model/screenSize.model";
import {
  IExerciseTimelineInjectRef,
  TacticalTime,
} from "../model/exercise.model";
import { TIME } from "./helpers";
import { ISnackData, OVERLAY_TYPE } from "../model/enums";
import { ExerciseInjectToTimelineDto } from "../model/dtos";
import { wait } from "./utils/functions";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class UiService {
  emittDurationTime: EventEmitter<Date> = new EventEmitter<Date>();

  windowResize: EventEmitter<ScreenSize> = new EventEmitter<ScreenSize>();

  // miniTimelineUpdate:EventEmitter<number> = new EventEmitter<number>();

  snackEmitter: EventEmitter<ISnackData> = new EventEmitter<ISnackData>();

  injectAnimation: EventEmitter<IExerciseTimelineInjectRef> =
    new EventEmitter<IExerciseTimelineInjectRef>();

  private _selectedDay: TacticalTime = TIME.date_to_tt(new Date());

  private _smallTimeLineItems: boolean = true;

  isToolbarHeightExpanded: boolean = false;

  private _isMapVisible: boolean = false;

  isMainToolbarOut: boolean = false;

  timeLineLabelOnTop: boolean = true;
  darkMode: boolean = true;

  currentSize: ScreenSize = { width: 400, height: 300 };

  isSimulation: boolean = false;
  isNotificationOn: boolean = true;

  toggleNotification() {
    this.isNotificationOn = !this.isNotificationOn;
  }
  get isMapVisible(): boolean {
    return this._isMapVisible;
  }

  toogleMapView() {
    this._isMapVisible = !this._isMapVisible;
  }

  get isDebugMode(): boolean {
    return !environment.production
  }
  get isDevMode(): boolean {
    return environment.devMode
  }
  toggleDarkMode() {
    this.darkMode = !this.darkMode;
    const el = document.getElementsByTagName("body");
    if (!this.darkMode) {
      console.log(el);
      //el[0].style.backgroundColor = "#cfdee1";
      el[0].setAttribute("class", "bodyLight");
    } else {
      el[0].removeAttribute("class");
    }
  }
  constructor() {
    // wait(2000, () => {
    //   this.toggleDarkMode();
    // });
  }

  // get me(): iUser | undefined {
  //   return this._iUser;
  // }

  get smallTimeLineItems(): boolean {
    return this._smallTimeLineItems;
  }

  set smallTimeLineItems(val) {
    this._smallTimeLineItems = val;
  }

  changeScreenAspect(newSize: ScreenSize) {
    this.currentSize = newSize;
    this.windowResize.emit(newSize);
  }

  public setCurrentDay(day: TacticalTime) {
    this._selectedDay = day;
    // this.getExerciseInjects(this._exerciseDocId)
    // this.exerciseDateChanged.emit(this._selectedDay)
    // setTimeout(()=>{
    //   this.dashboardToolbarInjectRefresh.emit(true)
    //  },250)
  }

  get selectedDay(): TacticalTime {
    return this._selectedDay;
  }

  get currentScreenSize() {
    if (this.currentSize == null) {
      this.currentSize = {
        width: window.innerWidth,
        height: window.innerHeight,
      };
    }
    return this.currentSize;
  }

  onOpenInjectDetailOverlay: EventEmitter<IExerciseTimelineInjectRef> =
    new EventEmitter<IExerciseTimelineInjectRef>();
  openInjectDetailOverlay(e: IExerciseTimelineInjectRef) {
    this.onOpenInjectDetailOverlay.emit(e);
  }
  onOpenInjectSelectlOverlay: EventEmitter<ExerciseInjectToTimelineDto> =
    new EventEmitter<ExerciseInjectToTimelineDto>();
  openInjectSelectOverlay(e: ExerciseInjectToTimelineDto) {
    this.onOpenInjectSelectlOverlay.emit(e);
  }

  onOpenOverlayEvent: EventEmitter<OVERLAY_TYPE> =
    new EventEmitter<OVERLAY_TYPE>();
  onReloadTimeLineEvent: EventEmitter<void> =
    new EventEmitter<void>();
}
