import { HourItemI, HourItemIState, TacticalTime } from "src/app/model/exercise.model";
import { getExerciseDays, newHourItem, wait } from './functions';
import { TIME } from "../helpers";
import { addHours, addMinutes, differenceInMinutes, isBefore, differenceInHours, addDays, isSameDay, subHours, subMinutes, isSameMonth } from 'date-fns';
import { isEqual } from "lodash";
import { LEVEL, Logger } from '../logger.service';
import * as _ from 'lodash';
import { UTCDate } from "@date-fns/utc";
import { EventEmitter } from "@angular/core";
export class TimelineController {

  private _startDate: Date = new Date()
  private _endDate: Date = new Date()
  private _hourItems: HourItemI[] = []

  private _startDateTime: TacticalTime = TIME.tt_currentUTC();
  private _exerciseDurationInMinutes: number = 2880
  private _endDateTime: TacticalTime | undefined
  private _earliestDeliveryDateTime: number | undefined;
  private _earliestStartMillis: number | undefined;
  private _maxEndMillis: number | undefined
  scrollToEmitter = new EventEmitter<string>()
  taskActionEmitter = new EventEmitter<HourAdjustEvent>()
  // lastStartDateTime:TacticalTime  | undefined
  // lastExerciseDurationInMinutes: number = 0


  // private _startDateTime: number = new Date().getTime()
  // private _endDateTime: number | undefined
  // private _durationInHours: number = 48

  constructor() {
     const endDate = new Date(addMinutes(TIME.tt_toDate(this._startDateTime), this._exerciseDurationInMinutes));
     this._endDateTime = TIME.date_to_tt(endDate);
    //  this._maxEndMillis = TIME.tt_toDate(this._endDateTime).getTime();
  }

  // get items
  get hourItems(): HourItemI[] {
    return this._hourItems.sort((a,b) => a.index - b.index);
  }



  initByTT(tt: TacticalTime, durationInMins: number, earliestInjectStartMillis?: number, latestInjectEndMillis?: number) {
    Logger.log(`TIMELINE CONTROLLER INIT BY TT ${TIME.formatedDateString(TIME.tt_toDate(tt))} durationInMins: ${durationInMins} earliestInjectStartMillis: ${earliestInjectStartMillis} latestInjectEndMillis: ${latestInjectEndMillis}`, LEVEL.WARN)
    this._startDateTime = tt; //
    this._startDate = TIME.tt_toDate(tt)
    this._exerciseDurationInMinutes = durationInMins;
    this._endDate = new Date(addMinutes(TIME.tt_toDate(this._startDateTime), this._exerciseDurationInMinutes));
    this._endDateTime = TIME.date_to_tt(this._endDate)
    //TIME.date_to_tt(this._endDate = new Date(addMinutes(TIME.tt_toDate(this._startDateTime), this._exerciseDurationInMinutes)));
    // Logger.log(`if millis:  ${(earliestInjectStartMillis != undefined && latestInjectEndMillis != undefined) ? true : false}`, LEVEL.ERROR);
    if(earliestInjectStartMillis != undefined && latestInjectEndMillis != undefined) {
      Logger.log(`this._earliestStartMillis:  ${this._earliestStartMillis} earliestSavedDDT: ${earliestInjectStartMillis}`, LEVEL.ERROR);
      this._earliestStartMillis = earliestInjectStartMillis;
      this._maxEndMillis = latestInjectEndMillis;
    }
    this.reinitItems(this.startDateTimeMillis, this.exerciseDurationInHours)
    // var _loadedItems: HourItemI[] = [];
    // _loadedItems = getExerciseDays(this.startDateTimeMillis, this.durationInHours);
    // this._hourItems = _loadedItems;
  }



  /// -----
  changeStartByTT(val: TacticalTime) {
    const changedMillis: number  = Number(TIME.tt_toDate(val).getTime());






    // if (changedMillis < this.startDateTimeMillis) {
    //   const hoursBetween = differenceInHours(this.startDateTimeMillis, changedMillis)

    //   Logger.log(`changeStartByTT add hours before: ${hoursBetween}`, LEVEL.ERROR)

    // // Logger.log(`changeStartByTT startMillis: ${startMillis} - this.startDateTimeMillis: ${this.startDateTimeMillis} hoursBetween: ${hoursBetween}`, LEVEL.ERROR)
    // } else {
    //   const hoursBetween = differenceInHours(changedMillis, this.startDateTimeMillis)
    //   Logger.log(`changeStartByTT sub hours : ${hoursBetween}`, LEVEL.ERROR)
    // }

    // this._startDateTime = val; //

    // this._startDate = TIME.tt_toDate(val)
    // const endDate = new Date(addMinutes(TIME.tt_toDate(this._startDateTime), this._exerciseDurationInMinutes));
    // this._endDateTime = TIME.date_to_tt(endDate);
    // this.reinitItems(this.startDateTimeMillis, this.exerciseDurationInHours)
  }

  changeStartByMS(val: number) {

  }

  // ----- CHANGE TIME
  appendDay() {
    this._exerciseDurationInMinutes += 1440;
  }

  appendHour() {
    const lastItem = this._hourItems[this._hourItems.length - 1]

    if(lastItem.state == HourItemIState.REMOVED) {
      const reversed = _.reverse(this._hourItems)
      const nestClearIndex = reversed.findIndex(item => item.state == HourItemIState.NONE || item.state == undefined)

      Logger.log(`>>>TIMELINE CONTROLLER appendHour nestClearIndex:${nestClearIndex}`, LEVEL.WARN)

      if(nestClearIndex != -1 ) {
        Logger.log(`>>>TIMELINE CONTROLLER appendHour :${reversed[nestClearIndex - 1].state}`, LEVEL.WARN)
        if (reversed[nestClearIndex - 1].state == HourItemIState.REMOVED) {
          reversed[nestClearIndex - 1].state = HourItemIState.NONE
          this.scrollToEmitter.emit(`hour-${reversed[nestClearIndex - 1].index}`)
        }
      }



      this._endDate = addHours(this._endDate, 1)
      this._endDateTime = TIME.date_to_tt(this._endDate)
      this._exerciseDurationInMinutes = differenceInMinutes(this._endDate, this._startDate)
      // this._hourItems[this._hourItems.length - 1].state = HourItemIState.NONE

    } else {
      const newItem: HourItemI = newHourItem(lastItem.index + 1, lastItem.dayIndex, this._endDate, HourItemIState.ADDED)
      this._endDate = addHours(this._endDate, 1)
      this._endDateTime = TIME.date_to_tt(this._endDate)
      this._exerciseDurationInMinutes = differenceInMinutes(this._endDate, this._startDate)

      this._hourItems.push(newItem)
      this.scrollToEmitter.emit(`hour-${newItem.index}`)
    }

    //TIME.date_to_tt(addMinutes(start, this._exerciseDurationInMinutes))
    this.fixItems()
  }

  removeLastHour() {
    Logger.log(`>>>TIMELINE CONTROLLER Remove last Our ${this._endDate}`, LEVEL.WARN)
    const lastItem = this._hourItems[this._hourItems.length - 1]

    if(lastItem.state == HourItemIState.ADDED) {

      this._endDate = subHours(this._endDate, 1)
      this._endDateTime = TIME.date_to_tt(this._endDate)
      this._exerciseDurationInMinutes = differenceInMinutes(this._endDate, this._startDate)

      this._hourItems.splice(this._hourItems.length - 1,1)

    } else if(lastItem.state == HourItemIState.REMOVED) {
      const reversed = _.reverse(this._hourItems)
      const nestClearIndex = reversed.findIndex(item => item.state == HourItemIState.NONE || item.state == undefined)
      reversed[nestClearIndex].state = HourItemIState.REMOVED
      this._endDate = subHours(this._endDate, 1)
      this._endDateTime = TIME.date_to_tt(this._endDate)
      this._exerciseDurationInMinutes = differenceInMinutes(this._endDate, this._startDate)
    } else {

      this._endDate = subHours(this._endDate, 1)
      this._endDateTime = TIME.date_to_tt(this._endDate)
      this._exerciseDurationInMinutes = differenceInMinutes(this._endDate, this._startDate)
      this._hourItems[this._hourItems.length - 1].state = HourItemIState.REMOVED
    }

    Logger.log(`<<< TIMELINE CONTROLLER Remove last Our ${this._endDate}`, LEVEL.WARN)
    this.fixItems()
  }

  // index = 0
  addHourBeforeStart() {

    const firstItem = this._hourItems[0]

    if (firstItem.state == undefined || firstItem.state == HourItemIState.ADDED || firstItem.state == HourItemIState.NONE) {

      this._startDate = subHours(this._startDate, 1);

      this._startDateTime = TIME.date_lcoal_to_tt(this._startDate);
      this._exerciseDurationInMinutes = differenceInMinutes(this._endDate, this._startDate)

      Logger.log(`this._exerciseDurationInMinutes: ${this._exerciseDurationInMinutes}`, LEVEL.ERROR)
      const newIten: HourItemI = newHourItem(0, firstItem.dayIndex, this._startDate, HourItemIState.ADDED)

      for(var i = 0; i < this._hourItems.length; i ++) {
        this._hourItems[i].index = i+1
        // this._hourItems[i].hour = this._hourItems[i].hour ? this._hourItems[i].hour! + 60 : i * 60
      }
      // this._hourItems.push(newIten)
      this._hourItems.splice(0, 0, newIten)
      // this.taskActionEmitter.emit('ITEM_ADDED')
      this.taskActionEmitter.emit({ event: HourAdjustEventType.HOUR_ADDED, itemCount: 1 })
      // this._hourItems.sort((a,b) => a.index - b.index)

    } else if (firstItem.state == HourItemIState.REMOVED) {

      const firstClearIndex = this._hourItems.findIndex(item => item.state == HourItemIState.NONE || item.state == undefined)
      Logger.log(`firstClearIndex: ${firstClearIndex}`, LEVEL.WARN)

      if(this._hourItems[firstClearIndex - 1].state == HourItemIState.REMOVED) {
        this._hourItems[firstClearIndex - 1].state = HourItemIState.NONE


        // this.taskActionEmitter.emit({ event: HourAdjustEventType.HOUR_ADDED, itemCount: 1 })
        this._startDate = addHours(this._startDate, 1);
        this._exerciseDurationInMinutes = differenceInMinutes(this._endDate, this._startDate)
        this._startDateTime = TIME.date_lcoal_to_tt(this._startDate);
      } else {

      }
    }

    this.fixItems()
  }


  removeFirstHour() {
    Logger.log(`removeFirstHour _earliestStartMillis: ${this._earliestStartMillis}`, LEVEL.DEBUG);
    const firstItem = this._hourItems[0];
    if(firstItem.state == HourItemIState.ADDED) {

      this._startDate = addHours(this._startDate, 1);

      this._startDateTime = TIME.date_lcoal_to_tt(this._startDate);
      this._exerciseDurationInMinutes = differenceInMinutes(this._endDate, this._startDate)
      this._hourItems.splice(0,1)
      this.taskActionEmitter.emit({ event: HourAdjustEventType.HOUR_REMOVED, itemCount: 1 })

    } else if(firstItem.state == HourItemIState.REMOVED) {
      //
      const firstClearIndex = this._hourItems.findIndex(item => item.state == HourItemIState.NONE || item.state == undefined)

      if(firstClearIndex != -1) {
        this._hourItems[firstClearIndex].state = HourItemIState.REMOVED
        this._startDate = addHours(this._startDate, 1);

        this._startDateTime = TIME.date_lcoal_to_tt(this._startDate);
        this._exerciseDurationInMinutes = differenceInMinutes(this._endDate, this._startDate)
      }

    } else {
      this._hourItems[0].state = HourItemIState.REMOVED
      this._startDate = addHours(this._startDate, 1);

      this._startDateTime = TIME.date_lcoal_to_tt(this._startDate);
      this._exerciseDurationInMinutes = differenceInMinutes(this._endDate, this._startDate)
    }



    this.fixItems()
  }


  private reinitItems(startDateTime: number, durationInHours: number) {
    // this._startDateTime, this._durationInHours
    var _loadedItems: HourItemI[] = [];
    _loadedItems = getExerciseDays(startDateTime, durationInHours);
    this._hourItems = _loadedItems;
  }


  // GET

  get startDateTimeTactical(): TacticalTime {
    return this._startDateTime
  }
  get startDateTime(): Date {
    return this._startDate
  }
  get endDateTime(): TacticalTime | undefined {
    return this._endDateTime;
  }


  get exerciseDurationInMinutes(): number {
    return this._exerciseDurationInMinutes;
  }

  get durationInDays(): string {
    const days = Math.round(this.exerciseDurationInHours / 24);
    return `${days}`;
  }

  get durationInHours(): string {
    const hours = this.exerciseDurationInHours % 24
    return `${hours}`;
  }

  get earliestStartMillis(): number | undefined {
    return this._earliestStartMillis
  }
  get maxEndMillis(): number | undefined {
    return this._maxEndMillis
  }

  get countRemovedHoursFromStart() : number {
    var out = 0
    if(this._hourItems.length > 0 && this._hourItems[0].state == HourItemIState.REMOVED) {
      const index = this._hourItems.findIndex(item => item.state == undefined || item.state == HourItemIState.NONE);
      if (index > 0) {
        out = this._hourItems.slice(0, index).length
      }
    }
    return out

  }
  get countAddedHoursFromStart() : number {
    var out = 0
    if(this._hourItems.length > 0 && this._hourItems[0].state == HourItemIState.ADDED) {
      const index = this._hourItems.findIndex(item => item.state == undefined || item.state == HourItemIState.NONE);
      if (index > 0) {
        out = this._hourItems.slice(0, index).length
      }
    }
    return out

  }



  // format
  get startDateTimeMillis():number {
    return Number(this._startDate.getTime());
  }

  get exerciseDurationInHours():number {
    return this._exerciseDurationInMinutes / 60;
  }

 /*  getLeftSpacing(): number {
    var leftSpacing = 0
    const index = this._hourItems.findIndex(item => item.state == undefined || item.state == HourItemIState.NONE);
    Logger.log(`getLeftSpacing index: ${index}`, LEVEL.DEBUG);

    if(index > 0) {
      const items = this._hourItems.slice(0, index)
      if(items && items.length > 0) {
        leftSpacing = items[0].state == HourItemIState.ADDED ? (items.length * 60) : items[0].state == HourItemIState.REMOVED ? -(items.length * 60) : 0
      }
    }

    // const index2 = this._hourItems.findIndex(item => item.state == undefined || item.state == HourItemIState.NONE);
    // Logger.log(`getLeftSpacing index: ${index}`, LEVEL.DEBUG);
    return leftSpacing
  } */

  private fixItems() {
    this._hourItems.sort((a,b) => a.index - b.index)
    // var firstDate: Date = new Date(this._hourItems[0].date)

    for(var i = 0; i < this._hourItems.length; i ++) {
      if(i > 0) {
        // this._hourItems[i].date = addDays(firstDate, i + 1)

        // if(isSameDay(this._hourItems[i].date, firstDate)) {
        if(this._hourItems[i].hourString.startsWith('00')) {
          this._hourItems[i].startNewDate = true
        } else {
          this._hourItems[i].startNewDate = false
        }
      } else {
        this._hourItems[i].startNewDate = true
        // firstDate = new Date(this._hourItems[i].date)
      }
      // firstDate = new Date(this._hourItems[i].date)

      // this._hourItems[i].hour = this._hourItems[i].hour ? this._hourItems[i].hour! + 60 : i * 60
    }

    console.log(this._hourItems);
  }


  /// TEMP

  setupTimeline(dateTimeStarted: number, durationInHours: number, callback: () => void) {
    Logger.log(`setupTimeline>>>>>>`, LEVEL.DEBUG);

          // var newCreatedItems: HourItemI[] = []
          // var currentItems: HourItemI[] = []
          // var loadedItems: HourItemI[] = []
          // // var restItems: HourItemI[] = []
          // loadedItems = getExerciseDays(dateTimeStarted, durationInHours)
          // // this.hourItems = tItems
          // currentItems = _.cloneDeep(this.hourItems)
          // if (this.hourItems.length > 0) {

          //   // const lastOld = this.hourItems[this.hourItems.length - 1]
          //   // restItems = _.cloneDeep(this.hourItems)
          //   const firstOld = currentItems[0]//this.hourItems[0]
          //   const firstNew = loadedItems[0]

          //   // const lastOld = currentItems[currentItems.length - 1]
          //   // const lastNew = loadedItems[loadedItems.length - 1]

          //   // const diffInMinutes = differenceInMinutes(firstNew.date, firstOld.date)
          //   // const diffInMinutesEnd = differenceInMinutes(lastNew.date, lastOld.date)
          //   // Logger.log(`diffInMinutes:: ${diffInMinutes} diffInMinutesEnd:: ${diffInMinutesEnd}`);

          //   if (isBefore(firstNew.date, firstOld.date)) {

          //     Logger.log(`\n>>> NEW_START_DATE IS BEFORE OLD_START_DATE <<<\n\n\n`, LEVEL.DEBUG);
          //     // this.direction = 'RIGHT'
          //     // isBefore(firstNew.date, firstOld.date)
          //     // arrayItems = _.cloneDeep(tItems)
          //     for (var i = 0; i < loadedItems.length; i ++) {
          //       const currentNew = loadedItems[i]
          //       const index = currentItems.findIndex(item => isEqual(item.date, currentNew.date))
          //       Logger.log(`index ${index} currentNew state: ${currentNew.state} `, LEVEL.WARN);
          //       if(index != -1) {
          //         currentItems.splice(index,1)

          //         currentNew.state = HourItemIState.NONE
          //         //
          //       } else {

          //         currentNew.state = HourItemIState.ADDED
          //       }
          //       newCreatedItems.push(currentNew)
          //     }
          //     // Logger.log(`rest items: `, currentItems);
          //     for (let j = 0; j < currentItems.length; j ++) {
          //       const addOld = currentItems[j]
          //       // addOld.currentlyRemoved = true
          //       // addOld.currentlyAdded = false
          //       newCreatedItems.push(addOld)
          //     }
          //     // Logger.log(currentItems);

          //   } else if (isBefore(firstOld.date, firstNew.date)) {

          //     Logger.log(`\n>>> NEW_START_DATE IS AFTER OLD_START_DATE <<<\n\n`, LEVEL.DEBUG);
          //     // this.direction = 'RIGHT'
          //     // Logger.log(`isBefore(firstOld.date, firstNew.date)`);
          //     var canADd = true
          //     var restItems: HourItemI[] = []
          //     restItems = _.cloneDeep(loadedItems)

          //     for (var i = 0; i < currentItems.length; i ++) {

          //       const currentNew = currentItems[i]
          //       const index = restItems.findIndex(item => isEqual(item.date, currentNew.date))
          //       Logger.log(`index ${index} currentNew state: ${currentNew.state} `, LEVEL.WARN);
          //       if(index != -1) {
          //         restItems.splice(index,1)


          //         canADd = true
          //         if (currentNew.state === HourItemIState.ADDED) {

          //         } else if (currentNew.state === HourItemIState.REMOVED) {

          //           currentNew.state = HourItemIState.NONE

          //         } else if (currentNew.state === HourItemIState.NONE || currentNew.state == undefined) {

          //         }
          //       } else {

          //         if (currentNew.state === HourItemIState.ADDED) {
          //           canADd = false
          //         } else if (currentNew.state === HourItemIState.REMOVED) {

          //           currentNew.state = HourItemIState.REMOVED
          //           canADd = true

          //         } else if (currentNew.state === HourItemIState.NONE || currentNew.state == undefined) {
          //           currentNew.state = HourItemIState.REMOVED
          //           canADd = true
          //         }

          //       }
          //       if (canADd) {

          //         newCreatedItems.push(currentNew)
          //       }
          //     }
          //     // Logger.log(`rest items: `, restItems.length);
          //     // Logger.log(restItems);
          //     for (let j = 0; j < restItems.length; j ++) {

          //       const addOld = restItems[j]
          //       const index = currentItems.findIndex(item => isEqual(item.date, addOld.date))
          //       // Logger.log(`index ${index} addOld state: ${addOld.state} `);
          //       if(index == -1) {
          //         // addOld.currentlyRemoved = false
          //         addOld.state = HourItemIState.ADDED
          //       }

          //       // addOld.currentlyRemoved = true
          //       // addOld.currentlyAdded = false
          //       newCreatedItems.push(addOld)
          //     }
          //   } else {

          //     var canADd = true
          //     Logger.log(`\nIS SAME ??\n`, LEVEL.DEBUG);
          //     var restItems: HourItemI[] = []
          //     currentItems = _.cloneDeep(this.hourItems)
          //     restItems = _.cloneDeep(loadedItems)

          //     const lastOld = currentItems[currentItems.length - 1]
          //     const lastNew = loadedItems[loadedItems.length - 1]

          //     const diffInMinutes = differenceInMinutes(firstNew.date, firstOld.date)
          //     const diffInMinutesEnd = differenceInMinutes(lastNew.date, lastOld.date)

          //     // Logger.log(`diffInMinutes:: ${diffInMinutes} diffInMinutesEnd:: ${diffInMinutesEnd}`);

          //     for (var i = 0; i < currentItems.length; i ++) {
          //       const currentNew = currentItems[i]
          //       const index = restItems.findIndex(item => isEqual(item.date, currentNew.date))
          //       // Logger.log(`index ${index}`);

          //       Logger.log(`index ${index} currentNew state: ${currentNew.state} `, LEVEL.WARN);
          //       // Logger.log(currentNew);
          //       if(index != -1) {

          //         restItems.splice(index,1)
          //         canADd = true
          //         if (currentNew.state === HourItemIState.ADDED) {

          //         } else if (currentNew.state === HourItemIState.REMOVED) {

          //           currentNew.state = HourItemIState.NONE

          //         } else if (currentNew.state === HourItemIState.NONE || currentNew.state == undefined) {

          //         }
          //       } else {

          //         if (currentNew.state === HourItemIState.ADDED) {
          //           canADd = false
          //         } else if (currentNew.state === HourItemIState.REMOVED) {

          //           currentNew.state = HourItemIState.REMOVED
          //           canADd = true

          //         } else if (currentNew.state === HourItemIState.NONE || currentNew.state == undefined) {
          //           currentNew.state = HourItemIState.REMOVED
          //           canADd = true
          //         }
          //         //  else {
          //         //   currentNew.currentlyAdded = false
          //         //   currentNew.currentlyRemoved = true
          //         //   canADd = true
          //         // }
          //       }

          //       if (canADd) {
          //         newCreatedItems.push(currentNew)
          //       }

          //     }

          //     // Logger.log(`rest items: `, restItems.length);
          //     // Logger.log(restItems);

          //     for (let j = 0; j < restItems.length; j ++) {
          //       const addOld = restItems[j]
          //       const index = currentItems.findIndex(item => isEqual(item.date, addOld.date))
          //       // Logger.log(`index ${index} addOld state: ${addOld.state} `);
          //       if(index == -1) {
          //         // addOld.currentlyRemoved = false
          //         addOld.state = HourItemIState.ADDED
          //       }

          //       newCreatedItems.push(addOld)
          //     }
          //     // for (var i = 0; i < loadedItems.length; i ++) {
          //     //   const currentNew = loadedItems[i]
          //     //   const index = currentItems.findIndex(item => isEqual(item.date, currentNew.date))
          //     //   if(index != -1) {
          //     //     currentItems.splice(index,1)
          //     //     currentNew.currentlyAdded = false
          //     //     //
          //     //   } else {
          //     //     currentNew.currentlyAdded = true
          //     //   }
          //     //   newCreatedItems.push(currentNew)
          //     // }
          //     // Logger.log(`rest items: `, currentItems.length);
          //     // for (let j = 0; j < currentItems.length; j ++) {
          //     //   const addOld = currentItems[j]
          //     //   addOld.currentlyRemoved = true
          //     //   addOld.currentlyAdded = false
          //     //   newCreatedItems.push(addOld)
          //     // }
          //   }
          //   this._hourItems = newCreatedItems

          // } else {
          //   this._hourItems = loadedItems
          // }
          // // Logger.log(this.hourItems);
          // // Logger.log(this.hourItems.length);



          // wait(100, () => {

          //   callback()
          //   // this.fixAssignedInjectTimeLocal(this.currentFact)
          //   // this.adjustInjectStartTimeChanged()
          // })
          callback()
  }
}


/* initByMS(startDateTime: number, durationInHours: number) {
  this._startDateTime = Number(TIME.tt_toDate(this._startDateTime).getTime());
  this._durationInHours = durationInHours;
  var _loadedItems: HourItemI[] = [];
  _loadedItems = getExerciseDays(this._startDateTime, this._durationInHours);
  this._hourItems = _loadedItems;
} */

  export enum HourAdjustEventType {
    HOUR_ADDED = 'HOUR_ADDED',
    HOUR_REMOVED = 'HOUR_REMOVED',
  }
  export interface HourAdjustEvent {
    event: HourAdjustEventType;
    itemCount: number;
  }
