import { ExcelExportComponent } from './../../overlays/excel-export/excel-export.component';
import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  Type,
  ViewChild,
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { TimelineComponent } from "src/app/components/timeline/timeline.component";
import { AddComponentDirective } from "src/app/directives/add-component.directive";
import { ExerciseState, OVERLAY_TYPE, VIEW_MODE } from "src/app/model/enums";
import {
  ExerciseClass,
  ExerciseI,
  IExerciseTimelineInjectRef,
  IUserRole,
} from "src/app/model/exercise.model";
import { ScreenSize } from "src/app/model/screenSize.model";
import { TextEditOverlayDataI } from "src/app/model/texteditOLdata.model";
import { TextEditOverlayComponent } from "src/app/overlays/text-edit-overlay/text-edit-overlay.component";
import { ApiService } from "src/app/services/api.service";
import { UiService } from "src/app/services/ui.service";
import { gsap } from "gsap";
import { TIME } from "src/app/services/helpers";
import {
  addHours,
  differenceInMinutes,
  differenceInSeconds,
  isAfter,
  isBefore,
} from "date-fns";
import { ExerciseUserRole } from "../../model/exercise.model";
import { NAV_EVENT } from "src/app/model/models";
import { wait } from "src/app/services/utils/functions";
import { InjectDetailOverlayComponent } from "src/app/overlays/inject-detail-overlay/inject-detail-overlay.component";
import { InjectSelectOverlayComponent } from "src/app/overlays/inject-select-overlay/inject-select-overlay.component";
import { Subscription } from "rxjs";
import { ExerciseInjectToTimelineDto } from "src/app/model/dtos";
import { StorageService } from "../../services/storage.service";
import { LocationDetailOverlayComponent } from "src/app/overlays/location-detail-overlay/location-detail-overlay.component";
import { FullscreenOverlayComponent } from "src/app/overlays/fullscreen-overlay/fullscreen-overlay.component";
import { ExerciseDurationOverlayComponent } from "src/app/overlays/exercise-duration-overlay/exercise-duration-overlay.component";
import { ExerciseLogbookOverlayComponent } from "src/app/overlays/exercise-logbook-overlay/exercise-logbook-overlay.component";
import { ExercisePerformanceOverlayComponent } from "src/app/overlays/exercise-performance-overlay/exercise-performance-overlay.component";
import { ExerciseTeamPerformanceOverlayComponent } from "src/app/overlays/exercise-team-performance-overlay/exercise-team-performance-overlay.component";
import { CommentsOverviewOverlayComponent } from "src/app/overlays/comments-overview-overlay/comments-overview-overlay.component";
import { ChatMessageLocal } from "src/app/model/chatMessage.model";
import { DashboardUserComponent } from "./components/dashboard-user/dashboard-user.component";
import { DashboardRoleComponent } from "./components/dashboard-role/dashboard-role.component";
@Component({
  selector: "app-exercise-dashboard",
  templateUrl: "./exercise-dashboard.component.html",
  styleUrls: ["./exercise-dashboard.component.scss"],
})
export class ExerciseDashboardComponent implements OnInit, OnDestroy {
  @ViewChild(AddComponentDirective, { static: false })
  adHost!: AddComponentDirective;
  @ViewChild("_exercise_content_col") _exercise_content_col!: ElementRef;
  @ViewChild("_exercise_toolbar_col") _exercise_toolbar_col!: ElementRef;
  @ViewChild("injectToolBar", { static: false }) _injectToolBar!: ElementRef;
  @ViewChild("headerWrap", { static: false }) headerWrap:
    | ElementRef
    | undefined;
  @ViewChild("exerciseName", { static: false }) exerciseNameRef:
    | ElementRef
    | undefined;
  @ViewChild("exerciseStatus", { static: false }) exerciseStatusRef:
    | ElementRef
    | undefined;
  @ViewChild("exerciseUserRef", { static: false }) exerciseUserRef:
    | DashboardUserComponent
    | undefined;
  @ViewChild("exerciseRoleRef", { static: false }) exerciseRoleRef:
    | DashboardRoleComponent
    | undefined;
  // @ViewChild("headerWrap1", { static: false }) headerWrap1: ElementRef | undefined
  // @ViewChild("headerWrap2", { static: false }) headerWrap2: ElementRef | undefined
  exerciseDocID: string | undefined;

  get exercise(): ExerciseClass | undefined {
    return this.storage.currentExer;
  }

  ROLES = IUserRole;
  ExerciseUserRoles = ExerciseUserRole;
  currentTimeArray: string[] = ["01", "23", "32", "JUN", "24"];
  currentLocalTimeArray: string[] = ["01", "23", "32", "JUN", "24"];
  exerciseStates = ExerciseState;
  currentLocalTimeString: string = "";
  viewModes = VIEW_MODE;
  _currentViewMode: VIEW_MODE = VIEW_MODE.TIMELINE_VIEW;
  actionsToolbarVisible = true;
  isLoading = true;
  isSaving = false;
  showUI = false;
  injectOverlaySub: Subscription | undefined;
  injectSelectOverlaySub: Subscription | undefined;
  // locationsOverlaySub: Subscription | undefined;
  overlayOpenEventSub: Subscription | undefined;
  incomingMessageEventSub: Subscription | undefined;

  reloadTimeLineSub: Subscription | undefined;
  constructor(
    public api: ApiService,
    public storage: StorageService,
    private dialog: MatDialog,
    private router: Router,
    private route: ActivatedRoute,
    public ui: UiService
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.exerciseDocID = params["id"];
      this.startSubscription();
    });
    this.ui.windowResize.subscribe((_newSize) => {
      this.updateScreenAspects(_newSize);
    });
    this.injectOverlaySub = this.ui.onOpenInjectDetailOverlay.subscribe(
      (item: IExerciseTimelineInjectRef) => {
        this.openInjectDetails(item);
      }
    );
    this.injectSelectOverlaySub = this.ui.onOpenInjectSelectlOverlay.subscribe(
      (item: ExerciseInjectToTimelineDto) => {
        this.openInjectSelectOverlay(item);
      }
    );

    this.incomingMessageEventSub =
      this.storage.incomingMessageEmitter.subscribe((msg: ChatMessageLocal) => {
        this.showNotification(msg);
      });

    this.overlayOpenEventSub = this.ui.onOpenOverlayEvent.subscribe(
      (type: OVERLAY_TYPE) => {
        this.onOpenOverlay(type);
      }
    );
    this.reloadTimeLineSub = this.ui.onReloadTimeLineEvent.subscribe(
      () => {
        this.isLoading = true;
        this.startSubscription();
      }
    );

    setTimeout(() => {
      this._currentStageWidth = this.ui.currentScreenSize.width;
      this._currentStageHeight = this.ui.currentScreenSize.height;
      this.updateScreenAspects(this.ui.currentScreenSize);
      this.startCurrentTimeInterval();
    }, 1500);
  }

  async startSubscription() {
    if (this.exerciseDocID) {
      this.loadExDetail();
    }
  }

  private async loadExDetail() {
    if (!this.exerciseDocID) {
      return;
    }
    await this.storage.loadExDetail(this.exerciseDocID);

    wait(500, () => {
      this.isLoading = false;
    });
    wait(800, () => {
      this.showUI = true;
    });
  }

  onNavEvent(e: NAV_EVENT) {
    console.log(e);
  }

  ngOnDestroy(): void {
    if (this.injectOverlaySub) {
      this.injectOverlaySub.unsubscribe();
    }
    if (this.injectSelectOverlaySub) {
      this.injectSelectOverlaySub.unsubscribe();
    }
    // if (this.locationsOverlaySub) {
    //   this.locationsOverlaySub.unsubscribe();
    // }
    if (this.overlayOpenEventSub) {
      this.overlayOpenEventSub.unsubscribe();
    }
    if (this.reloadTimeLineSub) {
      this.reloadTimeLineSub.unsubscribe();
    }
  }

  access(roles: string[]): boolean {
    return true;
  }

  private async saveExercise() {
    if (!this.exercise) {
      return;
    }
    console.log(`saveExercise`);
    if (this.isSaving) {
      return;
    }
    this.isSaving = true;
    const dto = {
      name: this.exercise.name,
      description: this.exercise.description,
      durationInHours: this.exercise.durationInHours,
      dateTimeStarted: Number(this.exercise.dateTimeStarted),
      status: this.storage.currentExer!.status,
    };
    const result = await this.api.apiPatch("exercise", this.exercise.id, dto);
    // this.exercise = result as ExerciseI;
    // console.log(result);

    wait(2000, () => {
      this.isSaving = false;
    });
  }

  routeToAdmin() {
    this.router.navigateByUrl("admin");
  }

  signOutTapped() {
    // this.api.signOut()
    // window.sessionStorage.clear();
    this.storage.signOut();
    setTimeout(() => {
      this.router.navigateByUrl("");
    }, 500);
  }

  textEditOverlay(item: string) {
    if (this.storage.getAccess([ExerciseUserRole.ADMIN])) {
      if (!this.exercise) {
        return;
      }

      let data: TextEditOverlayDataI = {
        title:
          item == "exercisename"
            ? "EDIT EXERCISE NAME"
            : "EDIT EXERCISE DESCRIPTION",
        editText:
          item == "exercisename"
            ? this.exercise.name
            : this.exercise.description ?? "",
        singleLineTxt: true,
      };
      this.dialog
        .open(TextEditOverlayComponent, {
          width: "600px",
          maxWidth: "600px",
          backdropClass: "backdrop-2",
          data: data,
        })
        .afterClosed()
        .subscribe((_newEditText) => {
          if (_newEditText != null) {
            // console.log(_newEditText);

            if (!this.exercise) {
              return;
            }
            if (item == "exercisename") {
              this.exercise.name = _newEditText;
            } else {
              this.exercise.description = _newEditText;
            }
            this.saveExercise();
          }
        });
    }
  }


  timeLeftInSeconds: number | undefined;

  //
  _currentStageWidth: number = 800;
  _currentStageHeight: number = 800;
  _contentContainerWidth: number = 800;
  _toolbarContainerWidth: number = 800;
  _contentColLeft: number = 400;
  // _contentColLeft:number = 411;
  _currentToolbarX: number = 0;
  // _currentToolbarX:number = 49;
  _currentToolbarHeight: number = 920;
  showCurrentLocal = true;
  showBreak = false;

  updateScreenAspects(newScreenSize: ScreenSize) {
    const w = newScreenSize.width - (140 + 258);
    let nameWidth = 0;
    let statusWidth = 0;
    let userWidth = 0;
    let roleWidth = 0;
    if (this.exerciseNameRef) {
      nameWidth = this.exerciseNameRef.nativeElement.clientWidth;
    }
    if (this.exerciseStatusRef) {
      statusWidth = this.exerciseStatusRef.nativeElement.clientWidth;
    }
    if (this.exerciseUserRef) {
      userWidth = this.exerciseUserRef.headerWrap?.nativeElement.clientWidth;
    }
    if (this.exerciseRoleRef) {
      roleWidth =
        this.exerciseRoleRef.containerHolder?.nativeElement.clientWidth;
    }

    let sumH = nameWidth + statusWidth + userWidth;
    // console.log(`headerWidht: ${w} nameWidth: ${nameWidth} statusWidth: ${statusWidth} userWidth: ${userWidth} : roleWidth: ${roleWidth}`);
    // console.log(`headerWidht: ${w} sumH: ${sumH} `);
    if (this.headerWrap) {
      if (sumH <= w) {
        if (sumH + roleWidth <= w) {
          this.showBreak = true;
        } else {
          this.showBreak = false;
        }
      } else {
        this.showBreak = false;
      }

      gsap.to(this.headerWrap.nativeElement, { width: w });
    }

    let newContentWidth =
      newScreenSize.width -
      (this.ui.isMainToolbarOut == true ? 0 : this._contentColLeft);

    this._currentStageHeight = newScreenSize.height;

    gsap.to(this._exercise_content_col.nativeElement, {
      duration: 0.65,
      width: newContentWidth,
      height: newScreenSize.height, //- 90,
      // marginTop: 90,
      ease: "Expo.easeOut",
      onComplete: () => {
        this._contentContainerWidth = newContentWidth;
      },
    });

    // let startContentX = this._contentColLeft;
    let newContentX = this.ui.isMainToolbarOut === true ? -400 : 0;
    // let newContentX = (this.ui.isMainToolbarOut === true ? -411 : 0);
    // let newContentX = (this.ui.isMainToolbarOut === true ? -368 : 0);
    // let newContentX = (this.ui.isMainToolbarOut === true ? -362 : 0);

    gsap.to(this._exercise_content_col.nativeElement, {
      duration: 0.65,
      x: newContentX,
      ease: "Expo.easeOut"
    });

    this.initToolbar();
  }

  initToolbar() {
    this.resizeToolbarHeight();
    if (this._currentViewMode == VIEW_MODE.INITIALIZING) {
      gsap.to(this._exercise_toolbar_col.nativeElement, {
        duration: 0.75,
        opacity: 1,
        ease: "Expo.easeOut"
      });
    }
  }

  resizeToolbarHeight() {
    this._currentStageHeight = this.ui.currentScreenSize.height;
    // let toolbarStartHeight:number = this._currentToolbarHeight == null ?  200 : this._currentToolbarHeight;
    let toolbarHeight: number = this._currentStageHeight - 99; // (this.ui.isToolbarHeightExpanded ? 155 : 320);

    // let toolbarHeight: number = this._currentStageHeight - (183 + 75)// (this.ui.isToolbarHeightExpanded ? 155 : 320);
    // let toolbarHeight: number = this._currentStageHeight - (this.ui.isToolbarHeightExpanded ? 155 : 420);
    // { 'height': toolbarStartHeight},
    if (this._injectToolBar != null) {
      gsap.to(this._injectToolBar.nativeElement, {
        duration: 0.75,
        height: toolbarHeight,
        ease: "Expo.easeOut",
        onComplete: () => {
          this._currentToolbarHeight = toolbarHeight;
          // console.log(`toolbarHeight: ${toolbarHeight}`);
        },
      });
    }
  }

  currentTimeInterval: any;
  intervalHours = 0;

  startCurrentTimeInterval() {

    if (this.currentTimeInterval != null) {
      clearInterval(this.currentTimeInterval);
    }

    this.currentTimeInterval = setInterval(() => {
      let tt = TIME.tt_currentUTC();
      let local = TIME.date_lcoal_to_tt(new Date());
      if (this.ui.isSimulation) {
        tt = TIME.tt_currentUTC(this.intervalHours);
        local = TIME.date_lcoal_to_tt(addHours(new Date(), this.intervalHours));
      }
      this.ui.emittDurationTime.emit(TIME.tt_toDate(tt));
      // this.currentTimeString = tt.day + ".   " +  tt.hours + "  :  " +  tt.minutes + "  :  " +  tt.seconds;
      this.currentTimeArray[0] = tt.day;
      this.currentTimeArray[1] = tt.hours;
      this.currentTimeArray[2] = tt.minutes;
      this.currentTimeArray[3] = tt.month.viewVal;
      this.currentTimeArray[4] = tt.year;
      // this.currentTimeArray[5] = tt.seconds;
      // DDHHMM(Z)MONYY
      this.currentLocalTimeString =
        local.day +
        ".   " +
        local.hours +
        "  :  " +
        local.minutes +
        "  :  " +
        local.seconds;
      this.currentLocalTimeArray[0] = local.day;
      this.currentLocalTimeArray[1] = local.hours;
      this.currentLocalTimeArray[2] = local.minutes;
      this.currentLocalTimeArray[3] = local.month.viewVal;
      this.currentLocalTimeArray[4] = local.year;
      // this.currentLocalTimeArray[5] = local.seconds;

      if (this.exercise != null) {
        if (
          this.exercise.currentExerciseState === ExerciseState.SCHEDULED &&
          this.exercise.dateTimeStarted
        ) {
          this.timeLeftInSeconds = differenceInSeconds(
            new Date(this.exercise.dateTimeStarted),
            new Date()
          );
        } else if (
          this.exercise.currentExerciseState === ExerciseState.RUNNING
        ) {
          this.timeLeftInSeconds = differenceInSeconds(
            new Date(this.exercise.dateTimeStarted),
            new Date()
          );
        } else {
          this.timeLeftInSeconds = 0;
        }
      }
      this._checkExerciseState(TIME.tt_toDate(tt));
      this.intervalHours++;
    }, 3000);
  }

  private _checkExerciseState(widthDateTime: Date) {
    if (!this.storage.currentExer) {
      return;
    }
    const startUTC = this.storage.currentExer.getDateTimeUTC();
    const endUTC = this.storage.currentExer.endDateTimeDate;
    const currentUTC = widthDateTime.toUTCString();
    const minBetween = differenceInMinutes(widthDateTime, startUTC);
    const currentDateIsBeforeExerciseStart = isBefore(widthDateTime, startUTC);
    const currentDateIsAfterExerciseStart = isAfter(widthDateTime, startUTC);
    const currentDateIsAfterExerciseEnd = isAfter(widthDateTime, endUTC);
    // console.warn(`start UTC ${startUTC.toUTCString()}`);
    // console.warn(`end UTC ${endUTC.toUTCString()}`);
    // console.warn(`currentTUC ${currentUTC}`);
    // console.warn(`minBetween: ${minBetween}`);

    let message = "";

    if (currentDateIsBeforeExerciseStart) {
      message = " ExerciseDashboardComponent ? EX STARTS SOON";
    } else {
      if (currentDateIsAfterExerciseStart && currentDateIsAfterExerciseEnd) {
        message = " ExerciseDashboardComponent ? EX IS DONE";
        if (this.storage.currentExer.status != ExerciseState.DONE) {
          this.storage.currentExer.status = ExerciseState.DONE;
          // this.saveExercise()
        }
      } else {
        message = " ExerciseDashboardComponent ? EX IS RUNNING";
        if (this.storage.currentExer.status != ExerciseState.RUNNING) {
          this.storage.currentExer.status = ExerciseState.RUNNING;
          // this.saveExercise()
        }
      }
    }

    // console.warn(message);
    // console.warn("---------");
    // console.warn(
    //   `current Date Is BEFORE Exercise Start: ${currentDateIsBeforeExerciseStart}`
    // );
    // console.warn(
    //   `current Date Is AFTER Exercise Start: ${currentDateIsAfterExerciseStart}`
    // );
    // console.warn(
    //   `current Date Is AFTER Exercise END: ${currentDateIsAfterExerciseEnd}`
    // );
    // console.warn("");
    // console.warn(
    //   `currentDateIsAfterExerciseStart: ${currentDateIsAfterExerciseStart}\n`
    // );
    // console.warn("---------");
    // console.warn(`_checkExerciseState : ${this.storage.currentExer.status}`);
    // console.warn("");
    // console.warn("");
    //   if(this.exercise == null || this.exercise.startDateTime == null || this.exercise.endDateTime == null){
    //     return
    //   }
    //   let currentIsAfterE_start:boolean = isAfter(widthDateTime, TIME.tt_toDate(this.exercise.startDateTime))
    //   let currentIsBeforeE_end:boolean = isBefore(widthDateTime, TIME.tt_toDate(this.exercise.endDateTime))
    //   let secondsUntilStart = differenceInSeconds(widthDateTime, TIME.tt_toDate(this.exercise.startDateTime))
    //   if(currentIsAfterE_start === false){
    //     this.ui.setCurrentExerciseState(ExerciseState.SCHEDULED);
    //   } else if(currentIsAfterE_start === true &&  currentIsBeforeE_end == false){
    //     this.ui.setCurrentExerciseState(ExerciseState.DONE);
    //   } else if(currentIsAfterE_start === true &&  currentIsBeforeE_end == true){
    //     this.ui.setCurrentExerciseState(ExerciseState.RUNNING);
    //   } else {
    //     this.ui.setCurrentExerciseState(ExerciseState.NONE);
    //   }
  }

  /// OVERLAYS

  private openInjectDetails(inj: IExerciseTimelineInjectRef) {
    console.log(inj);
    this.dialog
      .open(InjectDetailOverlayComponent, {
        width: "95%",
        height: "80%",
        maxWidth: "1800px",
        maxHeight: "1200px",
        panelClass: this.ui.darkMode ? "fsPanelClass" : "fsPanelClassLight",
        // backdropClass: "backdrop-2",
        data: inj,
      })
      .afterClosed()
      .subscribe((_data?: IExerciseTimelineInjectRef) => {
        if (_data) {
          console.log(_data);
        }
      });
  }
  private openInjectSelectOverlay(item: ExerciseInjectToTimelineDto) {
    this.dialog.open(InjectSelectOverlayComponent, {
      width: "95%",
      height: "80%",
      maxWidth: "1800px",
      maxHeight: "1200px",
      panelClass: this.ui.darkMode ? "fsPanelClass" : "fsPanelClassLight",
      // backdropClass: "backdrop-2",
      data: item,
    });
  }
  // private openLocationsOverlay() {
  //   this.dialog.open(LocationDetailOverlayComponent, {
  //     width: "90%",
  //     height: "90%",
  //     maxWidth: "1600px",
  //     maxHeight: "960px",
  //     panelClass: "fsPanelClass",
  //     backdropClass: "fs_backdrop",
  //     // data: this.storage.currentExer,
  //   });
  // }

  toggleMainToolbar() {
    this.ui.isMainToolbarOut = !this.ui.isMainToolbarOut;
    setTimeout(() => {
      // this.ui.windowResize.emit(this.ui.currentScreenSize);
      this.ui.windowResize.emit({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }, 150);
  }

  private onOpenOverlay(type: OVERLAY_TYPE) {
    switch (type) {
      case OVERLAY_TYPE.USERGROUPS:
        if (this.storage.currentExer) {
          this.dialog
            .open(FullscreenOverlayComponent, {
              width: "100%",
              height: "100%",
              panelClass: this.ui.darkMode ? "fsPanelClass" : "fsPanelClassLight",
              backdropClass: "fs_backdrop",
              data: this.storage.currentExer.id!,
            })
            .afterClosed()
            .subscribe((d: string) => {
              // this.loadExDetail()
              if (d && d === "reload") {
                this.storage.reloadTimelines();
              }
            });
        }
        return;
      case OVERLAY_TYPE.SHOW_DURATION:
        if (!this.storage.currentExer || this.storage.isExerciseDeboarded) {
          return;
        }
        this.dialog
          .open(ExerciseDurationOverlayComponent, {
            // width: "600px",
            // width: "550px",
            width: "1200px",
            // height: "75%",
            data: this.storage.currentExer,
            panelClass: this.ui.darkMode ? "fsPanelClass" : "fsPanelClassLight",
            backdropClass: "fs_backdrop",
          })
          .afterClosed()
          .subscribe((msg?: any) => {
            if (msg && msg == "RELOAD") {
              this.isLoading = true;
              this.startSubscription();
            }
          });
        return;
      case OVERLAY_TYPE.LOGBOOK:
        if (!this.storage.currentExer) {
          return;
        }
        this.dialog
          .open(ExerciseLogbookOverlayComponent, {
            width: "90%",
            height: "80%",
            maxWidth: "1200px",
            maxHeight: "960px",
            panelClass: this.ui.darkMode ? "fsPanelClass" : "fsPanelClassLight",
            backdropClass: "fs_backdrop",
          })
        return;
      case OVERLAY_TYPE.PERFORMANCE:
        if (!this.storage.currentExer) {
          return;
        }
        this.dialog
          .open(ExercisePerformanceOverlayComponent, {
            width: "90%",
            height: "80%",
            maxWidth: "1200px",
            maxHeight: "960px",
            panelClass: this.ui.darkMode ? "fsPanelClass" : "fsPanelClassLight",
            backdropClass: "fs_backdrop",
          })
        return;
      case OVERLAY_TYPE.TEAM_PERFORMANCE:
        if (!this.storage.currentExer) {
          return;
        }
        this.dialog
          .open(ExerciseTeamPerformanceOverlayComponent, {
            height: "80%",
            minWidth: "1024px",
            width: "80%",
            panelClass: this.ui.darkMode ? "fsPanelClass" : "fsPanelClassLight",
            backdropClass: "fs_backdrop",
          })

        return;
      case OVERLAY_TYPE.LOCATIONS:
        if (!this.storage.currentExer) {
          return;
        }
        this.dialog.open(LocationDetailOverlayComponent, {
          width: "90%",
          height: "90%",
          maxWidth: "1600px",
          maxHeight: "960px",
          panelClass: this.ui.darkMode ? "fsPanelClass" : "fsPanelClassLight",
          backdropClass: "fs_backdrop",
          // data: this.storage.currentExer,
        });
        return;

      case OVERLAY_TYPE.COMMENT_OVERVIEW:
        if (!this.storage.currentExer) {
          return;
        }
        this.dialog.open(CommentsOverviewOverlayComponent, {
          width: "90%",
          height: "90%",
          maxWidth: "1600px",
          maxHeight: "960px",
          panelClass: this.ui.darkMode ? "fsPanelClass" : "fsPanelClassLight",
          backdropClass: "fs_backdrop",
          // data: this.storage.currentExer,
        });
        return;
        case OVERLAY_TYPE.EXPORT:
        if (!this.storage.currentExer) {
          return;
        }
        this.dialog.open(ExcelExportComponent, {
          width: "90%",
          height: "90%",
          maxWidth: "1600px",
          maxHeight: "960px",
          panelClass: this.ui.darkMode ? "fsPanelClass" : "fsPanelClassLight",
          backdropClass: "fs_backdrop",
          // data: this.storage.currentExer,
        });
        return;
    }
  }

  @ViewChild("notification") notification: ElementRef | undefined;
  hideNotification() {
    if (this.notification) {
      gsap.to(this.notification.nativeElement, {
        duration: 0.65,
        y: -100,
        opacity: 0,
        ease: "Expo.easeInOut",
      });
    }
    this.isNotification = false;
  }
  currentChatNot: ChatMessageLocal | undefined;
  showNotification(mgs: ChatMessageLocal) {
    this.currentChatNot = mgs;
    if (this.notification && this.ui.isNotificationOn) {
      gsap.fromTo(
        this.notification.nativeElement,
        { x: -100, y: 0 },
        { duration: 0.45, y: 0, x: 0, opacity: 1, ease: "Expo.easeInOut" }
      );
      wait(3000, () => {
        this.hideNotification();
      });
    }
  }

  isNotification = true;
}
export class ExerciseTimelineViewRef {
  constructor(
    public component: Type<TimelineComponent>,
    public exercise: ExerciseI
  ) {}
}
