<div class="sideBarContainer">
  <!-- <div class="sideBarBox p-0">
    <inject-detail-ol-delivery-method [inject]="inject"></inject-detail-ol-delivery-method>
  </div> -->
  <div class="sideBarBox" *ngFor="let navItem of sideNavItems" >
    <app-navigation-list-item
    [active]="activeSideNavId == navItem.id"
    [model]="navItem" (selected)="selectNav($event)" ></app-navigation-list-item>
  </div>
</div>
