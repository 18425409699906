import { Component, inject, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { iCompanyInject } from 'src/app/model/company.model';
import { StorageService } from 'src/app/services/storage.service';
import { RenameOverlayData } from '../renameOverlayData.model';

@Component({
  selector: 'app-rename-inject-import-item',
  templateUrl: './rename-inject-import-item.component.html',
  styleUrls: ['./rename-inject-import-item.component.scss']
})
export class RenameInjectImportItemComponent implements OnInit {
  overlayData: RenameOverlayData | undefined
  injectItem: iCompanyInject
  errorCopy = ''
  compInjects: iCompanyInject[] = []
  injectHasError = false
  inject_no: string = ''

  constructor(
    public dialogRef: MatDialogRef<RenameInjectImportItemComponent>,
    @Inject(MAT_DIALOG_DATA) public data:RenameOverlayData
  ) {
    this.overlayData = data;
    this.injectItem = data.injectItem
    this.inject_no = data.injectItem.inject_no
  }
  ngOnInit(): void {
    if (!this.overlayData) { return}
    this.compInjects = this.overlayData.companyInjects
    console.log(this.compInjects);
    const index = this.compInjects.findIndex(item => item.inject_no.toUpperCase() == this.inject_no.toUpperCase())
    const index2 = this.overlayData.injectImportList.findIndex(item2 => item2.inject_no.toUpperCase() == this.inject_no.toUpperCase())
    if (this.inject_no.length < 2) {
      this.injectHasError = true
      this.errorCopy = 'min 3 chars'
    } else if ( index != -1 || index2 != -1) {
      this.injectHasError = true
      this.errorCopy = 'ID already exists'
    }


  }

  injectNoKeyDown(e:any) {
    console.log(e);
    const index = this.compInjects.findIndex(item => item.inject_no.toUpperCase() == this.inject_no.toUpperCase())

    const index2 = this.overlayData?.injectImportList.findIndex(item2 => item2.inject_no.toUpperCase() == this.inject_no.toUpperCase())


    if (this.inject_no.length < 2) {
      this.injectHasError = true
      this.errorCopy = 'min 3 chars'
    } else if (index != -1 || index2 != -1) {
      this.injectHasError = true
      this.errorCopy = 'ID already exists'
    } else {
      this.errorCopy = ''
      this.injectHasError = false
    }
  }


  close() {
    this.dialogRef.close()
  }
  get saveEnabled():boolean {
    return !this.injectHasError
  }
  save() {
    this.dialogRef.close(this.inject_no.toUpperCase())
  }
}
